import React from "react";
import PageNotFoundController from "./PageNotFoundController";
import PageNotFound from "./PageNotFound.web";

const checkArrayValuesInString = (array: string[], string: string) => {
    for (let value of array) {
        if (string.includes(value)) {
            return true;
        }
    }
    return false;
}

export default class Header extends PageNotFoundController {

    render() {
        const pageTitle = this.getPageTitle(this.state.dynamicRoutes);
        
        // It can be used for hiding the 404 page for static urls
        const dashboardPages = [
            "/dashboard",
            "/get-started/",
            "/get-started",
            "/measure",
            "/login/secondary",
            "/VisualAnalytics",
            "/overview/dashboard",
            "/act/action-plan",
            "/act/forecast-Analysis",
            "/settings/profile",
            "/settings/organization-info",
            "/unsubscribe",
            "/subscribe",
            "/intermodal"
        ];
        const removedFrom = [
            ...dashboardPages,
            "/marketplace/",
            "/insights-and-trends/",
            "/events/",
            "/job-application?",
            "/job-openings-list/",
            "/transform/hub/integrations",
            "/dashboard",
            "/VerifyEmail",
            "/resend_mail/:mail",
            "/activate_account",
            "/job-application",
            "/job-success",
            "/success/email",
            "/free-assessment",
            "/success/contact",
            "/signup",
            "/login/primary",
            "/forgot-password",
            "/NewPassword",
            "/ResendEmail",
            "/VerifyEmail",
            "/resend_mail/:mail",
            "/activate_account",
            "/signup",
            "/login/primary",
            "/forgot-password",
            "/NewPassword",
            "/ResendEmail",
            "/VerifyEmail",
            "/resend_mail/:mail",
            "/activate_account",
            "/transform/reporting/selected-unsdgs",
            "/transform/reporting/reports",
            "/transform/reporting/reporting-framework",
            "/settings/plan&billing/",
            "/transform/hub/marketplace",
            "/transform/hub/marketlace/details",
            "/climate-concierge",
            "/payment/success",
            "/payment/fail",
            "transform/hub/industry-benchmarking",
            "/transform/hubdetail/industry-benchmarking",
        ];

        const result = checkArrayValuesInString(removedFrom, window.location.href);
        
        return (
            <>
                {!pageTitle && !result &&
                    <PageNotFound />
                }
            </>
        )
    }
}