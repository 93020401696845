import React from "react";
// Customizable Area Start

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";

import ReportBuilderController, {
    Props,
    configJSON,
} from "./ReportBuilderController";

import Layout from "../../../dashboard/src/Layout.web";
import { Box, Button, FormControl, IconButton, Link, MenuItem, Modal, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { reportIcon } from "../assets";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import TablePagination from "../../../dashboard/src/common/TablePagination.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const tableLabels = {
    title: "Report Title",
    description: "Report Year",
    action: "Action",
};

// Customizable Area End



// Customizable Area Start
export default class ReportBuilder extends ReportBuilderController {
    constructor(props: Props) {
        super(props);
    }

    renderReportList() {
        const updatedList = this.state.reportList.filter(report => report.report_status === 'completed');
        if(updatedList.length === 0){
            return <></>
        }
        return (
            <Box style={{ ...webStyle.innerContainer, paddingTop: 0 }}>
                <ReportTableContainer>
                    <ReportTable aria-label="users table">
                        <ReportTableHead>
                            <ReportTableRowHead>
                                <ReportTableCellHeadTitle>
                                    {tableLabels.title}
                                </ReportTableCellHeadTitle>
                                <ReportTableCellHead>{tableLabels.description}</ReportTableCellHead>
                                <ReportTableCellHead>{tableLabels.action}</ReportTableCellHead>

                            </ReportTableRowHead>
                        </ReportTableHead>
                        <ReportSpacing />
                        <ReportTableBody data-test-id="table-body">
                            {updatedList.map((report, index) => {
                                return (
                                    <TableRow key={index}>
                                        <ReportTableCellTitle>
                                            {report.report_name}
                                        </ReportTableCellTitle>
                                        <ReportTableCell>
                                            {report.report_year}
                                        </ReportTableCell>
                                        <ReportTableCell>
                                            {this.checkReportStatus(report.report_year) === 'completed' && (
                                                <Link style={webStyle.button} onClick={() => this.downloadHandler(report.attachment_url.url, report.report_name)}>
                                                    Download
                                                </Link>
                                            )}
                                        </ReportTableCell>
                                    </TableRow>
                                );
                            })}
                        </ReportTableBody>
                    </ReportTable>
                </ReportTableContainer>
                <div>
                    <TablePagination count={5} />
                </div>
            </Box>
        )
    }

    renderUNSDGModal() {
        return (
            <Modal 
              open={this.state.showAlert} 
              onClose={() => this.setState({showAlert: false})} 
              style={webStyle.modalWrapper} 
              data-testid="alert-modal"
              >
              <Paper style={webStyle.deleteModalPaper}>
                  <Typography style={webStyle.deleteMessage}>{configJSON.alertMessage}</Typography>
                  <Box style={webStyle.deletebuttonBox}>
                        <CustomButton
                            variant="contained"
                            onClick={this.navigateToUNSDG}
                            data-test-id="go-to-unsdg-btn"
                        >
                            Go To UN SDGs
                        </CustomButton>
                  </Box>
              </Paper>
            </Modal>
        )
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <Box style={webStyle.headerContainer}>
                        <Typography style={webStyle.titleText}>{configJSON.reportBuilderText}</Typography>
                        <Typography style={webStyle.descriptionText}>{configJSON.reportBuilderDescription}</Typography>
                    </Box>
                    <hr style={webStyle.hrLineText} />

                    {this.state.smapleRportList.length > 0 && (
                        <>
                            <Box style={webStyle.innerContainer}>
                                <Typography style={webStyle.sampleReportText}>{configJSON.viewSampleReports}</Typography>
                                <Typography style={webStyle.sampleDescriptionText}>{configJSON.sampleReportsDscription}</Typography>

                                <Box style={webStyle.rowContainer} data-test-id="sample-report-list">
                                    {this.state.smapleRportList.map((item, index) => {
                                        return (
                                            <Box style={webStyle.sampleCard} key={item.id} onClick={() => this.downloadHandler(item.sample_report_attachment_url, item.title)}>
                                                <img src={reportIcon} style={webStyle.sampleCardImg} />
                                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography style={webStyle.sampleCardLabel}>{item.title}</Typography>
                                                    <Typography style={webStyle.sampleCardTitle}>{item.description}</Typography>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                            <hr style={webStyle.hrLineText} />
                        </>
                    )}
                    
                    {this.checkReportStatus(`${new Date().getFullYear()}`) === 'in_process' && (
                        <>
                            <Box style={webStyle.innerContainer}>
                                <Typography style={webStyle.sampleReportText}>{configJSON.reportBeingViewedText}</Typography>
                                <Typography style={webStyle.sampleDescriptionText}>{configJSON.reportedViewedSubText}</Typography>
                                <Typography style={webStyle.subText} data-test-id="review-text">
                                    Would you like to discuss with our <span style={{...webStyle.subText, color: "#43D270", fontWeight: 700}} onClick={this.navigateToExpertSupport}>Expert</span>.
                                </Typography>
                            </Box>
                            <hr style={webStyle.hrLineText} />
                        </>
                    )}

                    <Box style={webStyle.innerContainer}>
                        <Typography style={webStyle.sampleReportText}>{configJSON.createOwnReport}</Typography>
                        <Typography style={webStyle.sampleDescriptionText}>{configJSON.createOwnReportDescription}</Typography>

                        <Box style={{ display: 'flex', alignItems: 'center', gap: '15px', marginTop: '20px', }}>
                            <AnalizeDropdown style={{ border: '1px solid #DFDFDF' }}>
                                <AnalizeSelectField
                                    displayEmpty
                                    data-test-id="duration-filter"
                                    IconComponent={() => (
                                        <>
                                            {this.state.openDropdown ?
                                                <ExpandLessIcon onClick={this.toggleDropDown} style={webStyle.arrowUpIcon} />
                                                :
                                                <ExpandMoreIcon onClick={this.toggleDropDown} style={webStyle.arrowUpIcon} />
                                            }
                                        </>
                                    )}
                                    open={this.state.openDropdown}
                                    onClose={() => this.closeDropDown()}
                                    onOpen={() => this.handleOpenDropDown()}
                                    value={this.state.selectedYear}
                                    onChange={this.handleValueChange}
                                    disableUnderline
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                                width: "100px",
                                                maxWidth: "100%",
                                            },
                                        },
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 395,
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 400,
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {
                                        [...this.state.yearsList].map((year) => (
                                            <MenuItem
                                                key={year}
                                                style={webStyle.menuItems}
                                                value={year}>
                                                {year}
                                            </MenuItem>
                                        ))
                                    }

                                </AnalizeSelectField>
                            </AnalizeDropdown>

                            <Button
                                data-test-id="report-builder-form-btn"
                                variant="contained"
                                style={webStyle.buttonContainer}
                                onClick={this.navigateToReportBuilderForm}
                            >
                                <Typography style={webStyle.buttonText}>Report Builder Form</Typography>
                            </Button>
                        </Box>
                    </Box>

                    {this.renderReportList()}
                    {this.renderUNSDGModal()}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.toastOpen}
                        autoHideDuration={4000}
                        data-testid="toast"
                        onClose={()=>this.setState({toastMessage:'', toastOpen: false})}
                        message={this.state.toastMessage}
                        action={
                            <React.Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={()=>this.setState({toastMessage:'', toastOpen: false})}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                </Layout>
            </ThemeProvider>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const ReportTableContainer = styled(TableContainer)({
    minHeight: "583px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
});

const ReportTable = styled(Table)({
    width: "100%",
    borderCollapse: "separate",
});

const ReportTableBody = styled(TableBody)({
    color: "inherit",
});

const ReportSpacing = styled("div")({
    height: 16,
    display: "table-header-group",
});

const ReportTableCellHead = styled(TableCell)({
    minHeight: "48px",
    padding: "8px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const ReportTableCellHeadTitle = styled(TableCell)({
    minHeight: "48px",
    padding: "8px 18px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
    minWidth: '240px'
});

const ReportTableCell = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const ReportTableCellTitle = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px 8px 8px 18px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const ReportTableHead = styled(TableHead)({
    backgroundColor: "#F5F5F5",
    borderRadius: "10px",
});

const ReportTableRowHead = styled(TableRow)({
    borderRadius: "20px !important",
    overflow: "hidden",
    "& :first-child": {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    "& :last-child": {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
});

const AnalizeDropdown = styled(FormControl)({
    borderRadius: '8px',
    width: "100%",
    maxWidth: "100px !important",
    border: '1px solid rgba(0, 0, 0, 0.2)',
})

const AnalizeSelectField = styled(Select)({
    height: "36px",
    borderRadius: "8px",
    padding: "8px !important;",
    "& *": {
        background: "#fff !important;",
        fontFamily: "Inter",
        fontWeight: 600,
        fontSize: "13.33px",
        lineHeight: "21.33px",
        color: '#34373A',
        paddingRight: '0px !important'
    },
    '&:focus': {
        borderColor: '#DFDFDF',
    },
    '& .MuiInputBase-input-209': {
        color: '#34373A',
        fontSize: '12px',
        lineHeight: '19.2px',
        fontFamily: 'Lato',
        fontWeight: 600,
    }
});

const CustomButton = styled(Button)({
    background: '#43D270',
    height: '32px',
    borderRadius: '8px',
    padding: '0px 20px',
    color: '#34373A',
    fontSize: '12px',
    fontWeight: 700,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: 'Lato',
    '&:hover': {
        background: '#43D270',
        color: '#34373A',
        boxShadow: 'none'
    }
});

const webStyle = {
    headerContainer: {
        marginTop: '32px',
        padding: "0 44px 30px",
    },
    titleText:{
        fontSize: '34px',
        lineHeight: '46px',
        fontWeight: 700,
        fontFamily: 'Montserrat',
        color: '#34373A',
        marginBottom: '10px',
    },
    descriptionText:{
        fontSize: '16px',
        lineHeight: '26px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#34373A',
    },
    innerContainer:{
        padding: "40px 44px",
    },
    sampleReportText:{
        fontSize: '20px',
        lineHeight: '25px',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        color: '#34373A',
        marginBottom: '6px'
    },
    sampleDescriptionText:{
        fontSize: '12px',
        lineHeight: '26px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#7D7D7F',
    },
    rowContainer:{
        marginTop: '27px',
        display: 'flex',
        alignItems: 'center',
        gap: '24px'
    },
    sampleCard:{
        width: '264px',
        height: '104px',
        border: '1px solid #EBEBEB',
        borderRadius: '12px',
        padding: '28px 24px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    sampleCardImg:{
        width: '48px',
        height: '48px',
        marginRight: '16px',
        cursor: 'pointer'
    },
    sampleCardLabel:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 500,
        fontFamily: 'Lato',
        color: '#7D7D7F',
        marginBottom: '4px',
        cursor: 'pointer'
    },
    sampleCardTitle:{
        fontSize: '16px',
        lineHeight: '22.4px',
        fontWeight: 600,
        fontFamily: 'Lato',
        color: '#34373A',
    },
    buttonText:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#34373A',
        cursor:'pointer'
    },
    buttonContainer:{
        background: "#43D270",
        padding: '8px 10px',
        borderRadius: '8px',
        textTransform:'capitalize',
    } as React.CSSProperties,
    menuItems: {
        maxWidth: '92px !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: "1px !important",
        fontSize: '13.33px',
        fontWeight: 600,
        lineHeight: "21.33px",
        color: '#34373A',
    } as React.CSSProperties,
    arrowUpIcon: {
        cursor: 'pointer',
        fontSize: '20px',
        height: '20px',
        color: '#000'
    },
    subText:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#7D7D7F',
        cursor:'pointer'
    },
    button: {
        minWidth: '140px',
        maxWidth: '140px',
        minHeight: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
        borderRadius: "8px",
        textDecoration: "none",
        border: "1px solid #000",
        cursor:'pointer',
    },
    hrLineText:{
        height: "1px",
        backgroundColor: "#EBEBEB",
        border: "none"
    },
    modalWrapper:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      mdalPaper:{
        width: '100%',
        maxWidth: "899px",
        padding: '30px',
        borderRadius: '12px',
        overflow: 'scroll' as 'scroll',
        maxHeight: '90vh',
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none'
      },
      deleteModalPaper:{
        width: '100%',
        maxWidth: "334px",
        padding: '30px',
        borderRadius: '12px',
        overflow: 'scroll' as 'scroll',
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none'
      },
      deleteMessage:{
        color:'#34373A',
        lineHeight:'24px',
        margin:'10px 0 40px',
        fontFamily:'Lato'
      },
      deletebuttonBox:{
        display:'flex',
        alignItems:'center',
        gap:'20px',
        justifyContent:'center'
      },
};
// Customizable Area End
