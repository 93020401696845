import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData } from "../../../../framework/src/Utilities";
// Customizable Area Start


// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    yearsList: string[];
    selectedYear: string;
    openDropdown:boolean;
    smapleRportList: {
        id: number;
        title: string;
        description: string;
        created_at: string;
        sample_report_attachment_url: string;
    }[];
    toastOpen: boolean;
    toastMessage: string;
    reportList:  {
        "id": number;
        "account_id": number;
        "report_name": string;
        "report_year": string;
        "report_status": string;
        "report_generated_at": string;
        "attachment_url": {filename:string; url: string;};
        "created_at": string;
        "updated_at": string;
    }[];
    unsdgList : {
        id: number;
        goal_id: number;
        goal_title: string;
        goal_name: string;
        goal_description: string;
        goal_icon: string;
        goal_enabled: boolean;
        goal_green_icon: string;
    }[];
    showAlert: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ReportBuilderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    account_id: string;
    company_id: string;
    user_type: string;
    sampleReportApiCallId: string = "";
    reportsListApiCallId: string = "";
    downloadApiCallId: string = "";
    unsdgApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
        const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
        this.account_id = userDetails.meta.id;
        this.user_type = userDetails.meta.user_account_type;
        this.company_id = orgDetails.id;


        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            yearsList: [],
            selectedYear: `${new Date().getFullYear()}`,
            openDropdown: false,
            smapleRportList: [],
            toastOpen: false,
            toastMessage: '',
            reportList: [],
            unsdgList: [],
            showAlert: false,
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const currentYear = new Date().getFullYear();
        let startYear = 2023;
        const list = [`${startYear}`];
        
        while (startYear < currentYear) {
            list.push(`${startYear + 1}`);
            startYear += 1;
        }
        
        this.setState({yearsList: list});
        await this.fetchSampleReports();
        await this.fetchReportsList();
        await this.fetchUNSDGList();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const requestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if(requestCallId === this.sampleReportApiCallId){
                this.sampleReportResponseHandler(responseJson);
            }

            if(requestCallId === this.reportsListApiCallId){
               this.reportsListResponseHandler(responseJson);
            }

            if (requestCallId === this.downloadApiCallId) {
                this.downloadResponseHandler(responseJson);
            }

            if (requestCallId === this.unsdgApiCallId) {
                this.unsdgResponseHandler(responseJson);
            }
        }
    }

    sampleReportResponseHandler = (responseJson: any) => {
        if (responseJson.error) {
            this.setState({ toastOpen: true, toastMessage: responseJson.error });
        } else if (responseJson.data.length > 0) {
            this.setState({ smapleRportList: responseJson.data });
        }
    }

    reportsListResponseHandler = (responseJson: any) => {
        if(responseJson.error){
            this.setState({toastOpen:true, toastMessage: responseJson.error});
        } else if(responseJson?.length > 0) {
            this.setState({reportList: responseJson});
        }
    }

    unsdgResponseHandler = (responseJson: any) => {
        if (responseJson.unsdg_goals?.length > 0) {
            const list = responseJson.unsdg_goals.filter((item: any) => item?.goal_enabled);
            this.setState({ unsdgList: list });
        }
    }

    downloadResponseHandler = (responseJson: any) => {
        if (responseJson?.url) {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = responseJson.url;
            a.download = responseJson.file_name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(responseJson.url);
        }
    }

    navigateToReportBuilderForm = () => {
        const reportStatus = this.checkReportStatus(this.state.selectedYear);
        
        if(reportStatus){
            if(reportStatus === 'completed'){
                this.setState({toastMessage: configJSON.completedToastMessage, toastOpen: true })
            }
            if(reportStatus === 'in_process'){
                this.setState({toastMessage: configJSON.inProcessToastMessage, toastOpen: true })
            }
        } else if (this.state.unsdgList.length === 0) {
            this.setState({showAlert: true});
        } else {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "ReportBuilderForm");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            const params = { selectedYear: this.state.selectedYear };
            const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
            raiseMessage.addData(getName(MessageEnum.HelpCentreMessageData), params);
            message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(message);
        }
    }

    toggleDropDown = () => {
        this.setState(prevState => ({openDropdown: !prevState.openDropdown}))
    }

    closeDropDown = () => {
        this.setState({openDropdown: false});
    }

    handleOpenDropDown = () => {
        this.setState({openDropdown: true});
    }

    handleValueChange = (e: any) => {
        this.setState({ selectedYear: e.target.value });
      }

    fetchSampleReports = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken"),
        };
        
        const body: any = { account_id: this.account_id };

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sampleReportApiCallId = apiRequest.messageId;
        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sampleReportEndPoint);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

        runEngine.sendMessage(apiRequest.id, apiRequest);
    }

    fetchReportsList = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken"),
        };
        
        const body: any = { account_id: this.account_id };
        if(this.user_type === 'portfolio_manager'){
            body.company_id = this.company_id;
        }

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        
        this.reportsListApiCallId = apiRequest.messageId;

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.reportsListEndPoint);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

        runEngine.sendMessage(apiRequest.id, apiRequest);
    }

    checkReportStatus = (year:string) => {
        const {reportList} = this.state;
        if(reportList.length > 0){
            const report = reportList.find(item => item.report_year === year);
            return report?.report_status ?? '';
        }
        return '';
    }

    downloadHandler = (url: string, fileName: string) => {
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        
        this.downloadApiCallId = apiRequest.messageId;

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        apiRequest.addData(getName(MessageEnum.BlobResponseData), true);
        apiRequest.addData(getName(MessageEnum.BlobResponseFileName), fileName ?? "downloadFIle");

        runEngine.sendMessage(apiRequest.id, apiRequest);
    }

    navigateToExpertSupport = () => {
        this.props.navigation.navigate('ExpertSupport',{orgTab: 'portfolio-manager'});
    }

    fetchUNSDGList = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken"),
        };
        
        const body: any = { account_id: this.account_id };
        if(this.user_type === 'portfolio_manager'){
            body.company_id = this.company_id;
        }

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        
        this.unsdgApiCallId = apiRequest.messageId;

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.unSDGEndPoint);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

        runEngine.sendMessage(apiRequest.id, apiRequest);
    }

    navigateToUNSDG = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "UNSDGS");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    // Customizable Area End
}
