import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  blogPageData: any;
  pageTitle: string;
  blogDetails: any;
  jobDetails: any;
  blogId: number;
  searchResultData: any;
  pageId: string;
  sort: string;
  searchString: string;
  page: number;
  pathName: string;
  isLoading: boolean;
  pageHeading: string;
  pageSubHeading: string;
  isLoadingBlogDetails: boolean;
  paginationStarted: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BlogPostsManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBlogPageDataApiCallId: string = ""
  getBlogDetailsDataApiCallId: string = ""
  getJobDetailsDataApiCallId: string = ""
  serchBlogsApiCallId: string = ""

  blogsRef = createRef<null | HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      blogPageData: [],
      pageTitle: '',
      blogDetails: [],
      jobDetails: [],
      blogId: 0,
      searchResultData: {},
      pageId: "0",
      sort: "DESC",
      searchString: "",
      page: 1,
      pathName: "",
      isLoading: false,
      pageHeading: "",
      pageSubHeading: "",
      isLoadingBlogDetails: false,
      paginationStarted: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestId === this.getBlogPageDataApiCallId) {
      this.handleMarketPlacePageResult(response);
    }

    if (apiRequestId === this.getBlogDetailsDataApiCallId) {
      this.setBlogDetails(response);
    }

    if (apiRequestId === this.getJobDetailsDataApiCallId) {
      this.setJobDetails(response);
    }

    if (apiRequestId === this.serchBlogsApiCallId) {
      this.setState({ searchResultData: response, isLoading: false })
    }

    // window && window.scrollTo(0, 0);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  getBlogPageData = (pageTitle: any, pageNumber: number = 1) => {
    const header = {
      "Content-Type": configJSON.blogPostsManagementApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBlogPageDataApiCallId = requestMessage.messageId;

    const httpBody = {
      page_title: pageTitle,
      page_no: pageNumber
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blogPostsManagementApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blogPostsManagementApiMethodPost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMarketPlacePageResult = (res: any) => {
    if (this.blogsRef.current !== null && this.state.paginationStarted) {
      this.blogsRef.current.scrollIntoView();
    }

    this.setState({ 
      blogPageData: res.data,
      pageHeading: res.page_heading,
      pageSubHeading: res.page_sub_heading,
      paginationStarted: false,
    });
  };

  setPageTitle = (title: string) => {
    this.setState({ pageTitle: title });
  };

  getBlogPageTitle() {
    const pathName = window.location.pathname;
    const pathnameBlog = pathName?.toLowerCase();

    switch (pathnameBlog) {
      case "/news-room":
        return "ECO Marketplace";
      case "/marketplace":
        return "ECO Marketplace";
      case "/events":
        return "Events";
      case "/insights-and-trends":
        return "Insights & Trends";
      case "/job-openings-list":
        return "Job Openings list";
      default:
        return "ECO Marketplace";
    }

  }

  getResourceType = () => {
    const resourceSegment = window.location.pathname.split("/")[1];
    if (resourceSegment === "insights-and-trends") {
      return "insight_trend_item";
    } else if (resourceSegment === "events") {
      return "event_item";
    } else if (resourceSegment === "marketplace") {
      return "marketplace_item"
    }
    return "";
  }

  navigateToDetails = (id: any, path: string, relatedPost: boolean = false) => {
    window.scrollTo(0, 0);
    const redirectPath: any = {
      "/marketplace": "marketplaceDetails",
      "/events": "eventsDetails",
      "/insights-and-trends": "insightsDetails",
      "/job-openings-list": "JobDetails",
    }

    if (id) {
      if (relatedPost) {
        this.getBlogDetails(id);
        this.setBlogId(id)
      }
      this.props.navigation.navigate(redirectPath[path], { id })
    }
  };

  navigateToJobApplication = (name: string) => {
    this.props.navigation.navigate("JobApplicationDetail", {name})
  }

  handlePageChange = (page: number) => {
    const pageTitle = this.getBlogPageTitle();
    this.setPageTitle(pageTitle);
    this.setState({ page, paginationStarted: true });

    if (page && this.state.pageId === '0') {
      this.getBlogPageData(pageTitle, page);
      this.setBlogId(0);
    } else {
      const { searchString, pageId, sort } = this.state
      this.getSearchResult(searchString, pageId, sort, page)
    }
  }

  getBlogDetails = (id: any) => {

    this.setState({ isLoadingBlogDetails: true });

    const resourceType = this.getResourceType();
    const header = {
      "Content-Type": configJSON.blogPostsManagementApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blogDetailsApiEndPoint}/${id}?type=${resourceType}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blogPostsManagementApiMethodTypeGet
    );

    this.getBlogDetailsDataApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobDetails = (id: any, type: string) => {
    const header = {
      "Content-Type": configJSON.blogPostsManagementApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blogDetailsApiEndPoint}/${id}?type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blogPostsManagementApiMethodTypeGet
    );

    this.getJobDetailsDataApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentDidMount() {
    const pathName = window.location.pathname;
    const pathnameBlog = pathName.toLowerCase();
    if (pathnameBlog) {
      this.setState({ pathName: pathnameBlog })
    }

    const pageTitle = this.getBlogPageTitle();
    this.setPageTitle(pageTitle);

    const blogId = this.props.navigation.getParam('id');
    const type = this.props.navigation.getParam('type');
    if (blogId) {
      if (type) {
        this.getJobDetails(blogId, type);
      } else {
        this.getBlogDetails(blogId);
      }
      this.setBlogId(blogId);
    } else {
      this.getBlogPageData(pageTitle);
      this.setBlogId(0);
    }
    window && window.scrollTo(0, 0);
  }

  setJobDetails = (res: any) => {
    this.setState({jobDetails: res.data});
  }

  setBlogDetails = (res: any) => {
    this.setState({ blogDetails: res.data, isLoadingBlogDetails: false });
  };

  setBlogId = (id: number) => {
    this.setState({ blogId: id });
  };

  getSearchResult = (
    search: any, 
    pageId: string, 
    sort: string, 
    pageNumber: number = 1
  ) => {
    this.setState({ isLoading: true });
    const resourceType = this.getResourceType();

    const header = {
      "Content-Type": configJSON.blogPostsManagementApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serchBlogsApiCallId = requestMessage.messageId;

    const httpBody = {
      "widget_item_id": Number(pageId),
      "query": search,
      "sort_by": sort,
      "page_no": pageNumber,
      "item_type": resourceType,
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blogSearchApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blogPostsManagementApiMethodPost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChangeSearch = (value: any, pageId: string) => {
    if (!value) {
      this.setState({ pageId: "0", searchString: "" })
    } else {
      this.setState({ pageId, searchString: value, page: 1 })
      this.getSearchResult(value, pageId, this.state.sort)
    }

  }

  handleChangeSort = (value: any, pageId: string) => {
    if (!value) {
      this.setState({ pageId: "0" })
    } else {
      this.setState({ pageId, page: 1 })
      this.setState({ sort: value })
      this.getSearchResult(this.state.searchString, pageId, value)
    }

  }

  navigateToPrivacyPolicyPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "pivacypolicy");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  navigateToContactUsPage = () => {
    this.props.navigation.navigate("ContactUsPage")
  }
  // Customizable Area End
}
