import React from "react";
import { 
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Card from "../../../cfunsdgs/src/components/GoalCard";
import { no_goals } from "../assets";
import { 
  PageHeaadingContainer,
  NoGoalsContainer,
  NoGoalsText,
  CircularProgressContainer,
  PageHeading,
  SaveButton,
} from "../../../cfunsdgs/src/Cfunsdgs.web";

import CfunsdgsController, {
  Props,
} from "../../../cfunsdgs/src/CfunsdgsController";

const GoalsContainer = styled("div")({
	width: "100%",
	minHeight: "calc(100vh - 185px)",
});

export default class SelectedUnsdgGoals extends CfunsdgsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        {
            this.state.isLoading ? (
              <CircularProgressContainer>
                <CircularProgress color="inherit" />
              </CircularProgressContainer>
            ) : (
              <GoalsContainer>
                <PageHeaadingContainer>
                  <PageHeading variant="h1">
                    {this.state.unsdgGoals.length} UN SDGs
                  </PageHeading>
                  <SaveButton 
                    disableElevation
                    disabled={!this.state.goalChangeDetected}
                    variant="contained"
                    onClick={this.saveUNSDGGoals}
                  >
                    Save
                  </SaveButton>
                </PageHeaadingContainer>
                {
                  this.state.unsdgGoals.length === 0 && (
                    <NoGoalsContainer>
                      <img src={no_goals} alt="no goals found" />
                      <NoGoalsText>No goals found</NoGoalsText>
                    </NoGoalsContainer>
                  )
                }
                <Grid container spacing={2}>
                  {this.state.unsdgGoals.map((goal) => {
                    return (
                      <Grid item xs={12} sm={6} md={3} key={goal.id}>
                        <Card
                          showRemove
                          goalNumber={goal.goal_name}
                          goalEnabled={goal.goal_enabled}
                          goalTitle={goal.goal_title}
                          goalIcon={goal.goal_icon}
                          goalSelectedIcon={goal.goal_green_icon}
                          goalDescription={goal.goal_description}
                          onSelectClick={() => this.handleGoalSelection(goal.id)}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </GoalsContainer>
            )
        }
      </>
    );
  }
}