import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef } from "react";
import { getStorageData } from "../../../../framework/src/Utilities";
// Customizable Area Start
type FileType = File | null | { url: string; filename: string;};
class FormDataMock {
    data: Record<string, any> = {};  
    append(key: string, value: any) {
      this.data[key] = value;
    }
  
    get(key: string) {
      return this.data[key];
    }
  }
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeStep: number;
    generalObj : {
        mission:string;
        vision: string;
        welcome_statement: string;
        sustainability_snapshot: FileType;
        key_highlights: string;
        philosophy: string;
        engagement1: string;
        engagement2: string;
        awards: {id: string; award: string; award_file: FileType;}[];
        accolades: string;
        memberships: {id:string; membership: string; membership_details: string;}[];
        partnerships: string;
        community: string;
        initative1: string;
        initative2: string;
        initative3: string;
        initative4: string;        
    };
    generalObjError:{
        mission:string;
        vision: string;
        welcome_statement: string;
        sustainability_snapshot: string;
        key_highlights: string;
        philosophy: string;
        engagement1: string;
        engagement2: string;
        awards: {award: string; award_file: string;}[];
        accolades: string;
        memberships: {membership: string; membership_details: string;}[];
        initative1: string;
        initative2: string;
        initative3: string;
        initative4: string;
    };
    esgPerformanceObj:{
        circular_economy_initatives: string;
        membership: string;
        resource_efficiencey: string;
        well_being: string;
        partnerships: string;
        community: string;
        diversity_efforts: string;
        certifications:{id: string; title: string; certificate: FileType;}[];
        ethical_policies:{id:string; name: string;}[];
        data_security: string;
    };
    esgPerformanceObjError: {
        circular_economy_initatives: string;
        membership: string;
        resource_efficiencey: string;
        well_being: string;
        partnerships: string;
        community: string;
        diversity_efforts: string;
        certifications:{title: string; certificate: string;}[];
        ethical_policies:string[];
        data_security: string;
    };
    unsdgList : {
        id: number;
        goal_id: number;
        goal_title: string;
        goal_name: string;
        goal_description: string;
        goal_icon: string;
        goal_enabled: boolean;
        goal_green_icon: string;
    }[];
    choosenUNSDG: {goal_id: number; id: number; goal_title:string; details: string;}[];
    choosendUNSDGError: string[];
    selectedYear: string;
    toastOpen: boolean;
    toastMessage: string;
    isUpdatingReport: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ReportBuilderFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    submitForm() {
        this.formData = new FormData();
    
        this.formData.append("account_id", this.account_id);
    
        this.state.generalObj.awards.forEach((item, index) => {
            this.formData.append(
                `report_builder[general_information_attributes][awards_attributes][${index}][name]`,
                item.award
            );
    
            if (item.id && item.id !== "") {
                this.formData.append(
                    `report_builder[general_information_attributes][awards_attributes][${index}][id]`,
                    item.id
                );
            }
    
            if (item.award_file && item.award_file instanceof File) {
                this.formData.append(
                    `report_builder[general_information_attributes][awards_attributes][${index}][award_attachment]`,
                    item.award_file
                );
            }
        });
    }
       
    // Customizable Area Start
    account_id: string;
    user_type: string;
    company_id: string;
    tabOptions: string[] = ["General", "ESG Performance", "UN SDGs"];
    fileInputRef:any =  createRef();
    awardFileInputRef:any =  [];
    certificateFileInputRef:any =  [];
    unsdgApiCallId: string = "";
    generalInfoApiCallId: string = "";
    esgPerformanceApiCallId: string = "";
    unSDGListUpdateApiCallId: string = "";
    getReportApiCallId: string = "";
    formData = new FormData();

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
        const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
        this.account_id = userDetails?.meta?.id ?? "default-id"; 
        this.user_type = userDetails?.meta?.user_account_type ?? "default-type";  
        this.company_id = orgDetails?.id ?? "default-org"; 

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            activeStep: 0,
            generalObj: {
                mission: "",
                vision: "",
                welcome_statement: "",
                sustainability_snapshot: null,
                key_highlights: "",
                philosophy: "",
                engagement1: "",
                engagement2: "",
                awards: [{id: "", award:'', award_file: null}],
                accolades: "",
                memberships: [{id: '', membership: '', membership_details: ''}],
                initative1: "",
                partnerships: "",
                community: "",
                initative2: "",
                initative3: "",
                initative4: ""
            },
            generalObjError:{
                mission: "",
                vision: "",
                welcome_statement: "",
                sustainability_snapshot: "",
                key_highlights: "",
                philosophy: "",
                engagement1: "",
                engagement2: "",
                awards: [],
                accolades: "",
                memberships: [],
                initative1: "",
                initative2: "",
                initative3: "",
                initative4: ""
            },
            esgPerformanceObj:{
                circular_economy_initatives: "",
                membership: "",
                resource_efficiencey: "",
                well_being: "",
                partnerships: "",
                community: "",
                diversity_efforts: "",
                certifications: [{id: "", title: "", certificate: null, }],
                ethical_policies: [{id: '', name: ''}],
                data_security: ""
            },
            esgPerformanceObjError:{
                circular_economy_initatives: "",
                membership: "",
                resource_efficiencey: "",
                well_being: "",
                partnerships: "",
                community: "",
                diversity_efforts: "",
                certifications: [],
                ethical_policies: [],
                data_security: ""
            },
            unsdgList: [],
            choosenUNSDG: [],
            choosendUNSDGError:[],
            selectedYear: `${new Date().getFullYear()}`,
            toastOpen: false,
            toastMessage: '',
            isUpdatingReport: false,
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const navigationProps = message.getData(
                getName(MessageEnum.HelpCentreMessageData)
            );
            if (navigationProps.selectedYear) {
                this.setState({ selectedYear: navigationProps.selectedYear });
            }
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            this.handleApiResponse(message);
        }
    }

    handleApiResponse = (message: Message) => {
        const requestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (requestCallId === this.unsdgApiCallId) {
            this.unsdfGoalsResponseHandler(responseJson);
        }

        if(requestCallId === this.generalInfoApiCallId){
            this.generalInfoResponseHandler(responseJson);
        }

        if(requestCallId === this.esgPerformanceApiCallId) {
            this.esgPerformanceResponseHandler(responseJson)
        }

        if(requestCallId === this.unSDGListUpdateApiCallId){
            this.unSDGListResponseHandler(responseJson);
        }

        if(requestCallId === this.getReportApiCallId){
            this.reportResponseHandler(responseJson);
        }
    }

    esgPerformanceResponseHandler = (responseJson:any) => {
        this.setState({isUpdatingReport: false});
        if(responseJson.error){
            this.setState({toastOpen: true, toastMessage: responseJson.error});
        } else{
            this.setState({activeStep: 2});
        }
    }

    unSDGListResponseHandler = (responseJson: any) => {
        this.setState({isUpdatingReport: false});
        if (responseJson.error) {
            this.setState({ toastOpen: true, toastMessage: responseJson.error });
        } else {
            localStorage.removeItem('reportBuilderId');
            this.navigateToReportBuilder()
        }
    }

    setGenerInfo = (generalInfo: any) => {
        if (generalInfo !== null) {
            localStorage.setItem("reportBuilderId", generalInfo.report_builder_id);
            const awardsList = generalInfo.awards?.length > 0 ? generalInfo.awards.map((item:any) => ({ id: item.id, award: item.name, award_file: item.award_attachment_url ?? null })) : [{ id: "", award: '', award_file: null }];
            const membershipList = generalInfo.memberships?.length > 0 ? generalInfo.memberships.map((item: any) => ({ id: item.id, membership: item.name, membership_details: item.description })) : [{ id: '', membership: '', membership_details: '' }];
            this.setState({
                generalObj: {
                    mission: generalInfo.mission,
                    vision: generalInfo.vision,
                    welcome_statement: generalInfo.welcome_statement,
                    sustainability_snapshot: generalInfo.sustainability_snap_url ?? null,
                    key_highlights: generalInfo.highlights,
                    philosophy: generalInfo.philosophy,
                    engagement1: generalInfo.engagement_01,
                    engagement2: generalInfo.engagement_02,
                    awards: [...awardsList],
                    accolades: generalInfo.accolades,
                    memberships: [...membershipList],
                    partnerships: "Partnerships",
                    community: "Community",
                    initative1: generalInfo.indicator_01,
                    initative2: generalInfo.indicator_02,
                    initative3: generalInfo.indicator_03,
                    initative4: generalInfo.indicator_04
                }
            });
        }
    }

    setESGPerformance = (esgPerformance:any) => {
        if(esgPerformance !== null) {
            const certificateList = esgPerformance.esg_performance_certifications?.length > 0 ? esgPerformance.esg_performance_certifications.map((item:any) => ({ id: item.id, title: item.name, certificate: item.certification_attachment_url ?? null })) : [{ id: '', title: '', certificate: null }];
            const policiesList = esgPerformance.esg_performance_ethical_policies?.length > 0 ? esgPerformance.esg_performance_ethical_policies.map((item:any) => ({id: item.id, name: item.name})) : [{id: '', name: ''}];
            this.setState({
                esgPerformanceObj:{
                    circular_economy_initatives: esgPerformance.circular_economy,
                    membership: esgPerformance.membership,
                    resource_efficiencey: esgPerformance.resource_efficiency,
                    well_being: esgPerformance.employee_well_being,
                    partnerships: esgPerformance.partenships,
                    community: esgPerformance.community,
                    diversity_efforts: esgPerformance.diversity_efforts,
                    certifications: [...certificateList],
                    ethical_policies: [...policiesList],
                    data_security: esgPerformance.data_security
                }
            });
        }
    }

    setUNSDG = (unSdg: any[]) => {
        if(unSdg?.length > 0){
            const list:any = [];
            this.state.choosenUNSDG.forEach(item =>{
                const obj = unSdg.find((ele:any) => item.goal_id === ele.unsdg_goals_id);
                if(obj){
                    list.push({
                        ...item,
                        details: obj.description,
                    })
                }
            });
            this.setState({choosenUNSDG:list})
        }
    }

    reportResponseHandler = (responsJson:any) =>{
        if(responsJson.error){
            this.setState({toastOpen:true, toastMessage: responsJson.error});
        } else if (responsJson.id) {
            const generalInfo:any = responsJson.general_information?.id ? responsJson.general_information : null;
            const esgPerformance:any = responsJson.esg_performance?.id ? responsJson.esg_performance : null;
            const unSdg:any[] = responsJson.report_builder_un_sdg?.length ? responsJson.report_builder_un_sdg : [];
            this.setGenerInfo(generalInfo);
            this.setESGPerformance(esgPerformance);
            this.setUNSDG(unSdg);
        }
    }

    unsdfGoalsResponseHandler = (responseJson:any) => {
        if(responseJson.error){
            this.setState({toastOpen: true, toastMessage: responseJson.error});
        } else if (responseJson.unsdg_goals?.length > 0) {
            const list = responseJson.unsdg_goals.filter((item:any) => item.goal_enabled).map((item:any) => {
                return {
                    id: item.id,
                    goal_id: item.goal_id,
                    goal_title: item.goal_title,
                    details: '',
                }
            });
            this.setState({ unsdgList: responseJson.unsdg_goals, choosenUNSDG: list });
        }
    }

    generalInfoResponseHandler = (responseJson:any) => {
        this.setState({isUpdatingReport: false});
        if(typeof responseJson.error === 'string'){
            this.setState({toastOpen: true, toastMessage: responseJson.error});
        } else if (responseJson.errors){
            this.setState({toastOpen: true, toastMessage: responseJson.errors[0]});
        } else if(responseJson.report_builder_id){
            localStorage.setItem("reportBuilderId", responseJson.report_builder_id);
            this.setState({activeStep: 1});
        }
    }

    async componentDidMount() {
        this.unsdgApiCallId = await this.apiCall({
            endPoint: configJSON.unSDGEndPoint,
            apiBody: this.user_type === 'portfolio_manager' ? {company_id : this.company_id} : null,
            apiMethod: configJSON.exampleAPiMethod
        });
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if(prevState.activeStep !== this.state.activeStep){
            const reportId = localStorage.getItem('reportBuilderId');
            window.scrollTo(0, 0);
            if(reportId){
                const body:any = {id: reportId};
                if(this.user_type === 'portfolio_manager'){
                    body.company_id = this.company_id;
                }
                this.getReportApiCallId = await this.apiCall({
                    endPoint: configJSON.getReportEndPoint,
                    apiBody: body,
                    apiMethod: configJSON.exampleAPiMethod,
                })
            }
        }
    }

    async componentWillUnmount() {
        localStorage.removeItem('reportBuilderId');
    }

    handleClick = () => {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.click();
        }
    };

    handleFileChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            this.setState(prevPorps => ({
                generalObj: {
                    ...prevPorps.generalObj,
                    sustainability_snapshot: file,
                },
            }));

            if (this.state.generalObjError?.sustainability_snapshot) {
                this.setState(prevProps => ({
                    generalObjError: {
                        ...prevProps.generalObjError,
                        sustainability_snapshot: '',
                    },
                }));
            }
        }
    };

    awardUploadHandler = (index:number) => {
        if (this.awardFileInputRef[index].current) {
            this.awardFileInputRef[index].current.click();
        }
    }

    awardsFileHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const list = [...this.state.generalObj.awards];
            list[index] = {
                ...list[index],
                award_file: file,
            }
            this.setState(prevPorps => ({
                generalObj: {
                    ...prevPorps.generalObj,
                    awards: list,
                },
            }));

            if (this.state.generalObjError.awards[index]?.award_file) {
                const list1 = [...this.state.generalObjError.awards];
                list1[index] = {
                    ...list1[index],
                    award_file: '',
                }
                this.setState(prevProps => ({
                    generalObjError: {
                        ...prevProps.generalObjError,
                        awards: list1,
                    },
                }));
            }
        }
    }

    certificateUploadHandler = (index:number) => {
        if (this.certificateFileInputRef[index].current) {
            this.certificateFileInputRef[index].current.click();
        }
    }

    certificateFileHandler = (event: React.ChangeEvent<HTMLInputElement>, index:number) => {
        const file = event.target.files && event.target.files[0];
        
        if (file && index !== null) {
            const list = [...this.state.esgPerformanceObj.certifications];
            list[index] = {
                ...list[index],
                certificate: file,
            }
            this.setState(prevProps => ({
                esgPerformanceObj: {
                    ...prevProps.esgPerformanceObj,
                    certifications: list,
                },
            }));

            if (this.state.esgPerformanceObjError.certifications[index]?.certificate) {
                const list1 = [...this.state.esgPerformanceObjError.certifications];
                list1[index] = {
                    ...list1[index],
                    certificate: '',
                }
                this.setState(prevProps => ({
                    esgPerformanceObjError: {
                        ...prevProps.esgPerformanceObjError,
                        certifications: list1,
                    },
                }));
            }
        }
    }

    apiCall = async (data: { endPoint: string; apiBody: any; apiMethod: string, isFormData?:boolean; }) => {
        const { endPoint, apiBody, apiMethod, isFormData = false } = data;
        const header = isFormData ? {
            "token": await getStorageData("authToken"),
        } : {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken"),
        };
        
        const body: any = { account_id: this.account_id, ...apiBody };

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), isFormData ? apiBody : JSON.stringify(body));

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return apiRequest.messageId;
    };

    bytesToMB(bytes:number) {
        return bytes / (1024 * 1024);
    }

    checkTextValidation = (valueString: string) => {
        if (valueString && valueString.trim().length > 500) {
            return 'Text should be less than 500 characters.';
        } 
        if(!valueString?.trim()) {
            return 'Required field. Please enter a value.';
        }
        return '';
    }

    checkFileValidation = (data:FileType) => {
        if(data === null) {
            return 'Required field. Please select file.';
        }

        if(data instanceof File && this.bytesToMB(data.size) > 2){
            return 'Exceeds maximum allowed size of 2MB.';
        }

        if(!(data instanceof File) && data.hasOwnProperty('url') && !data.url){
            return 'Required field. Please select file.';
        }

        return '';
    }

    checkGeneralInfoValidationMessages = () => {
        const {
            mission, 
            vision, 
            welcome_statement, 
            key_highlights, 
            philosophy, 
            engagement1, 
            engagement2,accolades,
            initative1,
            initative2,
            initative3,
            initative4,
            sustainability_snapshot,
            awards,
            memberships
        } = this.state.generalObj;

        this.setState(prevState => ({
            generalObjError:{
                ...prevState.generalObjError,
                mission: this.checkTextValidation(mission),
                vision: this.checkTextValidation(vision),
                welcome_statement: this.checkTextValidation(welcome_statement),
                key_highlights: this.checkTextValidation(key_highlights),
                philosophy: this.checkTextValidation(philosophy),
                engagement1: this.checkTextValidation(engagement1),
                engagement2: engagement2 ? this.checkTextValidation(engagement2) : '',
                initative1: this.checkTextValidation(initative1),
                initative2: initative2 ? this.checkTextValidation(initative2) : '',
                initative3: initative3 ?this.checkTextValidation(initative3) : '',
                initative4: initative4 ? this.checkTextValidation(initative4) : '',
                sustainability_snapshot: this.checkFileValidation(sustainability_snapshot),
            }
        }));

        const arr: { award: string; award_file: string; }[] = [];
        awards.forEach((item, index) => {
            arr[index] = {
                award: this.checkTextValidation(item.award),
                award_file: this.checkFileValidation(item.award_file),
            };
        });

        const arr1: { membership: string; membership_details: string; }[] = [];
        memberships.forEach((item, index) => {
            arr1[index] = {
                membership: this.checkTextValidation(item.membership),
                membership_details: this.checkTextValidation(item.membership_details),
            };
        });

        this.setState(prevState => ({
            generalObjError: {
                ...prevState.generalObjError,
                memberships: arr1,
                awards: arr,
            }
        }));   
    }

    checkOptionValidation = (value:string) =>{
        if(value){
            return value.length < 500;
        }
        return true;
    }

    checkGeneralInfoValidations = () =>{
        const {
            mission, 
            vision, 
            welcome_statement, 
            key_highlights, 
            philosophy, 
            engagement1, 
            engagement2,
            initative1,
            initative2,
            initative3,
            initative4,
            sustainability_snapshot,
            awards,
            memberships
        } = this.state.generalObj;
        this.checkGeneralInfoValidationMessages();
        const isAwardsValid = awards.filter(item => item.award && item.award_file !== null && !this.checkFileValidation(item.award_file)).length === awards.length;
        const isMembershipsValid = memberships.filter(item => item.membership && item.membership_details).length === memberships.length;

        return (mission && mission.length < 500) && 
            (vision && vision.length < 500) &&
            (welcome_statement && welcome_statement.length < 500) &&
            (key_highlights && key_highlights.length < 500) &&
            (philosophy && philosophy.length < 500) &&
            (engagement1 && engagement1.length < 500) &&
            this.checkOptionValidation(engagement2) &&
            (initative1 && initative1.length < 500) &&
            this.checkOptionValidation(initative2) && 
            this.checkOptionValidation(initative3) && 
            this.checkOptionValidation(initative4) &&
            (sustainability_snapshot !== null && !this.checkFileValidation(sustainability_snapshot)) && isAwardsValid && isMembershipsValid;
    }

    updateGeneralInfo = async () => {
        const reportId = localStorage.getItem('reportBuilderId');
        if(!this.checkGeneralInfoValidations() || this.state.isUpdatingReport){
            return;
        }
        const {
            mission, 
            vision, 
            welcome_statement, 
            key_highlights, 
            philosophy, 
            engagement1, 
            engagement2,accolades,
            initative1,
            initative2,
            initative3,
            initative4,
            sustainability_snapshot,
            awards,
            memberships,
        } = this.state.generalObj;
        this.setState({isUpdatingReport: true});
        const formData = new FormData();

        formData.append("account_id", this.account_id);
        if(this.user_type === 'portfolio_manager'){
            formData.append("company_id", this.company_id);
        }
        formData.append("report_builder[general_information_attributes][mission]", mission);
        formData.append("report_builder[general_information_attributes][vision]", vision);
        formData.append("report_builder[general_information_attributes][welcome_statement]", welcome_statement);
        formData.append("report_builder[general_information_attributes][highlights]", key_highlights);
        formData.append("report_builder[general_information_attributes][philosophy]", philosophy);
        formData.append("report_builder[general_information_attributes][engagement_01]", engagement1);
        formData.append("report_builder[general_information_attributes][engagement_02]", engagement2);
        formData.append("report_builder[general_information_attributes][accolades]", accolades);
        formData.append("report_builder[general_information_attributes][indicator_01]", initative1);
        formData.append("report_builder[general_information_attributes][indicator_02]", initative2);
        formData.append("report_builder[general_information_attributes][indicator_03]", initative3);
        formData.append("report_builder[general_information_attributes][indicator_04]", initative4);

        if(sustainability_snapshot && sustainability_snapshot instanceof File){
            formData.append("report_builder[general_information_attributes][sustainability_snap]", sustainability_snapshot);
        }
        formData.append("report_builder[report_year]", this.state.selectedYear);
        formData.append("step","general_information");

        awards.forEach((item, index) => {
            formData.append(`report_builder[general_information_attributes][awards_attributes][${index}][name]`, item.award);
            if (item.id && item.id !== '') {
                formData.append(
                    `report_builder[general_information_attributes][awards_attributes][${index}][id]`,
                    item.id
                );
            }
            if (item.award_file && item.award_file instanceof File) {
                formData.append(`report_builder[general_information_attributes][awards_attributes][${index}][award_attachment]`, item.award_file);
            }
        });

        memberships.forEach((item, index) => {
            formData.append(`report_builder[general_information_attributes][memberships_attributes][${index}][name]`, item.membership);
            formData.append(`report_builder[general_information_attributes][memberships_attributes][${index}][description]`, item.membership_details);
            if (item.id && item.id !== '') {
                formData.append(`report_builder[general_information_attributes][memberships_attributes][${index}][id]`, item.id);
            }
        });

        if(reportId){
            formData.append('id',reportId);
        }

       this.generalInfoApiCallId = await this.apiCall({
            endPoint: configJSON.saveReportEndPoint,
            apiBody: formData,
            apiMethod: configJSON.exampleAPiMethod,
            isFormData: true,
        });
    }

    esgPerformanceValidationMessage = () =>{
        const {
            circular_economy_initatives,
            membership,
            resource_efficiencey,
            well_being,
            partnerships,
            community,
            diversity_efforts,
            certifications,
            ethical_policies,
            data_security,
        } = this.state.esgPerformanceObj;

        this.setState(prevState => ({
            esgPerformanceObjError:{
                ...prevState.esgPerformanceObjError,
                circular_economy_initatives: this.checkTextValidation(circular_economy_initatives),
                membership: this.checkTextValidation(membership),
                resource_efficiencey: this.checkTextValidation(resource_efficiencey),
                well_being: this.checkTextValidation(well_being),
                partnerships: this.checkTextValidation(partnerships),
                community: this.checkTextValidation(community),
                diversity_efforts: this.checkTextValidation(diversity_efforts),
                data_security: this.checkTextValidation(data_security),
            }
        }));

        const arr: { title: string; certificate: string; }[] = [];
        certifications.forEach((item, index) => {
            arr[index] = {
                title: this.checkTextValidation(item.title),
                certificate: this.checkFileValidation(item.certificate),
            }
        });

        const arr1: string[] = [];
        ethical_policies.forEach((item, index) => {
            arr1[index] = this.checkTextValidation(item.name);
        });
        this.setState(prevState => ({
            esgPerformanceObjError: {
                ...prevState.esgPerformanceObjError,
                ethical_policies: arr1,
                certifications: arr,
            }
        }));
    }

    checkESGPerformanceValidations = () => {
        const {
            circular_economy_initatives,
            membership,
            resource_efficiencey,
            well_being,
            partnerships,
            community,
            diversity_efforts,
            certifications,
            ethical_policies,
            data_security,
        } = this.state.esgPerformanceObj;
        this.esgPerformanceValidationMessage();

        const isCertificationValid = certifications.filter(item => item.title && item.certificate !== null && !this.checkFileValidation(item.certificate)).length === certifications.length;
        const isEthicalPolicy = ethical_policies.filter(item => item.name).length === ethical_policies.length;

        return (circular_economy_initatives && circular_economy_initatives.length < 500) && 
            (membership && membership.length < 500) &&
            (resource_efficiencey && resource_efficiencey.length < 500) &&
            (well_being && well_being.length < 500) &&
            (partnerships && partnerships.length < 500) &&
            (community && community.length < 500) &&
            (diversity_efforts && diversity_efforts.length < 500) &&
            (data_security && data_security.length < 500) && isCertificationValid && isEthicalPolicy;
    }

    updateESFPerformance = async () => {
        const reportId = localStorage.getItem('reportBuilderId');

        if(!this.checkESGPerformanceValidations() || !reportId || this.state.isUpdatingReport){
            return;
        }

        const {
            circular_economy_initatives,
            membership,
            resource_efficiencey,
            well_being,
            partnerships,
            community,
            diversity_efforts,
            certifications,
            ethical_policies,
            data_security,
        } = this.state.esgPerformanceObj;
        this.setState({isUpdatingReport: true});
        const formData = new FormData();
        formData.append("step", "esg_performance");
        formData.append("account_id", this.account_id);
        if(this.user_type === 'portfolio_manager'){
            formData.append("company_id", this.company_id);
        }
        formData.append("report_builder[esg_performance_attributes][circular_economy]", circular_economy_initatives);
        formData.append("report_builder[esg_performance_attributes][membership]", membership);
        formData.append("report_builder[esg_performance_attributes][partenships]", partnerships);
        formData.append("report_builder[esg_performance_attributes][employee_well_being]", well_being);
        formData.append("report_builder[esg_performance_attributes][resource_efficiency]", resource_efficiencey);
        formData.append("report_builder[esg_performance_attributes][community]", community);
        formData.append("report_builder[esg_performance_attributes][diversity_efforts]", diversity_efforts);
        formData.append("report_builder[esg_performance_attributes][data_security]", data_security);

        certifications.forEach((item, index) => {
            formData.append(`report_builder[esg_performance_attributes][esg_performance_certifications_attributes][${index}][name]`, item.title);
            if (item.id) {
                formData.append(
                    `report_builder[esg_performance_attributes][esg_performance_certifications_attributes][${index}][id]`,
                    item.id
                );
            }
            if (item.certificate && item.certificate instanceof File) {
                formData.append(`report_builder[esg_performance_attributes][esg_performance_certifications_attributes][${index}][certification_attachment]`, item.certificate);
            }
        });

        ethical_policies.forEach((item, index) => {
            formData.append(`report_builder[esg_performance_attributes][esg_performance_ethical_policies_attributes][${index}][name]`, item.name);
            if (item.id && item.id !== '') {
                formData.append(`report_builder[esg_performance_attributes][esg_performance_ethical_policies_attributes][${index}][id]`, item.id);
            }
        });
        formData.append('id',reportId);

        this.esgPerformanceApiCallId = await this.apiCall({
            endPoint: configJSON.saveReportEndPoint,
            apiBody: formData,
            apiMethod: configJSON.exampleAPiMethod,
            isFormData: true,
        });
    }

    checkUNSDGValidations = () =>{
        const errors: string[] = this.state.choosenUNSDG.map((item) => {
            if (!item.details || !item.details.trim()) {
                return 'This is a required field.';
            }
            return this.checkTextValidation(item.details);
        });

        this.setState({ choosendUNSDGError: errors });

        return errors.every(error => error === '');
    }

    updateUNSDGList = async () => {
        const reportId = localStorage.getItem('reportBuilderId');
        if (!reportId || !this.checkUNSDGValidations() || this.state.isUpdatingReport) {
            return;
        }
        this.setState({isUpdatingReport: true});
        const formData = new FormData();
        formData.append("step", "report_builder_un_sdg");
        formData.append("account_id", this.account_id);
        if(this.user_type === 'portfolio_manager'){
            formData.append("company_id", this.company_id);
        }
        formData.append('id', reportId);
        this.state.choosenUNSDG.forEach((item, index) => {
            formData.append(`report_builder[report_builder_un_sdg_attributes][${index}][unsdg_goals_id]`, `${item.goal_id}`);
            formData.append(`report_builder[report_builder_un_sdg_attributes][${index}][description]`, item.details)
        });

        this.unSDGListUpdateApiCallId = await this.apiCall({
            endPoint: configJSON.saveReportEndPoint,
            apiBody: formData,
            apiMethod: configJSON.exampleAPiMethod,
            isFormData: true,
        });
    }

    navigateToReportBuilder = () =>{
        if(this.state.isUpdatingReport){
            return;
        }
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ReportBuilder");
        message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(message);
    }

    handleToastClose = () =>{
        this.setState({toastMessage: '', toastOpen: false});
    }

    handleInitiative1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prevProps => ({
            generalObj: {
                ...prevProps.generalObj,
                initative1: e.target.value,
            },
        }));

        if(this.state.generalObjError.initative1){
            this.setState(prevProps => ({
                generalObjError: {
                    ...prevProps.generalObjError,
                    initative1: '',
                },
            }));
        }
    }

    handleInitiative2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prevProps => ({
            generalObj: {
                ...prevProps.generalObj,
                initative2: e.target.value,
            },
        }));
        if(this.state.generalObjError.initative2){
            this.setState(prevProps => ({
                generalObjError: {
                    ...prevProps.generalObjError,
                    initative2: '',
                },
            }));
        }
    }

    handleInitiative3 = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prevProps => ({
            generalObj: {
                ...prevProps.generalObj,
                initative3: e.target.value,
            },
        }));

        if(this.state.generalObjError.initative3){
            this.setState(prevProps => ({
                generalObjError: {
                    ...prevProps.generalObjError,
                    initative3: '',
                },
            }));
        }
    }

    handleInitiative4 = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prevProps => ({
            generalObj: {
                ...prevProps.generalObj,
                initative4: e.target.value,
            },
        }));

        if(this.state.generalObjError.initative4){
            this.setState(prevProps => ({
                generalObjError: {
                    ...prevProps.generalObjError,
                    initative4: '',
                },
            }));
        }
    }

    getFileName = (item: FileType) => {
        if(item === null){
            return "Choose File";
        }

        if (item instanceof File && item?.name) {
            return item.name;
        }

        if (!(item instanceof File) &&  item?.filename) {
            return item.filename;
        }
        return "Choose File";
    }
    // Customizable Area End
}
