Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskAllocator";
exports.labelBodyText = "TaskAllocator Body";
exports.reportBuilderText = "Report Builder";
exports.reportBuilderDescription = "Streamline data, visualize insights, and deliver impactful sustainability reporting with our intuitive tools."
exports.viewSampleReports = "View our sample reports";
exports.sampleReportsDscription = "Explore examples of sustainability reports to inspire your own journey.";
exports.createOwnReport = "Create your own report";
exports.createOwnReportDescription = "Click on the form below to generate your own report effortlessly.";
exports.reportBuilderFormText = "Report Builder Form";
exports.reportBuilderFormDescription = "Create and generate your own sustainability report with ease. Get started!";
exports.unSDGEndPoint = "bx_block_cfunsdgs/unsdg_goals_list";
exports.saveReportEndPoint = "bx_block_custom_form/report_builders/save_report";
exports.getReportEndPoint = "bx_block_custom_form/report_builders/report_detail"
exports.sampleReportEndPoint = "bx_block_custom_form/report_builders/sample_report";
exports.reportsListEndPoint = "bx_block_custom_form/report_builders/approve_report_lists";

exports.btnExampleTitle = "CLICK ME";
exports.reportBeingViewedText = "Report is being reviewed by Carbonzeroed";
exports.reportedViewedSubText = `Your report is under process. You can download it by clicking "Download" once it's ready.`;
exports.completedToastMessage = "Report is already uploaded by Carbonzeroed. You can find the same report in report list."
exports.inProcessToastMessage = "Report is being reviewed by Carbonzeroed. You can find the same report in report list once uploaded by admin."
exports.alertMessage = "No UN SDGs selected. Please select at least 1 UN SDG from Act->UN SDGs to proceed with report submission."
// Customizable Area End