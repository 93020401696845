import React, { CSSProperties } from "react";
import { createTheme, ThemeProvider, makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Sidebar from "./Sidebar.web"
import RolesPermissions from "../../RolesPermissions/src/RolesPermissions.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff"
        }
    },
    typography: {
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px"
        }
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            position: 'absolute',
            top: '120px',
            maxWidth: `calc(100% - 250px)`,
            right: '0px',
            width: '100%',
            overflow: 'hidden',
            '@media(max-width: 1100px)': {
                width: '100%',
                maxWidth: '100%',
            },
            '@media(max-width: 768px)': {
                top: '60px'
            },
            '@media(min-width: 768px)': {
                top: '50px'
            },
            marginRight: '0px',
        }
    }),
);


const Layout = (props: any) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <div>
                <RolesPermissions navigation={undefined} id={""}/>
                <div className={classes.mainContainer}>
                    {props.children}
                </div>
            </div>
        </ThemeProvider>
    );
}

export default Layout
