Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.blogPostsManagementApiMethodTypeGet = "GET";
exports.blogPostsManagementApiEndPoint = "bx_block_blogpostsmanagement/get_resource_page";
exports.blogPostsManagementApiMethodPost = "POST";
exports.blogPostsManagementApiContentType = "application/json";
exports.labelTitleText = "ECO Marketplace";
exports.labelBodyText = "ECO Marketplace Body";
exports.blogDetailsApiEndPoint = "bx_block_blogpostsmanagement/resource_detailed_page"
exports.blogSearchApiEndPoint = "bx_block_advanced_search/filter"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End