import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface Header {
  token: string;
  "Content-Type"?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  placeholder: string | undefined;
  buttonLabel: string | undefined;
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  footerFormDesc?: string;
  footerDarkMode?: boolean;
  classes?: any;
  onPrivacyPolicyClick: () => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  errors: string;
  newsletterEmail: string;
  isUnsubscribing: boolean;
  hasUnsubscribed: boolean;
  readPrivacePolicy: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailNotifications2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  emailReg: any;
  EmailNoficationMessageId: string = '';
  validationApiCallId: string = '';
  newsletterUnsbuscribeApiCallId: string = '';
  newsletterResbuscribeApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      errors: '',
      newsletterEmail: "",
      isUnsubscribing: true,
      hasUnsubscribed: false,
      readPrivacePolicy: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.arrayholder = [];
    this.emailReg = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });

    }

    // Customizable Area Start
    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestId === this.EmailNoficationMessageId) {
      if (!response.data.success) {
        this.setState({ errors: response.data.error })
      } else {
        if (response.data.type === "newsletter") {
          window.location.replace("/subscribe");
        } else {
          this.props.setSubmitted(true)
        }
      }
    }
    if (apiRequestId === this.validationApiCallId) {
      this.arrayholder = response.data;

      if (this.arrayholder.length !== 0 && this.arrayholder[0].email_validation_regexp) {
        this.emailReg = new RegExp(this.arrayholder[0].email_validation_regexp);
      }
    }

    if (apiRequestId === this.newsletterUnsbuscribeApiCallId) {
      this.handleNewsletterUnsubscriptionResponse(response);
    }

    if (apiRequestId === this.newsletterResbuscribeApiCallId) {
      this.handleResubscribeNewsletterResponse(response);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    // Newsletter unsubscription
    if (window.location.pathname === "/unsubscribe") {
      // Extract email address from url
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const newsletterEmail = urlParams.get("email") || "";
      this.setState({ newsletterEmail });
      // Call api for unsubscription
      this.unsubscribeNewsletter(newsletterEmail);
    }  
  }

  // Common method for calling apis
  apiCall = async (data: any) => {
    const { method, endPoint, payload, contentType } = data;
    const token = localStorage.getItem("token") ?? "";
    const header: Header = { token };
  
    if (contentType) {
      header["Content-Type"] = contentType;
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
  
    payload &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        payload
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Unsubscribe from email newsletter
  unsubscribeNewsletter = async (email: string) => {
    this.newsletterUnsbuscribeApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.newsleterUnsubscriptionAPIEndPoint}?email=${email}`,
    });
  };

  // Handle newsletter unsubscription response
  handleNewsletterUnsubscriptionResponse = (response: any) => {
    if (!response.success) {
      this.setState({
        isUnsubscribing: false,
        hasUnsubscribed: false,
      });
      return;
    }
    this.setState({
      isUnsubscribing: false,
      hasUnsubscribed: true,
    });
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleSubscribe(page: string) {
    if (!this.state.txtInputValue) {
      this.setState({ errors: 'Please Enter Email' })
    } else if (!this.emailReg.test(this.state.txtInputValue)) {
      this.setState({ errors: 'Please Enter Valid Email' })
    } else {
      const header = {
        "Content-Type": configJSON.exampleApiContentType
      };

      let httpBody = {
        subscriber_email: this.state.txtInputValue,
        subscription_type: page
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.EmailNoficationMessageId = requestMessage.messageId

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emalAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }

  // Re-subscribe to newsletter
  resubscribeNewsletter = async () => {
    const payload = {
      subscriber_email: this.state.newsletterEmail,
      subscription_type: "newsletter"
    };
    this.newsletterResbuscribeApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.emalAPIEndPoint,
      payload: JSON.stringify(payload),
    });
  }

  // Handle click event on clicking resubscribe link
  handleResubscribeLinkClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.resubscribeNewsletter();
  }

  // Handle resubscribe newsletter response
  handleResubscribeNewsletterResponse = (response: any) => {
    if (response.data.success) {
      this.navigateToNewsletterSubscriptionSuccessPage();
    }
  }

  // Navigate to the newsletter subscription success page
  navigateToNewsletterSubscriptionSuccessPage = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "NewsLetterSubscriptionSuccess");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }

  togglePrivacyPolicyCheckbox = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        readPrivacePolicy: !prevState.readPrivacePolicy
      }
    })
  }

  // Customizable Area End
}
