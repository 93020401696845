import React from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Container } from "@material-ui/core"
import BlogPageHeadingSectionWeb from "./BlogPageHeadingSection.web";
import DynamicContentController, {
    Props,
} from "./DynamicContentController";
import parse from 'html-react-parser';
import Typography from '@material-ui/core/Typography';
import Heading from "../../../components/src/FreeAssessmentPage/Heading";
import { Helmet } from "react-helmet";


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

export default class PrivacyPolicy extends DynamicContentController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        this.getPrivacyPolicyData()
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Helmet>
                    <link rel="canonical" href="https://www.carbonzeroed.com/terms-and-conditions" />
                    <link rel="canonical" href="https://www.carbonzeroed.com/privacy-policy" />
                </Helmet>
                <Container>
                    <Heading
                        heading={this.state.PrivacyPolicyData[0]?.attributes?.title}
                    />
                    {this.state.PrivacyPolicyData[0]?.attributes?.body &&
                        <Typography style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                            {parse(this.state.PrivacyPolicyData[0]?.attributes?.body.toString())}
                        </Typography>

                    }
                </Container>
            </ThemeProvider>
        );
    }
}