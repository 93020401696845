import React, { CSSProperties } from "react";
// Customizable Area Start
import {
    Typography,
    // Customizable Area Start
    Divider,
    Select,
    IconButton,
    FormControl,
    MenuItem, Tooltip
    // Customizable Area End
} from "@material-ui/core";
import Layout from "./Layout.web";
import {createTheme, ThemeProvider, styled, withStyles} from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import VisualAnalytics from "../../visualanalytics/src/VisualAnalytics.web";
import { overviewbg, arrows, info, measure, emmision, dsc_report, env_report, org_report } from "./assets";
import { getMonthYear } from "./common/MonthYearSelect.web";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { setStorageData } from "../../../framework/src/Utilities";
import ForeCastAnalysis from "./pages/ForecastAnalysis.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff"
        }
    },
    typography: {
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px"
        }
    },
});

const TaskContainer = styled('div')({
    '@media(max-width: 880px)': {
        flexDirection: 'column',
        minHeight: '120px'
    },
    '@media(max-width: 1330px)': {
        width: '100% !important',
        padding: '20px !important'
    },
});

const SubItems = styled('div')({
    '@media(max-width: 675px)': {
        flexDirection: 'column',
        minHeight: '120px'
    },
});

const PieChart = styled('div')({
    '@media(max-width: 675px)': {
        marginRight: '30px'
    },
});

const AnalizeDropdown = styled(FormControl)({
    borderRadius: '8px',
    width: "100%",
    maxWidth: "67px !important",
    border: '1px solid rgba(0, 0, 0, 0.2)',
})

const EnviromentDropdown = styled(FormControl)({
    borderRadius: '8px',
    width: "100%",
    maxWidth: "67px !important",
    border: '1px solid rgba(0, 0, 0, 0.2)',
    maxHeight: "36px",
})

const YearDropdown = styled(FormControl)({
    borderRadius: '8px',
    width: "100%",
    maxWidth: "150px !important",
    border: '1px solid rgba(0, 0, 0, 0.2)',
})

const AnalizeSelectField = styled(Select)({
    height: "36px",
    borderRadius: "8px",
    padding: "8px !important;",
    "& *": {
        background: "#fff !important;",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "19.2px",
        color: '#34373A',
        paddingRight: '0px !important'
    },
    '&:focus': {
        borderColor: '#cccccc',
    },
})

const OverallEmissionContainer = styled("div")({
    padding: '26px 0 0 44px',
    '@media(max-width: 425px)': {
        padding: '26px 0 0 10px'
    }
});

const DashboardContainer = styled("div")({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px 10px 44px',
    marginTop: '20px',
    '@media(max-width: 425px)': {
        padding: '20px 20px 10px 10px'
    }
});

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#FFF',
        border: 'none',
        boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.11)",
        maxWidth: "200px",
        display: "inline-flex",
        height: "71px",
        padding: "7px 10px 7px 16px",
        justifyContent: "flex-end",
        alignItems: "center",
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "160%",
    },
    arrow: {
        color: '#FFF',
    },
}))(Tooltip);
// Customizable Area End
import DashboardController, { configJSON, Props } from "./DashboardController";
export default class Overview extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        // Customizable Area Start
        this.isLogin()
        this.getemmission()
        this.getorgreport()
        this.getdscreport()
        this.getenvreport()
        this.getOverallData()
        // Customizable Area End
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const LineOptions = {
            chart: {
                type: 'area',
                stacked: true,
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                curve: 'smooth'
            },
            colors: ['#43D270'],
            toolbar: {
                show: false,
            },
            xaxis: {
                type: 'month',
                axisTicks: {
                    show: false,
                },
                categories: this.state.xGraphData.emmision,
                labels: {
                    style: webStyle.labelText,
                },
            },
            grid: {
                borderColor: '#F5F5F5',
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.4,
                    opacityTo: 0.3,
                },
            },
            yaxis: {
                labels: {
                    style: webStyle.labelText,
                },
            },

            lineWidth: 1
        }

        const ActivityOptions = {
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                enabled: true,
                shared: false,
                custom: this.customTooltopBar
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '26.273px',
                    borderRadiusApplication: 'end',
                    borderRadius: 8,
                    distributed: true
                },
            },
            colors: ['#C5F1D3', '#E5E1FF', '#C5F1D3', '#AB9EFF', '#43D270'],
            xaxis: {
                type: 'days',
                axisTicks: {
                    show: false,
                },
                categories: this.state.xGraphData.descreport,
                labels: {
                    style: {
                        colors: '#34373A',
                        fontSize: '12px',
                        fontFamily: 'Lato',
                        fontWeight: 400,
                        lineHeight: "19.2px"
                    },
                },
            },
            states: {
                hover: {
                    filter: 'brightness(90%)',
                },
            },
            grid: {
                borderColor: '#F1F5F9',
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            legend: {
                show: false,
            },
        }

        const PieOptions = {
            colors: this.state.piechartColors,
            dataLabels: {
                enabled: false
            },
            labels: this.state.xGraphData.orgreport,
            plotOptions: {
                pie: {
                    donut: {
                        width: 5,
                    },
                },
            },
            tooltip: {
                enabled: true,
                shared: false,
                custom: this.getCustomTooltipdonut
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        offsetX: 10,
                        width: 200
                    },
                }
            }],
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left',
                markers: {
                    width: 10,
                    height: 10,
                },
                formatter: this.customFormatter,
            },
            states: {
                hover: {
                    filter: {
                        type: "none"
                    }
                }
            }
        };

        const Baroptions = {
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    borderRadiusApplication: 'end',
                    borderRadius: 4
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 3,
                colors: ['transparent']
            },
            xaxis: {
                axisTicks: {
                    show: false,
                },
                categories: this.state.xGraphData.envreport,
                labels: {
                    style: {
                        colors: '#34373A',
                        fontSize: '12px',
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        lineHeight: "19.2px"
                    },
                },
            },
            fill: {
                opacity: 1
            },
            colors: ['#43D270', '#E5E1FF', '#FDD1BF'],
            grid: {
                borderColor: '#F1F5F9',
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left',
                height: 50,
                fontWeight: 600,
                fontSize: '12px',
                labels: {
                    colors: ["#34373A", "#34373A", "#34373A"],
                    style: {
                        colors: '#34373A',
                        fontSize: '12px',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        lineHeight: "19.2px"
                    },
                  },            
                offsetX: 50,
                offsetY: 25,
                markers: {
                    width: 10,
                    height: 10,
                    radius: 3,
                },
                itemMargin: {
                    horizontal: 20,
                },
            },
            responsive: [
                {
                    breakpoint: 1024,
                    options: {
                        chart: {
                            height: 500
                        },
                        legend: {
                            height: 180
                        }
                    }
                }
            ]
        };

        const renderIcon = (open:boolean,handleOpen:()=> void) => (
            <>
            {open ?
               <ExpandLessIcon onClick={handleOpen}  style={webStyle.arrowUpIcon}/>
                   :
               <ExpandMoreIcon onClick={handleOpen}  style={webStyle.arrowUpIcon}/>
            }
            </>
        )

        const emptyData = (width: string, img: string,errorMessage: string) => (
            <div style={{ ...webStyle.nodata, width, position: 'relative' }}>
                <img src={img} alt="Background" style={webStyle.imageStyle} />
                    <div style={{ ...webStyle.subemptyItem, zIndex: 1 }}>
                    <Typography style={webStyle.subEmptyDesc}>{errorMessage}</Typography>
                    </div>
            </div>
        )

        const years = Array.from({ length: 5 }, (_, index) => 2023 + index);
        return (
            //Merge Engine DefaultContainer
          <>
            <ThemeProvider theme={theme} >
                <Layout>
                    <div style={webStyle.root as CSSProperties}>
                        <DashboardContainer>
                            <Typography style={webStyle.title}>Dashboard</Typography>
                            <Typography style={{ ...webStyle.subtitle, maxWidth: '882px' }}>
                                Discover your sustainability impact with an interactive dashboard that highlights key metrics on Emission health, 
                                ESG performance, and more.
                            </Typography>
                        </DashboardContainer>
                        <Divider />
                        {
                            !this.state.isShowAllGraphs
                                ?
                                <div style={webStyle.overviewbg}>
                                    <div style={webStyle.insideModel} className="insideModel">
                                        <Typography style={webStyle.modelTitle}>Log your emissions to track them on your dashboard</Typography>
                                        <Typography style={webStyle.modelDesc}>
                                            Data for climate insights comes from your log entries. It seems like your organization
                                            didn't log enough data for us to showcase any insights. Please enter log data in order to leverage CarbonZeroed full potential.
                                        </Typography>
                                        <Link 
                                            data-test-id="log-your-first-em-btn"
                                            onClick={() => setStorageData("activeSidebarLink", "/measure/log-your-esg-factors/all")} 
                                            to="/measure/log-your-esg-factors/all" 
                                            style={webStyle.logbutton as CSSProperties}
                                        >
                                            + Log Your First Emission
                                        </Link>
                                    </div>
                                </div> :
                                <OverallEmissionContainer>
                                    <Typography style={{ ...webStyle.title, marginBottom: '3px' }}>Emissions Overview</Typography>
                                    <SubItems style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginBottom: '34px' }} className="yearSelectBox">
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Typography style={webStyle.LineValue}>{this.state.averageEmission} Kg CO2e</Typography>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                            <AnalizeDropdown>
                                                <AnalizeSelectField
                                                    displayEmpty
                                                    data-test-id="emmissionfilter"
                                                    IconComponent={ () =>renderIcon(this.state.openOverAllEmission,this.handleToggle)}
                                                    open={this.state.openOverAllEmission}
                                                    onClose={() => this.handleCloseIcon()}
                                                    onOpen={() => this.handleOpenIcon()}
                                                    onScroll={this.handleCloseIcon}
                                                    onWheel={this.handleCloseIcon}
                                                    value={this.state.emmisionyear}
                                                    onChange={this.handleChangeEmmission}
                                                    disableUnderline
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                                width: "60px",
                                                                maxWidth: "100%",
                                                            },
                                                        },
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 395,
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 400,
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                >
                                                    {
                                                        years.map((year) => (
                                                            <MenuItem style={webStyle.menuItems} value={year}>{year}</MenuItem>
                                                        ))
                                                    }

                                                </AnalizeSelectField>
                                            </AnalizeDropdown>
                                            <IconButton style={{ visibility: "hidden" }} className="selectButton"><img src={arrows} alt="arrow" /></IconButton>
                                        </div>
                                    </SubItems>
                                    {
                                        this.state.isShowGraphs.emmision ?
                                            <VisualAnalytics
                                                navigation={this.props.navigation}
                                                id={"Overview"}
                                                chartType={"area"}
                                                height={300}
                                                data={[{
                                                    name: 'Kg CO2e',
                                                    data: this.state.allgraphData.emmision,
                                                    stroke: '#43D270',
                                                    fill: {
                                                        colors: ['#43D270', 'transparent']
                                                    },
                                                }]}
                                                option={LineOptions}
                                                width="80%"
                                            />
                                            : emptyData("937px", emmision, this.state.errorGraphMessage)
                                    }
                                    <Typography style={{ ...webStyle.title, marginTop: '80px', marginBottom: '54px' }}>Overall Emissions by Category</Typography>
                                    <TaskContainer style={{ display: 'flex', width: '80%', justifyContent: 'space-between', marginBottom: '57px' }}>
                                        <div>
                                            <SubItems style={{ display: 'flex', marginBottom: '22px', justifyContent: "space-between", alignItems: 'center', gap: '20px' }}>
                                                <Typography style={{
                                                    ...webStyle.title, marginBottom: '0 !important', fontSize: '18px',
                                                    display: 'flex', alignItems: 'center', gap: '4px'
                                                }}>
                                                    ESG Performance
                                                    <CustomTooltip
                                                      arrow
                                                      placement="bottom-start"
                                                      title='Average emissions activity as per the data provided under Core ESG Metrics'
                                                    >
                                                        <img src={info} alt="info"/>
                                                    </CustomTooltip>
                                                </Typography>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <YearDropdown>
                                                        <AnalizeSelectField
                                                            displayEmpty
                                                            data-test-id="orgreportfilter"
                                                            disableUnderline
                                                            IconComponent={() => renderIcon(this.state.openOrganization,this.handleToggleOrganization)}
                                                            open={this.state.openOrganization}
                                                            onClose={() => this.handleCloseOrganizationIcon()}
                                                            onOpen={() => this.handleOpenOrganizationIcon()}
                                                            onScroll={this.handleCloseOrganizationIcon}
                                                            onWheel={this.handleCloseOrganizationIcon}
                                                            value={this.state.orgreportmonth}
                                                            onChange={this.handleChangeorgreport}
                                                            MenuProps={{
                                                                getContentAnchorEl: null,
                                                                anchorOrigin: {
                                                                    vertical: 'bottom',
                                                                    horizontal: 395,
                                                                },
                                                                transformOrigin: {
                                                                    vertical: 'top',
                                                                    horizontal: 400,
                                                                },
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 200,
                                                                        width: "60px",
                                                                        maxWidth: "100%",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {years.map((year) => (
                                                                <MenuItem
                                                                    data-test-id="org-report-menu-item"
                                                                    style={webStyle.menuItemsTwo} 
                                                                    value={year}
                                                                    key={year}
                                                                >
                                                                    {year}
                                                                </MenuItem>
                                                            ))}
                                                        </AnalizeSelectField>
                                                    </YearDropdown>
                                                </div>
                                            </SubItems>
                                            {
                                                this.state.isShowGraphs.orgreport ?
                                                    <>
                                                        <PieChart>
                                                            <div style={{ position: 'relative' }} className="pieChart">
                                                                <VisualAnalytics
                                                                    navigation={this.props.navigation}
                                                                    id={"Overview"}
                                                                    chartType={"donut"}
                                                                    height="300px"
                                                                    width="380px"
                                                                    data={this.state.allgraphData.orgreport} option={PieOptions} />
                                                                <div style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }} className="coreStyle">
                                                                    <Typography style={webStyle.coreText}>CORE</Typography>
                                                                    <img src={measure} alt="Center Image" style={{ width: '18px', height: '18px', marginLeft: '10px' }} />
                                                                </div>
                                                            </div>
                                                        </PieChart>
                                                        <Typography style={{ ...webStyle.subtitle, maxWidth: "401px" }}>
                                                            Notes: The graph presents the average emission activity as per the ESG logs and scores
                                                            captured from the measure logs. Various categories represent the ESG activities carried
                                                            out under environment, social and governance respectively.
                                                        </Typography>
                                                    </>
                                                    :
                                                    emptyData("428px", org_report,configJSON.noDataFound)
                                            }
                                        </div>
                                        <div>
                                            <SubItems style={{ display: 'flex', marginBottom: '22px', justifyContent: "space-between", alignItems: 'center', gap: '42px' }}>
                                                <div>
                                                    <Typography style={{
                                                        ...webStyle.title, marginBottom: '0 !important', fontSize: '18px',
                                                        display: 'flex', alignItems: 'center', gap: '4px'
                                                    }}>
                                                        Disclosure Report
                                                        <CustomTooltip
                                                          arrow
                                                          placement="bottom-start"
                                                          title='Disclosure data provided against Social and Governance Core ESG Metrics'
                                                        >
                                                            <img src={info} alt="info"/>
                                                        </CustomTooltip>
                                                    </Typography>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <YearDropdown>
                                                        <AnalizeSelectField
                                                            displayEmpty
                                                            data-test-id="descreportfilter"
                                                            IconComponent={() => renderIcon(this.state.opendescreport,this.handleToggledescreport)}
                                                            value={this.state.descreportmonth}
                                                            onChange={this.handleChangedescreport}
                                                            open={this.state.opendescreport}
                                                            onClose={() => this.handleClosedescreportIcon()}
                                                            onOpen={() => this.handleOpendescreportIcon()}
                                                            onScroll={this.handleClosedescreportIcon}
                                                            onWheel={this.handleClosedescreportIcon}
                                                            disableUnderline
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 200,
                                                                        width: "60px",
                                                                        maxWidth: " 100%",
                                                                    },
                                                                },
                                                                anchorOrigin: {
                                                                    vertical: 'bottom',
                                                                    horizontal: 395,
                                                                },
                                                                transformOrigin: {
                                                                    vertical: 'top',
                                                                    horizontal: 400,
                                                                },
                                                                getContentAnchorEl: null,
                                                            }}
                                                        >
                                                            {years.map((year) => (
                                                                <MenuItem 
                                                                    data-test-id="disclosure-report-menu-item"
                                                                    style={webStyle.menuItems} 
                                                                    value={year}
                                                                    key={year}
                                                                >
                                                                    {year}
                                                                </MenuItem>
                                                            ))}
                                                        </AnalizeSelectField>
                                                    </YearDropdown>
                                                </div>
                                            </SubItems>
                                            {
                                                this.state.isShowGraphs.descreport ?
                                                    <>
                                                        <VisualAnalytics
                                                            navigation={this.props.navigation}
                                                            id={"Overview"}
                                                            chartType={"bar"}
                                                            width="100%"
                                                            height={"250px"}
                                                            data={[
                                                                {
                                                                    name: 'Kg CO2e',
                                                                    data: this.state.allgraphData.descreport
                                                                },
                                                            ]} option={ActivityOptions} />
                                                        <Typography 
                                                            style={{ 
                                                                ...webStyle.subtitle, 
                                                                maxWidth: "401px", 
                                                                fontStyle: "italic",
                                                                marginBottom: 20,
                                                            }}>
                                                            Legend: CT - Certifications; CO - Carbon Offsets; RW - Renewables; EB - Ethical Behaviour; AS - Assurance
                                                        </Typography>
                                                        <Typography style={{ ...webStyle.subtitle, maxWidth: "401px" }}>
                                                            Notes: The graph presents the average emission activity as per the ESG logs and scares
                                                            captured from the measure logs. Various categories represent the ESG activities carried
                                                            out under environment, social and governance respectively.
                                                        </Typography>
                                                    </>
                                                    :
                                                    emptyData("401px", dsc_report,configJSON.noDataFound)
                                            }
                                        </div>
                                    </TaskContainer>
                                    <Typography style={webStyle.title}>Total emissions captured</Typography>
                                    <Typography style={{ ...webStyle.subtitle, maxWidth: '882px', marginTop: '26px' }}>
                                        Get an outlook of your monthly emissions breakdown
                                    </Typography>
                                    <div>
                                        <SubItems style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginTop: '79px' }} className="yearSelectBox">
                                            <Typography style={{ ...webStyle.title, marginBottom: '0 !important', fontSize: '18px', display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                Total Environment Impact (Kg CO2e)
                                                <CustomTooltip
                                                  arrow
                                                  placement="bottom-start"
                                                  title='Emissions activity data provided against the key Environment Core ESG Metrics'
                                                >
                                                    <img src={info} alt="info"/>
                                                </CustomTooltip>
                                            </Typography>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                <EnviromentDropdown>
                                                    <AnalizeSelectField
                                                        displayEmpty
                                                        data-test-id="envfilter"
                                                        IconComponent={() => renderIcon(this.state.openEnvironment,this.handleToggleEnvironment)}
                                                        open={this.state.openEnvironment}
                                                        onOpen={this.handleOpenEnvIcon}
                                                        onClose={this.handleCloseEnvIcon}
                                                        onScroll={this.handleCloseEnvIcon}
                                                        onWheel={this.handleCloseEnvIcon}
                                                        value={this.state.envyear}
                                                        onChange={this.handleChangeenvreport}
                                                        disableUnderline
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200,
                                                                    width: "60px",
                                                                    maxWidth: "100%",
                                                                },
                                                            },
                                                            anchorOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 395,
                                                            },
                                                            transformOrigin: {
                                                                vertical: 'top',
                                                                horizontal: 400,
                                                            },
                                                            getContentAnchorEl: null,
                                                        }}
                                                    >
                                                        {
                                                            years.map((year) => (
                                                                <MenuItem style={webStyle.menuItems} value={year}>{year}</MenuItem>
                                                            ))
                                                        }
                                                    </AnalizeSelectField>
                                                </EnviromentDropdown>
                                            </div>
                                        </SubItems>
                                        {
                                            this.state.isShowGraphs.envreport ?
                                                <VisualAnalytics
                                                    navigation={this.props.navigation}
                                                    id={"Overview"}
                                                    chartType={"bar"}
                                                    width="80%"
                                                    height={300}
                                                    data={this.state.allgraphData.envreport} option={Baroptions} />
                                                :
                                                emptyData("937px", env_report, configJSON.environmentError)
                                        }
                                    </div>

                                </OverallEmissionContainer>
                        }
                     
                    </div>   
                      
                    <Typography style={{ ...webStyle.title, marginBottom: "16px", marginTop:'40px', marginLeft:'45px' }}>
                                Forecast Analysis
                            </Typography>
                            <ForeCastAnalysis navigation={this.props.navigation} id="ForecastAnalysis" />
                </Layout >
            </ThemeProvider >
           
                      
          </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    arrowIcon: {
       cursor:'pointer'
    },
    arrowUpIcon:{
        cursor:'pointer',
        fontSize:'20px',
        height:'20px',
        color:'#000'
    },
    root: {
        width: '100%',
        backgroundColor: '#FFF',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
    },
    title: {
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "125%",
        marginBottom: '23px',
    },
    subtitle: {
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "160%",
        letterSpacing: "160%",
        marginBottom: '33px',
    },
    LineValue: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "25.2px",
        letterSpacing: "0.2 px",
    },
    perEmtn: {
        color: "#34D399",
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "19.2px",
        marginLeft: '5px'
    },
    description: {
        color: "#34373A",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Lato",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: "0.085px",
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 20px 10px 44px',
        marginTop: '20px'
    },
    overviewbg: {
        backgroundImage: `url(${overviewbg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '642px',
    },
    modelTitle: {
        color: "#101010",
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "120%"
    },
    modelDesc: {
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "160%",
        marginTop: '12px'
    },
    insideModel: {
        marginTop: '93px', marginLeft: '44px',
        background: "#ECFBF1", maxWidth: '90%',
        borderRadius: "12px", padding: '27px',
    },
    logbutton: {
        display: "block",
        width: "187px",
        padding: "7px 0",
        borderRadius: "8px",
        background: "#43D270",
        color: "#34373A",
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineWeight: "160%",
        marginTop: '22px',
        textDecoration: 'none'
    },
    selects: {
        maxHeight: '36px',
        borderRadius: '8px',
        border: "1px solid #DFDFDF",
        color: "#34373A",
        fontFamily: "Inter !important",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19.2px",
        padding: '8px',
        marginRight: '10px',
        maxWidth: '92px',
        width: '100%'
    },
    menuItems: {
        maxWidth: '92px !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: "1px !important",
        fontSize: '10px'
    } as CSSProperties,
    menuItemsTwo:{
        fontSize: '10px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '92px !important',
        whiteSpace: 'nowrap',
        padding: "1px !important",
    } as CSSProperties,
    labelText: {
        color: "#34373A",
        textAlign: "right",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: "160%",
    } as CSSProperties,
    coreText: {
        color: "#34373A",
        textAlign: "center",
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: "22.4px",
        letterSpacing: "0.2px"
    } as CSSProperties,
    subemptyItem: {
        borderRadius: "4px",
        padding: "24px",
        border: '1px solid rgb(211 203 203)',
        backgroundColor: '#ECFBF1',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "70%"
    } as CSSProperties,
    subEmptytitle: {
        color: "var(--Neutral-100, #101010)",
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: "24px",
    } as CSSProperties,
    subEmptyDesc: {
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "15px",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: "24px",
    } as CSSProperties,
    nodata: {
        backgroundPosition: 'center',
        height: "300px",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    imageStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        WebkitFilter: 'blur(4px)',
        filter: 'blur(4px)',
    } as CSSProperties
};
// Customizable Area End
