import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

interface ReportType {
  "id": number;
  "account_id": number;
  "report_name": string;
  "report_year": string;
  "report_status": string;
  "report_generated_at": string;
  "attachment_url": string;
  "created_at": string;
  "updated_at": string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  open: boolean;
  reportList: ReportType[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Multiplelandingpage2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  account_id: string;
  company_id: string;
  reportsListApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open: false,
      reportList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
    this.account_id = userDetails.meta.id;
    this.company_id = orgDetails.id;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (requestCallId === this.reportsListApiCallId) {
        if (!responseJson.error && responseJson?.length > 0) {
          this.setState({ reportList: responseJson.filter((item:ReportType) => item.report_status === 'completed') });
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleShowDetails = () => {
    this.setState({
      open: true,
    })
  }

  navigateToMaterialCards = () => {
    // Doing this to highlight the Core Metrics menu on the sidebar
    setStorageData("activeSidebarLink", "/measure/log-your-esg-factors/all");
    this.props.navigation.navigate("LogEsgFactors", {
      category: "all"
    });
  }

  async componentDidMount() {
    await this.fetchReportsList();
  }

  fetchReportsList = async () => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": await getStorageData("authToken"),
    };
    
    const body = { 
      account_id: this.account_id,
      company_id: this.company_id,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
    this.reportsListApiCallId = apiRequest.messageId;

    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.reportsListEndPoint);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    runEngine.sendMessage(apiRequest.id, apiRequest);
}
  // Customizable Area End
}
