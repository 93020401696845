import React from "react";

import {
  // Customizable Area Start
    Typography,
    Divider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import Layout from "../../../dashboard/src/Layout.web";
import { Actionplans } from "../assets";
import { Link } from 'react-router-dom';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import Multiplelandingpage2Controller, {
  Props,
  configJSON,
} from "../Multiplelandingpage2Controller";

export default class ActionPlan extends Multiplelandingpage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout>
      <Typography style={webStyle.title}>Action Plan</Typography>
      <Typography style={webStyle.description}>Copy that talks about what time means to the users and some guidance or
          instructions or what to make from this and how to proceed forward. This  talks about what time means to the users and some
          guidance or instructions or what to make from this and how to proceed forward.</Typography>
      <Divider style={{color: '#EBEBEB'}} />
      <img src={Actionplans} style={webStyle.image}/>
      <Divider style={{color: '#EBEBEB'}} />
      <Link to="/get-started" style={webStyle.button}>Go to Task List (15)</Link>
  </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  title: {
      color: "#34373A",
      fontFamily: "Lato",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "125%",
      margin: '32px 0 23px 44px'
  },
  description: {
      color: "#7D7D7F",
      fontFamily: "Lato",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "160%",
      paddingLeft: '44px',
      maxWidth: '882px',
      marginBottom: '33px'
  },
  button: {
      minWidth: '195px',
      maxWidth: '195px',
      minHeight: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: "#34373A",
      fontFamily: "Inter",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "160%",
      marginLeft: '34px',
      borderRadius: "8px",
      background: "#43D270",
      textDecoration: "none",
      marginTop: '24px'
  },
  image: {
    width: '100%',
    height: '100%'
  }
};
// Customizable Area End
