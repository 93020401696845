import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Paper,
  TextField,
  Radio,
  Chip,
  Snackbar,
  Avatar,
  FormControl,
  Select,
  MenuItem,
  Grid,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
import Layout from "../../dashboard/src/Layout.web";
import CustomizedSteppers from "../../../components/src/CustomizedSteppers";
import CloseIcon from '@material-ui/icons/Close';
import {checkbox, checked} from "../../customform/src/assets"
import Autocomplete from "@material-ui/lab/Autocomplete";
import {closeChip, arrowDown, editIcon, trashIcon, successCheck} from "./assets"
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Pagination from "@material-ui/lab/Pagination";

const theme = createTheme({
  typography: {
    fontFamily:'Lato'
  },
});

const CreateButton = styled(Button)({
  color:'#43D270',
  fontSize:'12px',
  fontWeight:700,
  textTransform:'capitalize' as 'capitalize',
  fontFamily:'Lato',
});

const CreateTaskButton = styled(Button)({
  background:'#43D270',
  borderRadius:'8px',
  color:'#34373A',
  fontSize:'12px',
  fontWeight:700,
  textTransform:'capitalize' as 'capitalize',
  boxShadow:'none',
  minWidth:'120px',
  height:'40px',
  fontFamily:'Lato',
  '&:hover':{
    background:'#43D270',
    color:'#34373A',
    boxShadow:'none'
  }
})

const TaskNameField = styled(TextField)({
  width:'100%',
    fontSize:'14px',
    color:'#34373A',
    fontFamily:'Lato',
  '& div':{
    borderRadius:'8px',
    '& :focus':{
      outline:'1px solid #D9D9D9',
      borderRadius:'8px',
    zIndex: 1,
  },
  },
});

const TaskAreaField = styled(TextField)({
  width:'100%',
    fontSize:'14px',
    fontFamily:'Lato',
    color:'#34373A',
    '& .MuiOutlinedInput-input-380' : {
      padding: '12px',
    },
    '& .MuiOutlinedInput-multiline-378' : {
      padding: 0,
    },
    border:'1px solid #D9D9D9',
      borderRadius:'8px',
  '& div':{
    borderRadius:'8px',
    fontFamily:'Lato',
    '& :focus':{
      outline:0,
      borderRadius:'8px',
      zIndex: 1,
    },
  },
});

const LogsContainer = styled("div")({
	width: "100%",
	minHeight: "calc(100vh - 185px)",
	padding: "32px 32px 21px 32px"
});

const TabWithSelect = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	'@media(max-width: 768px)': {
		flexDirection: "column",
		alignItems: "flex-start",
	},
	'@media(max-width: 700px)': {
		flexWrap: "wrap",
	},
})

const Tabs = styled("div")({
	display: "flex",
	flexWrap: "wrap",
	width: "100%",
	gap: "20px",
	justifyContent: "flex-start",
	alignItems: "center",
	'@media(max-width: 700px)': {
		gap: "4px",
	},
});

const CustomCard = styled(Box)(({ theme }) => ({
  width: '359px',
  [theme.breakpoints.down('md')]: {
    width: '300px',
  },
  '@media(max-width: 1357px)':{
    width : '300px'
  }
}));

const TabContent = styled(Box)({
	display:'flex',
  width:'100%',
  gap:'12px',
  flexWrap:'wrap'
});

const LogCard = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "18px 16px",
  flexShrink: 0,
  borderRadius: "12px",
  width: "100%",
  minHeight: "199px",
  marginBottom: "0",
  backgroundColor: "#ffffff",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#ECFBF1"
  }
});

const CardBody = styled("div")({
  minHeight: "131px",
  marginBottom: "0",
  position: "relative",
  width: "100%"
});

const CategoryText = styled(Typography)({
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 700,
  fontStyle: "normal",
  marginBottom: "16px",
  lineHeight: "160%"
});

const HeadingText = styled(Typography)({
  marginBottom: "8px",
  fontFamily: "Lato",
  fontStyle: "normal",
  color: "#34373A",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  cursor: "pointer",
});

const ShortDesc = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Lato",
  marginBottom: "24px",
  color: "#7D7D7F",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "160%",
  overflow: "hidden",
  display: '-webkit-box',
  "-webkit-line-clamp": 2,
  "-webkit-box-orient": "vertical",
});

const CustomChip = styled(Chip)({
  fontSize: "12px",
  color: '#7D7D7F',
  height: 32,
  display: 'inline-flex',
  padding: '8px 15px,',
  alignItems: 'center',
  fontFamily: 'Lato',
  fontWeight: 500,
  lineHeight: '160%',
  borderRadius: 8,
  justifyContent: 'center',
  textDecoration: 'none',
  backgroundColor: '#F9F9F9',
  "&:hover": {
    backgroundColor: "#FFFFFF"
  }
});

const ButtonBox = styled("div")({
  display: "flex",
  gap: "5px"
});

const TagPill = styled(Box)({
  fontSize: "12px",
  padding: "8px 15px",
  color: "#7D7D7F",
  fontStyle: "normal",
  fontFamily: "Lato",
  fontWeight: 500,
  lineHeight: "160%",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#F9F9F9",
  borderRadius: "8px",
  textDecoration: "none"
});

const MoreIcon = styled(Box)({
  position: "absolute",
  top: "8px",
  cursor: "pointer",
  right: "4px",
  zIndex: 1
});

const AssignTo = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Lato",
  color: "#7D7D7F",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "160%",
  marginRight: "3px"
});

const Assigned = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Lato",
  color: "#34373A",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "160%"
});

const Profile = styled(Avatar)({
  height: "32px",
  width: "32px",
  marginRight: "6px"
});

const FieldGroupStyled = styled(Box)({
  cursor: "pointer"
});

const DropdownFormControl = styled(FormControl)({
});

const SelectDropdownStyled = styled(Select)({
  height: "40px",
  borderRadius: 8,
  border: "1px solid #DFDFDF",
  fontSize: 12,
  fontFamily: 'Lato',
  color: '#34373A',
  fontWeight: 600,
  minWidth: 120,
  padding: 12,
  display: 'flex',
  alignItems: 'center',
  '&:before': {
    display: 'none'
  },
  '&:after': {
    display: 'none'
  },
  '& div:focus': {
    background: 'transparent'
  }
});

const MenuItemStyled = styled(MenuItem)({
  whiteSpace: 'nowrap',
  maxWidth: "100%",
  fontSize: 14,
  fontFamily: 'Lato',
  color: '#34373A',
  padding: "8px 10px",
  minHeight: '32px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: '#ECFBF1',
    color: '#43D270',
  },
  '&.Mui-selected': {
    backgroundColor: '#ECFBF1',
    color: '#43D270',
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#ECFBF1',
    color: '#43D270',
  },
  '& span': {
    backgroundColor: 'transparent',
  },
});

const SimplePagination = styled(Pagination)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 10,
  '& li:first-child': {
    marginRight: 20,
  },
  '& li:last-child': {
    marginLeft: 20,
  },
  '& button': {
    height: 36,
    width: 36,
    borderColor: '#DFDFDF',
    borderRadius: 8,
    color: "#7D7D7F",
    fontSize: 12,
    fontFamily: 'Lato',
    background: 'transparent!important',
    '&.Mui-selected': {
      borderColor: '#329E54',
      color: '#329E54',
    },
  }
});

const NoButton = styled(Button)({
  borderColor:'#D9D9D9',
  borderRadius:'8px',
  color:'#34373A',
  fontSize:'12px',
  fontWeight:700,
  height:'32px',
  boxShadow:'none',
  textTransform:'capitalize',
  fontFamily:'Lato',
  width:'97px',
  '&:hover':{
   borderColor:'#D9D9D9',
   color:'#34373A'
  }
 })
 
 const YesButton = styled(Button)({
  background:'#43D270',
  height:'32px',
  borderRadius:'8px',
  padding:'0px 20px',
  color:'#34373A',
  fontSize:'12px',
  fontWeight:700,
  boxShadow:'none',
  textTransform:'capitalize',
  fontFamily:'Lato',
  width:'97px',
  '&:hover':{
   background:'#43D270',
   color:'#34373A',
   boxShadow:'none'
  }
 })

const SaveProcedButton = styled(Button)({
  background: '#43D270',
  borderRadius: '8px',
  width: '184.5px',
  height: '40px',
  color: '#34373A',
  fontSize: '12px',
  fontWeight: 700,
  boxShadow: 'none',
  textTransform: 'capitalize',
  fontFamily:'Lato',
  '&:hover': {
    background: '#43D270',
    color: '#34373A',
    boxShadow: 'none'
  }
});

const BackButton = styled(Button)({
  borderRadius: '8px',
  borderColor: '#000',
  width: '184.5px',
  height: '40px',
  color: '#34373A',
  fontSize: '12px',
  fontWeight: 700,
  boxShadow: 'none',
  textTransform: 'capitalize',
  fontFamily:'Lato',
  '&:hover': {
    borderColor: '#000',
    color: '#34373A',
    boxShadow: 'none'
  }
});

const CustomTableContainer = styled(TableContainer)({
	maxHeight: "500px",
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column',
	padding: '12px 24px',
	paddingLeft: 0,
  overflowY:'scroll',
  scrollbarWidth:'none',
	'@media(max-width: 600px)': {
		padding: 0
	}
}); 

const CustomTable = styled(Table)({
	width: "100%",
	borderCollapse: "separate",
  marginBottom:'20px'
});
const CustomTableHead = styled(TableHead)({
	backgroundColor: "#F5F5F5",
	borderRadius: "12px",
});
const AutoBox = styled(Box)({
	'& div button+button': {
    marginRight: 6
  },
});
const CustomTableRowHead = styled(TableRow)({
	// borderRadius: "20px !important",
	overflow: "hidden",
	"& :first-child": {
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
	},
	"& :last-child": {
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
	},
});
const CustomTableCellHead = styled(TableCell)({
	minHeight: "48px",
	padding: "10px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});

const CustomTableCellHeadUsername = styled(TableCell)({
	minHeight: "48px",
	padding: "10px 18px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});
const CustomSpacing = styled("div")({
	height: 16,
});
const CustomTableBody = styled(TableBody)({
	color: "inherit",
});
const CustomTableRow = styled(TableRow)({});
const CustomTableCellUsername = styled(TableCell)({
	borderBottom: "1px solid #F1F5F9",
	padding: "8px 8px 8px 18px",
	color: "#34373A",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});
const CustomTableCell = styled(TableCell)({
	borderBottom: "1px solid #F1F5F9",
	padding: "8px",
	color: "#34373A",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});

const CardGrid = styled(Grid)({
  width: " calc(100% + 14px) !important",
  marginLeft: "-12px", // "-22px",
  paddingTop: "20px !important",
  paddingBottom: "0 !important",
  paddingLeft: '32px',
  minHeight: '131px',
  '@media (max-width:600px)':{
    paddingLeft: '0px !important',
    marginLeft: "9px !important",
    maxWidth:'95% !important'
  }
});

const criticalOptions = [
  {value:'critical',label:'Critical'},
  {value:'major',label:'Major'},
  {value:'minor',label:'Minor'},

]

const renderSubCategory = (option: any, { selected }: any) => {
  return (
    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
      <Radio
        icon={<img src={checkbox} />}
        checkedIcon={<img src={checked} />}
        style={{ marginLeft: -16 }}
        checked={selected}
      />
      <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.sub_category_name}</Typography>
    </li>
  );
}
const renderAssignee = (option: any, { selected }: any) => {
  return (
    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
      <Radio
        icon={<img src={checkbox} />}
        checkedIcon={<img src={checked} />}
        style={{ marginLeft: -16 }}
        checked={selected}
      />
      <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.first_name} {option.last_name}</Typography>
    </li>
  );
}
const renderCritical = (option: any, { selected }: any) => {
  return (
    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
      <Radio
        icon={<img src={checkbox} />}
        checkedIcon={<img src={checked} />}
        style={{ marginLeft: -16 }}
        checked={selected}
      />
      <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.label}</Typography>
    </li>
  );
}


const DropdownIcon = (props: any) => {
  return <img {...props} alt="" style={{marginTop:"-1px",width:"18px",height:"18px", top: "auto", right: "6px"}} src={chevron_down} />
};

// Customizable Area End

import GoalManagementController, {
  Props,
  configJSON,
} from "./GoalManagementController";
import { chevron_down, verticalDots } from "../../dashboard/src/assets";
import KebabOptions from "../../dashboard/src/common/KebabOptions.web";
import zIndex from "@material-ui/core/styles/zIndex";

export default class GoalManagement extends GoalManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFormView() {
    return (
      <Box>
        <Box>
          <Typography style={webStyle.actionText}>{configJSON.actionPlan}</Typography>
          <Typography style={webStyle.actionDescription}>{configJSON.actionDescription}</Typography>
        </Box>
        <CustomizedSteppers
          data-testid="customized-stepper"
          getSteps={this.state.categories}
          getStepContent={this.getStepContent}
          activeStep={this.state.activeStep}
        />

        <div style={{height:'500px'}}>
          <CustomTableContainer>
            <CustomTable aria-label="task table">
              <CustomTableHead>
                <CustomTableRowHead>
                  <CustomTableCellHeadUsername>{configJSON.taskNameHeader}</CustomTableCellHeadUsername>
                  <CustomTableCellHead>{configJSON.subCategoryHeader}</CustomTableCellHead>
                  <CustomTableCellHead>{configJSON.assigneeHeader}</CustomTableCellHead>
                  <CustomTableCellHead>{configJSON.criticalityHeader}</CustomTableCellHead>
                  <CustomTableCellHead>{configJSON.startDate}</CustomTableCellHead>
                  <CustomTableCellHead>{configJSON.EndDate}</CustomTableCellHead>
                  <CustomTableCellHead></CustomTableCellHead>
                  <CustomTableCellHead></CustomTableCellHead>
                </CustomTableRowHead>
              </CustomTableHead>
              {this.state.allTasks &&
                this.state.allTasks.filter(data => data.category_id === this.state.activeStep + 1).map(item =>
                  <>
                    {item.tasks.map(task =>
                      <>
                        <CustomSpacing />
                        <CustomTableBody>
                          <CustomTableRow>
                            <CustomTableCellUsername>{task.task_name}</CustomTableCellUsername>
                            <CustomTableCell>{task.task_sub_category_name}</CustomTableCell>
                            <CustomTableCell>
                              <AvatarGroup spacing="small">
                                {task.assignees.map(data =>
                                  <Avatar src={data?.image} style={{ width: '38px', height: '36px', border: '3px solid #fff' }} />
                                )}
                              </AvatarGroup>
                            </CustomTableCell>
                            {task.criticality === "minor" &&
                              <CustomTableCell>
                                <div style={{ ...webStyle.criticalTable, color: '#ecb922' }}>
                                  <FiberManualRecordIcon style={{ fontSize: '12px', fontFamily:'Lato', }} />
                                  Minor
                                </div>
                              </CustomTableCell>
                            }
                            {task.criticality === "major" &&
                              <CustomTableCell>
                                <div style={{ ...webStyle.criticalTable, color: '#F96C30' }}>
                                  <FiberManualRecordIcon style={{ fontSize: '12px', fontFamily:'Lato', }} />
                                  Medium
                                </div>
                              </CustomTableCell>
                            }
                            {task.criticality === "critical" &&
                              <CustomTableCell>
                                <div style={{ ...webStyle.criticalTable, color: '#7166F1' }}>
                                  <FiberManualRecordIcon style={{ fontSize: '12px',fontFamily:'Lato', }} />
                                  High
                                </div>
                              </CustomTableCell>
                            }
                            <CustomTableCell>{task.start_date}</CustomTableCell>
                            <CustomTableCell>{task.end_date}</CustomTableCell>
                            <CustomTableCell data-testid={"edit-" + task.id} onClick={() => this.handleEdit(task.id)} style={{ cursor: 'pointer' }}>{<img src={editIcon} />}</CustomTableCell>
                            <CustomTableCell data-testid={"delete-" + task.id} onClick={() => this.handleOpenDeleteModal(task.id)} style={{ cursor: 'pointer' }}>{<img src={trashIcon} />}</CustomTableCell>
                          </CustomTableRow>
                        </CustomTableBody>
                      </>
                    )}
                  </>
                )
              }
            </CustomTable>
          </CustomTableContainer>

          {this.state.allTasks.filter(data => data.category_id === this.state.activeStep + 1).map(item => {
            return (
              <>
                {item.tasks.length > 0 ? (
                  <div>
                    <CreateButton
                      onClick={this.handleCreateTaskForm}
                      data-testid="create-button-one"
                    >
                      {configJSON.addMoreButton}
                    </CreateButton>

                  </div>
                ) : (
                  <div style={webStyle.creatButtonDiv}>
                    <Typography style={webStyle.noTaskText}>{configJSON.noTask}</Typography>
                    <CreateButton
                      onClick={this.handleCreateTaskForm}
                      data-testid="create-button"
                    >
                      {configJSON.createButton}
                    </CreateButton>
                  </div>
                )}
              </>
            )
          })}
        </div>
        <Box style={webStyle.endButtonBox}>
          <Box style={{ display: 'flex', gap: '20px' }}>
            {this.state.activeStep > 0 && (
              <BackButton
                data-testid="back-button"
                variant="outlined"
                onClick={this.handleBackStepper}
              >
                {configJSON.back}
              </BackButton>
            )}
            {this.state.allTasks.filter(data => data.category_id === this.state.activeStep + 1).map(item => {
              return (
                <>
                  {item.tasks.length > 0 &&
                    <>
                      {this.state.categories.length === this.state.activeStep + 1 ? (
                        <SaveProcedButton
                          data-testid="complete-button"
                          variant="contained"
                          onClick={this.handleComplete}
                        >
                          {configJSON.complete}
                        </SaveProcedButton>
                      ) : (
                        <SaveProcedButton
                          data-testid="save-proceed"
                          variant="contained"
                          onClick={() => this.handleProceed(item.category_id)}
                        >
                          {configJSON.saveProceed}
                        </SaveProcedButton>
                      )}
                    </>
                  }
                </>
              )
            })}

          </Box>
        </Box>
      </Box>
    )
  }

  renderGridView() {
    return (
      <CardGrid spacing={0} container>
        {
          this.state.filteredTasks.slice(this.state.indexOfFirstTask, this.state.indexOfLastTask).map((task: any, index: number) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <div style={{ position: 'relative', width: '100%', }}>
                {this.state.userType === "portfolio_manager" ?
                  <div className="menuBox" style={webStyle.menuContainer as React.CSSProperties}>
                    <IconButton data-test-id={`openmenu_${index}`} onClick={() => this.handleOpenMenu(index)}>
                      <MoreVertIcon />
                    </IconButton>
                    <div style={{ ...webStyle.popup, display: this.isShowPopup(index) } as React.CSSProperties}>
                      <div style={{ ...webStyle.mainMenuItem } as React.CSSProperties}>
                        <div style={webStyle.itemContainer as React.CSSProperties}>
                          <div
                            style={{ ...webStyle.items }}>
                            <div style={webStyle.menuText as React.CSSProperties} data-test-id={"edit-" + index} onClick={() => this.handleEditTask(task?.id, task?.task_name)}>Edit</div>
                          </div>
                          <div style={webStyle.items}>
                            <div style={webStyle.menuText as React.CSSProperties} data-test-id={"delete-" + index} onClick={() => this.handleOpenDeleteModal(task?.id)} >Delete</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ""
                }
                <LogCard data-test-id={"tasklog-" + index} onClick={() => this.handlelogDetails(task?.id, task?.task_name)}>
                  <CardBody>
                    <Box display="flex" width="100%">
                      {task?.categoryName === "Decarbonization Strategy" && (
                        <CategoryText style={{ color: '#43D270' }}>
                          {task?.categoryName}
                        </CategoryText>
                      )}
                      {task?.categoryName === "Digital Levers" && (
                        <CategoryText style={{ color: '#7166F1' }}>
                          {task?.categoryName}
                        </CategoryText>
                      )}
                      {task?.categoryName === "Climate Risk Management" && (
                        <CategoryText style={{ color: '#F96C30' }}>
                          {task?.categoryName}
                        </CategoryText>
                      )}
                    </Box>
                    <Box display="flex" alignItems="center" gridGap={10}>
                      <HeadingText>
                        {task?.task_name}
                      </HeadingText>
                    </Box>
                    <ShortDesc>{task?.task_description}</ShortDesc>
                    <CustomChip
                      data-test-id="framework-chip"
                      size="medium"
                      label={task?.task_sub_category_name}
                    />
                  </CardBody>
                </LogCard>
              </div>
            </Grid>
          ))
        }
      </CardGrid>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Layout>
          <LogsContainer>
            {this.state.showGridView ? (
              <div style={webStyle.topBar as React.CSSProperties}>
              <TabWithSelect>
                <Tabs>
                {["All", ...this.state.categories].map((tab,index) => (
                  <div
                    key={tab}
                    data-test-id={`tab_${index}`}
                    style={{...webStyle.taskTab, ...this.state.taskType === tab ? webStyle.activeTab: ''}}
                    onClick={() => this.handleTabClick(tab)}
                  >
                    {tab}
                  </div>
                ))}
                </Tabs>
              </TabWithSelect>
              <div style={{display: "inline-flex", flexWrap: 'wrap', gap: 12}}>
                {this.state.userType === "portfolio_manager" && this.state.taskType !== "All" ?
                  <CreateTaskButton
                  onClick={this.handleCreateTaskForm}
                  data-test-id="create-button"
                  >Add Task</CreateTaskButton>
                  :""
                }
               <FieldGroupStyled>
                    <DropdownFormControl>
                      <SelectDropdownStyled
                      open={this.state.open.statusfilter}
                      onOpen={this.handleOpen('statusfilter')}
                      onClose={this.handleClose('statusfilter')}
                      onChange={this.taskstatusChange}
                      onScroll={this.handleScroll}
                      displayEmpty
                      data-test-id="status-filter" 
                      value={this.state.taskStatus}
                      IconComponent={DropdownIcon}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            width: '150px',
                            borderRadius: 8,
                            border: "solid 1px #D9D9D9",
                            padding: "0px 6px",
                            boxShadow: 'none',
                            marginTop: '12px',
                            marginLeft: '12px',
                          },
                        },
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'bottom',
                        },
                        transformOrigin: {
                          horizontal: 'right',
                          vertical: 'top',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItemStyled value="all">Sort by</MenuItemStyled>
                      <MenuItemStyled value="yet_to_start">Yet to Start</MenuItemStyled>
                      <MenuItemStyled value="in_progress">In Progress</MenuItemStyled>
                      <MenuItemStyled value="completed">Completed</MenuItemStyled>
                      </SelectDropdownStyled>
                    </DropdownFormControl>
                </FieldGroupStyled>
                <FieldGroupStyled>
                    <DropdownFormControl>
                      <SelectDropdownStyled
                      open={this.state.open.monthfilter}
                      onOpen={this.handleOpen('monthfilter')}
                      onClose={this.handleClose('monthfilter')}
                      onChange={this.handleMonthChange}
                      onScroll={this.handleScroll}
                      displayEmpty
                      data-test-id="month-filter" 
                      value={this.state.selectedMonth}
                      IconComponent={DropdownIcon}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            width: '150px',
                            borderRadius: 8,
                            border: "solid 1px #D9D9D9",
                            padding: "0px 6px",
                            boxShadow: 'none',
                            marginTop: '12px',
                            marginLeft: '12px',
                          },
                        },
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'bottom',
                        },
                        transformOrigin: {
                          horizontal: 'right',
                          vertical: 'top',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {this.state.availableMonths.map((monthYear: any) => (
                        <MenuItemStyled key={monthYear} value={monthYear}>
                          {monthYear}
                        </MenuItemStyled>
                      ))}
                      </SelectDropdownStyled>
                    </DropdownFormControl>
                </FieldGroupStyled>
              </div>
            </div>
            ) : null}          
            <TabContent>
              {this.state.showGridView ? this.renderGridView() : this.renderFormView()}
            </TabContent>
            {this.state.showGridView && this.state.filteredTasks.length >= 9 ?
              <SimplePagination
                data-test-id="pagination"
                count={Math.ceil(this.state.filteredTasks.length / 9)}
                page={this.state.page}
                onChange={this.handleChangePage}
                variant="outlined"
                shape="rounded"
              />
            :""
            }
            <Modal 
                open={this.state.createTaskForm} 
                onClose={this.handleCloseCreateTaskForm} 
                style={webStyle.modalWrapper} 
                data-testid="task-modal"
                >
                <Paper style={webStyle.mdalPaper}>
                  <Box style={webStyle.createModalFirstDiv}>
                    <Typography style={webStyle.createTaskText}>{configJSON.CreateTask}</Typography>
                    <CloseIcon onClick={this.handleCloseCreateTaskForm}/>
                  </Box>
                  <Typography style={webStyle.createTaskDescr}>{configJSON.createTaskDescription}</Typography>
                  <Box style={webStyle.textFiledBox}>
                    <Box style={webStyle.fieldBox}>
                  <Typography style={webStyle.taskNameLabel}>{configJSON.taskName}</Typography>
                    <TaskNameField
                    placeholder={configJSON.taskNamePlaceholder}
                    variant="outlined"
                    onChange={this.handleChangeTaskName}
                    value={this.state.taskNameValue}
                    data-testid="task-name"
                    error={this.state.error && !this.state.taskNameValue}
                    inputProps={{
                      style:{
                        padding: '12px',
                        fontFamily: 'Lato'
                      }
                    }}
                    />
                    {(this.state.error && this.state.taskNameValue === "") &&
                      <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                      }
                    </Box>
                    <Box style={webStyle.fieldBox}>
                      <Typography style={webStyle.taskNameLabel}>{configJSON.selectSubCategory}</Typography>
                    <Autocomplete
                          id="subcategory"
                          options={this.state.subCategoryOption}
                          disableCloseOnSelect
                          value={this.state.subCategoryOption.find(option => option.id === this.state.subCategoryValue)}
                          data-testid="sub-category"
                          onChange={(event, value) => this.setState({subCategoryValue:value?.id})}
                          closeIcon={false}
                          popupIcon={<div style={{ margin: '5px 12px' }}>
                              <img src={arrowDown}/>
                          </div>}
                          renderInput={(params) =>
                            <TextField
                                {...params}
                                name="memberlist"
                                placeholder="Select one from the list"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: (this.state.error && this.state.subCategoryValue === 0) ? webStyle.autoErrorComplete:webStyle.autoComplete
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  style: {padding : 0, fontFamily: 'Lato'}
                                }}
                            />
                          }
                          renderOption={renderSubCategory}
                          getOptionLabel={(option: any) => option.sub_category_name}
                      />
                      {(this.state.error && this.state.subCategoryValue === 0) &&
                      <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                      }
                    </Box>
                  </Box>
                  <Box style={webStyle.textFiledBox}>
                  <Box style={webStyle.fieldBoxAssing}>
                  <Typography style={webStyle.taskNameLabel}>{configJSON.SelectAssignee}</Typography>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    data-testid="assignee-field"
                    value={this.state.assigneeValue}
                    options={this.state.assigneeOption}
                    disableCloseOnSelect
                    onChange={this.handleChangeAssignee}
                    closeIcon={false}
                    popupIcon={<div style={webStyle.arrowDiv}>
                              <img src={arrowDown}/>
                          </div>}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.slice(0,3).map((option, index) => (
                        <Chip
                          label={`${option.first_name} ${option.last_name}`}
                          {...getTagProps({ index })}
                          style={{
                            background:'#ECFBF1',
                            borderRadius:'8px',
                            color:'#329E54',
                            fontSize:'12px',
                            lineHeight: '16px',
                            fontWeight:500,
                            fontFamily:'Lato',
                            padding:'10px 3px',
                            cursor: 'pointer',
                          }}
                          deleteIcon={<img src={closeChip} style={{width:'14px'}}/>}
                        />
                      ))
                    }
                    getOptionLabel={(option) =>  `${option.first_name} ${option.last_name}`}
                    renderInput={(params:any) => 
                      <TextField
                          {...params}
                          name="memberlist"
                          placeholder={this.state.assigneeValue.length === 0 ?"Select one from the list":""}
                          InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              style: (this.state.error && this.state.assigneeValue.length === 0) ? webStyle.autoErrorComplete : {...webStyle.autoComplete,padding: '8px 12px', paddingTop: this.state.assigneeValue.length > 0 ? '3px':'8px'},
                              type: 'search'
                          }}
                          inputProps={{
                            ...params.inputProps,
                            style: {padding : 0, fontFamily: 'Lato'}
                          }}
                      />
                    }
                    renderOption={renderAssignee}
                  />
                  {(this.state.error && this.state.assigneeValue.length === 0) &&
                      <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                      }
                  </Box>
                  <Box style={webStyle.dateMainBox}>
                  <Box style={webStyle.fieldBox}>
                  <Typography style={webStyle.taskNameLabel}>{configJSON.StartDate}</Typography>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker 
                          data-testid="start-date"
                          placeholder="DD-MM-YYYY"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          inputProps={{
                            style: { padding: 0, fontFamily: 'Lato' }
                          }}
                          format="DD-MM-YYYY"
                          autoOk
                          openTo="date"
                          style={(this.state.error && this.state.startDate === null) ? webStyle.datetimeinputError :webStyle.datetimeinput}
                          fullWidth
                          disablePast
                          value={this.state.startDate}
                          onChange={this.handleStartDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                    </MuiPickersUtilsProvider>
                    {(this.state.error && this.state.startDate === null)&&
                      <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                    }
                  </Box>
                  <Box style={webStyle.fieldBox}>
                  <Typography style={webStyle.taskNameLabel}>{configJSON.EndDate}</Typography>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker 
                          data-testid="end-date"
                          placeholder="DD-MM-YYYY"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          inputProps={{
                            style: { padding: 0, fontFamily: 'Lato' }
                          }}
                          format="DD-MM-YYYY"
                          autoOk
                          openTo="date"
                          minDate={this.state.startDate !== null ? this.state.startDate : ''}
                          style={(this.state.error && this.state.endDate === null) ? webStyle.datetimeinputError :webStyle.datetimeinput}
                          fullWidth
                          disablePast
                          value={this.state.endDate}
                          onChange={this.handleChangeEndDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                    </MuiPickersUtilsProvider>
                    {(this.state.error && this.state.endDate === null)&&
                      <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                    }
                  </Box>
                  </Box>
                  </Box>
                  <Box style={webStyle.textFiledBox}>
                  <Box style={webStyle.fieldBoxAssing}>
                  <Typography style={webStyle.taskNameLabel}>{configJSON.SelectCriticality}</Typography>
                    <Autocomplete
                          id="critical"
                          options={criticalOptions}
                          data-testid="critical-field"
                          onChange={(event, value) => this.setState({criticalValue:value?.value})}
                          disableCloseOnSelect
                          closeIcon={false}
                          popupIcon={<div style={{ margin: '5px 12px' }}>
                              <img src={arrowDown}/>
                          </div>}
                          getOptionLabel={(option: any) => option.label}
                          renderOption={renderCritical}
                          renderInput={(params) =>
                            <TextField
                                {...params}
                                name="memberlist"
                                placeholder="Select one from the list"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: (this.state.error && this.state.criticalValue === "") ? webStyle.autoErrorComplete:webStyle.autoComplete
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  style: {padding : 0, fontFamily: 'Lato'}
                                }}
                            />
                          }
                          value={criticalOptions.find(option => option.value === this.state.criticalValue)}
                      />
                      {(this.state.error && this.state.criticalValue === "")&&
                      <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                    }
                  </Box>
                  </Box>
                  <Box style={webStyle.textFiledBox}>
                  <Box style={webStyle.fieldBox}>
                  <Typography style={webStyle.taskNameLabel}>{configJSON.Description}</Typography>
                    <TaskAreaField
                    placeholder={configJSON.descriptionPlaceholder}
                    variant="outlined"
                    data-testid="description"
                    onChange={this.handleChangeDescription}
                    value={this.state.description}
                    error={this.state.error && this.state.description === ""}
                    multiline
                    minRows={4}
                    fullWidth
                    />
                    {(this.state.error && this.state.description === "")&&
                      <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                    }
                  </Box>
                  </Box>
                  <Box style={webStyle.buttonBox}>
                  <CreateTaskButton
                    data-testid="create-task-button"
                    disabled={this.state.isLoading}
                    onClick={this.handleCreateTask}
                  >
                    {this.state.isLoading ? <CircularProgress style={{width:'20px', height:'20px'}} /> : configJSON.CreateTask}
                  </CreateTaskButton>
                  </Box>
                </Paper>
            </Modal>
            <Modal 
              open={this.state.deleteModal} 
              onClose={this.handleCloseDeleteModal} 
              style={webStyle.modalWrapper} 
              data-testid="delete-modal"
              >
              <Paper style={webStyle.deleteModalPaper}>
                  <Typography style={webStyle.deleteMessage}>{configJSON.deleteConfirmationMessage}</Typography>
                  <Box style={webStyle.deletebuttonBox}>
                    <NoButton variant="outlined" data-testid="no-delete-task" onClick={this.handleCloseDeleteModal}>{configJSON.noButton}</NoButton>
                    <YesButton variant="contained" data-testid="yes-delete-task" onClick={this.handleDeleteTask}>{configJSON.yesButton}</YesButton>
                  </Box>
              </Paper>
            </Modal>

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={this.state.toastOpen}
              autoHideDuration={4000}
              data-testid="toast"
              onClose={this.handleToastClose}
              message={configJSON.toastMessage}
              action={
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleToastClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
            <Modal
              open={this.state.completeModal}
              onClose={this.handleCloseCompleteModal}
              style={webStyle.modalWrapper}
              data-testid="complete-modal"
            >
              <Paper style={webStyle.deleteModalPaper}>
                <Typography style={webStyle.deleteMessage}>{configJSON.completeConfirmationMessage}</Typography>
                <Box style={webStyle.deletebuttonBox}>
                  <NoButton data-testid="no-complete-task" variant="outlined" onClick={this.handleCloseCompleteModal}>{configJSON.noButton}</NoButton>
                  <YesButton variant="contained" onClick={this.handleCompleteTaskApiCall} data-testid="yes-complete-task">{configJSON.yesButton}</YesButton>
                </Box>
              </Paper>
            </Modal>
            <Modal
              open={this.state.completedTaskModal}
              onClose={this.handleCloseCompleteTaskModal}
              style={webStyle.modalWrapper}
              data-testid="complete-task-modal"
            >
              <Paper style={webStyle.completedModalPaper}>
                <img src={successCheck} />
                <Typography style={webStyle.sucsessTitle}>{configJSON.successTitle}</Typography>
                <Typography style={webStyle.successMessage}>{configJSON.successMessage}</Typography>
              </Paper>
            </Modal>
            
          </LogsContainer>
        </Layout>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  actionText:{
    fontSize:'22px',
    fontWeight:700,
    color:'#34373A',
    lineHeight:'27.5px',
    fontFamily:'Lato',
  },
  actionDescription:{
    fontSize:'12px',
    color:'#7D7D7F',
    lineHeight:'19.2px',
    width:'70%',
    margin:'10px 0',
    fontFamily:'Lato',
  },
  creatButtonDiv:{
    textAlign:'center' as 'center',
    marginTop:'40px',
  },
  noTaskText:{
    fontSize:'10px',
    fontWeight:500,
    lineHeight:'16px',
    color:'#7D7D7F',
    fontFamily:'Lato',
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: "wrap",
    justifyContent: 'space-between',
    gap: 10,
    marginBottom: 15,
    marginLeft: '16px'
  },
  taskTab: {
    display: "flex",
    justifyContent: "center",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    color: "#7B7D7F",
    fontSize: "12px",
    fontWeight: 700,
    cursor: "pointer",
    fontFamily: "Lato",
    fontStyle: "normal",
    letterSpacing: "0.2px",
  },
  activeTab: {
    color: '#329E54',
    backgroundColor: '#ECFBF1'
  },
  modalWrapper:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mdalPaper:{
    width: '100%',
    maxWidth: "899px",
    padding: '30px',
    borderRadius: '12px',
    overflow: 'scroll' as 'scroll',
    maxHeight: '90vh',
    scrollbarWidth: 'none' as 'none',
    msOverflowStyle: 'none' as 'none'
  },
  deleteModalPaper:{
    width: '100%',
    maxWidth: "334px",
    padding: '30px',
    borderRadius: '12px',
    overflow: 'scroll' as 'scroll',
    scrollbarWidth: 'none' as 'none',
    msOverflowStyle: 'none' as 'none'
  },
  completedModalPaper:{
    width: '100%',
    maxWidth: "455px",
    padding: '40px 30px 20px',
    height:'241px',
    borderRadius: '12px',
    overflow: 'scroll' as 'scroll',
    scrollbarWidth: 'none' as 'none',
    msOverflowStyle: 'none' as 'none',
    textAlign:'center' as 'center'
  },
  createModalFirstDiv:{
    display:'flex',
    justifyContent:'space-between'
  },
  createTaskText:{
    fontSize:'18px',
    fontWeight:700,
    lineHeight:'25.2px',
    color:'#34373A',
    fontFamily:'Lato',
  },
  createTaskDescr:{
    fontSize:'14px',
    lineHeight:'24px',
    color:'#34373A',
    padding:'5px 0',
    fontFamily:'Lato',
  },
  textFiledBox:{
    display:'flex',
    gap:'20px',
    marginTop:'15px'
  },
  taskNameLabel:{
    fontFamily:'Lato',
    fontSize:'15px',
    marginBottom:'4px'
  },
  fieldBox:{
    width:'100%'
  },
  fieldBoxAssing:{
    width:'50%'
  },
  optionName:{
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: '14px'
  },
modelSubpopper:{
  maxHeight: '271px',
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    borderBottom:'none',
    boxShadow: 'none !important',
    padding: '0px !important',
},
autoComplete: {
  maxHeight: "48px",
  borderRadius: '8px',
  border: "1px solid #D9D9D9",
  padding: '11px',
  boxShadow: 'none'
},
autoErrorComplete:{
  maxHeight: '48px',
  borderRadius: '8px',
  border: "1px solid #f44336",
  padding: '11px',
  boxShadow: 'none'
},
errorMessage:{
 color:'#f44336',
 padding:'1px',
 fontSize:'14px',
 fontFamily:'Lato',
},
dateMainBox:{
  display:'flex',
  gap:'20px',
  width:'50%'
},
buttonBox:{
  textAlign:'center' as 'center',
  margin:'40px 0 10px'
},
arrowDiv:{ 
  height: 26,
  width: 26, 
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '10px'
},
datetimeinput: {
  width: '100%',
  display: 'flex',
  padding: '10px 10px 8px',
  alignItems: 'center',
  alignSelf: 'stretch',
  border: "#00000024 solid 1px",
  borderRadius: "8px",
  cursor: "pointer",
},
datetimeinputError:{
  width: '100%',
  display: 'flex',
  padding: '10px 10px 8px',
  alignItems: 'center',
  alignSelf: 'stretch',
  border: "#f44336 solid 1px",
  borderRadius: "8px",
  cursor: "pointer",
  color:'#f44336'
},
criticalTable:{
  display:'flex',
  alignItems:'center',
  gap: '6px'
},
deleteMessage:{
  color:'#34373A',
  lineHeight:'24px',
  margin:'10px 0 40px',
  fontFamily:'Lato'
},
deletebuttonBox:{
  display:'flex',
  alignItems:'center',
  gap:'20px',
  justifyContent:'center'
},
endButtonBox:{
},
sucsessTitle:{
  fontSize:'24px',
  fontWeight:700,
  lineHeight:'30px',
  color:'#0F172A',
  textAlign:'center' as 'center',
  margin:'30px 0 10px',
  fontFamily:'Lato',
},
successMessage:{
  fontSize:'17px',
  lineHeight:'26px',
  color:'#34373A',
  textAlign:'center' as 'center',
  fontFamily:'Lato',
},
menuContainer: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  height: '40px',
  overflow: 'visible',
  maxWidth: "45px",
  position: 'absolute', 
  top: 12, 
  right: 6,
  zIndex: 2,
},
popup: {
  width: 167,
  position: 'absolute',
  right: 0,
  top: 40,
},
mainMenuItem: {
  width: 167, padding: 10, left: 0, top: 0,
  position: 'relative', background: 'white', boxShadow: '0px 5px 25px 0px rgba(0, 0, 0, 0.11)',
  borderRadius: 12, flexDirection: 'column', justifyContent: 'flex-start', gap: 8, display: 'inline-flex'
},
itemContainer: {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: 8,
  display: 'flex'
},
downArrow: {
  width: 12, rotate: '134deg',
  height: 12, left: 129, position: 'absolute',
  background: 'white', boxShadow: '15px 20px 50px rgba(15, 23, 42, 0.12)'
},
items: {
  background: 'white',
  textDecoration: 'none',
  justifyContent: 'flex-start',
  alignItems: 'center', gap: 8,
  height: '100%',
},
menuText: {
  color: '#7B7D7F',
  fontSize: 12,
  fontFamily: 'Lato',
  fontWeight: 400,
  wordWrap: 'break-word',
  cursor: 'pointer',
  '&:hover' : {
    background: '#ECFBF1',
    color: '#43D270',
  }
},
};
// Customizable Area End
