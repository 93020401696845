import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { docFileIcon, excelFileIcon, folderViewIcon, pdfFileIcon } from "./assets";
// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start

    // Customizable Area End
}

export interface Category {
    id: string;
    type: string;
    name: string;
}

export interface FolderData {
    id: string;
    type: string;
    name: string;
    categoryName: string;
    categoryId: string;
    size: string;
    date: string;
    downloadUrl:string;
}

interface S {
    // Customizable Area Start
    activeTab: string;
    categoriesList: Category[];
    toastOpen: boolean;
    toastMessage: string;
    openCreateFolderModal: boolean;
    folderName: string;
    selectedSubCategory: string;
    folderNameError: string;
    subCategoryError: string;
    isCreating: boolean;
    folderData: FolderData[];
    anchorEl: any;
    openUploadFileModal: boolean;
    file: File[];
    openCategoryModal: boolean;
    isFetchingData: boolean;
    allowFolderSelection: boolean;
    anchorEl1: any;
    breadcrums: {id:string; name:string;}[];
    searchText: string;
    searchList: FolderData[];
    openSuccessModal: boolean;
    successMessageText: string;
    isUploadingFile: boolean;
    selectedFileId: string;
    selectedFileName: string;
    selectedFileType: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start

    // Customizable Area End
}

export default class Cfesdatamanagement2Controller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    account_id: number;
    company_id: number;
    user_type: string;
    companyDataAPICallId: string = "";
    createFolderAPICallId: string = "";
    folderDataApiCallId: string = "";
    uploadFileApiCallId: string = "";
    searchInputRef: any;
    trashApiCallId: string = "";
    downloadApiCallId: string = '';
    downloadFolderApiCallId: string = '';

    constructor(props: Props) {
        super(props);

        const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
        const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
        this.account_id = userDetails.meta.id;
        this.user_type = userDetails.meta.user_account_type;
        this.company_id = orgDetails.id;

        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            activeTab: 'All',
            categoriesList: [],
            toastOpen: false,
            toastMessage: '',
            openCreateFolderModal: false,
            folderName: '',
            selectedSubCategory: '',
            folderNameError: '',
            subCategoryError: '',
            isCreating: false,
            folderData: [],
            anchorEl: null,
            anchorEl1: null,
            openUploadFileModal: false,
            file: [],
            openCategoryModal: false,
            isFetchingData: false,
            allowFolderSelection: false,
            breadcrums:[],
            searchText: '',
            searchList: [],
            openSuccessModal: false,
            successMessageText: '',
            isUploadingFile: false,
            selectedFileId: '',
            selectedFileName:'',
            selectedFileType: '',
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    apiCall = async (data: { endPoint: string; apiBody: any; apiMethod: string }) => {
        const { endPoint, apiBody, apiMethod } = data;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };
        
        const body: any = { account_id: this.account_id, ...apiBody };

        if (this.user_type === 'portfolio_manager') {
            body.company_id = this.company_id;
        }

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return apiRequest.messageId;
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const requestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (requestCallId === this.companyDataAPICallId) {
                this.companyDataResponseHandler(responseJson);
            }

            if (requestCallId === this.createFolderAPICallId) {
                this.createFolderResponseHandler(responseJson);
            }

            if (requestCallId === this.folderDataApiCallId) {
                this.folderDataResponseHandler(responseJson)
            }

            if (requestCallId === this.uploadFileApiCallId) {
                this.fileUploadResponseHandler(responseJson);
            }

            if(requestCallId === this.trashApiCallId){
                this.trashResponseHandler(responseJson);
            }

            if(requestCallId === this.downloadApiCallId){
                this.downloadResponseHandler(responseJson);
            }

            if(requestCallId === this.downloadFolderApiCallId){
                this.downloadResponseHandler(responseJson);
            }
        }
    }

    downloadResponseHandler(responseJson: any) {
        this.handlePopoverClose();
        if (responseJson?.url) {
            const url = responseJson.url;
            const fileName = responseJson.file_name;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    }

    fileUploadResponseHandler(responseJson: any) {
        if (responseJson.error) {
            this.setState({ toastOpen: true, toastMessage: responseJson.error })
        } else if (responseJson.data?.data?.length > 0) {
            this.setState({ openCategoryModal: false });
            const folderId = this.state.breadcrums.length > 0 ? this.state.breadcrums[this.state.breadcrums.length - 1].id : this.state.activeTab;
            this.getFolderData(folderId);
        }
        this.setState({isUploadingFile: false});
    }

    trashResponseHandler(responseJson: any) {
        if (!responseJson.details && responseJson.message) {
            this.setState({ openSuccessModal: true, successMessageText: 'Your file has been successfully moved to trash.' });
            const folderId = this.state.breadcrums.length > 0 ? this.state.breadcrums[this.state.breadcrums.length - 1].id : this.state.activeTab;
            this.getFolderData(folderId);
            setTimeout(() => {
                this.setState({ openSuccessModal: false, successMessageText: '' })
            }, 2000);
        }
    }

    folderDataResponseHandler(responseJson:any){
        if (responseJson.error) {
            this.setState({ toastOpen: true, toastMessage: responseJson.error })
        } else if (responseJson.data?.data?.length > 0) {
            const list = responseJson.data.data.map((item: any) => {
                const obj:FolderData = {
                    id: item.id,
                    type: item.attributes.type,
                    name: item.attributes.name,
                    categoryName: this.state.categoriesList.find(ele => ele.id === item.attributes.destination_id)?.name ?? '',
                    categoryId: item.attributes.destination_id,
                    size: this.convertSize(item.attributes.storage_info.size_in_bytes ?? 0),
                    date: item.attributes.modified_time_i18,
                    downloadUrl: item.attributes.download_url,
                };
                return obj;
            });
            this.setState({ folderData: list })
        }
        this.setState({isFetchingData: false});
    }

    convertSize = (bytes: number = 0) => {
        if (bytes <= 0) {
            return '-'
        }

        const units = ['KB', 'MB', 'GB', 'TB'];
        let index = 0;
        let size = bytes / 1024;

        while (size >= 1024 && index < units.length - 1) {
            size /= 1024;
            index++;
        }

        return `${size.toFixed(2)} ${units[index]}`;
    }

    companyDataResponseHandler(responseJson: any) {
        if (responseJson.error) {
            this.setState({ toastOpen: true, toastMessage: responseJson.error, isFetchingData: false })
        } else if (responseJson.data?.data?.length > 0) {
            const list = responseJson.data.data.map((item: any) => {
                const obj = {
                    id: item.id,
                    type: item.attributes.type,
                    name: item.attributes.name
                };

                return obj;
            });
            const arr = ['Environmental', 'Social', 'Governance', 'Others'];
            const finalList: Category[] = [];
            arr.forEach(item =>{
                const findObj = list.find((ele:Category) => ele.name === item);
                if(findObj?.id){
                    finalList.push(findObj);
                }
            })
            this.setState({ 
                categoriesList: finalList, 
                activeTab: finalList[0].id, 
                selectedSubCategory: finalList[0].id 
            }, ()=>{
                this.getFolderData(finalList[0].id);
            });
        } else {
            this.setState({ isFetchingData: false});
        }
    }

    async createFolderResponseHandler(responseJson: any) {
        if (responseJson.error) {
            this.setState({ toastOpen: true, toastMessage: responseJson.error, isCreating: false })
        } else if (responseJson.folder?.id) {
            this.setState({ openCreateFolderModal: false, isCreating: false, folderName: '' });
            if(this.state.selectedSubCategory !== this.state.activeTab){
                this.setState({activeTab: this.state.selectedSubCategory});
            }
            this.getFolderData(this.state.selectedSubCategory);
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        this.setState({isFetchingData: true});
        this.companyDataAPICallId = await this.apiCall({
            endPoint: configJSON.companyDataEndPoint,
            apiBody: null,
            apiMethod: configJSON.postAPIMethod,
        });
    }

    tabPressHandler = (tabId: string) => {
        this.setState({ activeTab: tabId, selectedSubCategory: tabId, breadcrums:[] });
        this.getFolderData(tabId);
    }

    handleToastClose = () => {
        this.setState({ toastOpen: false });
    }

    closeModalHandler = () => {
        this.setState({ openCreateFolderModal: false })
    }

    checkFormValidation = () => {
        const { folderName, selectedSubCategory } = this.state;
        const isValid = folderName && selectedSubCategory
        return isValid;
    }

    createFolderHandler = async () => {
        const { folderName, selectedSubCategory, breadcrums } = this.state;
        if (this.checkFormValidation()) {
            this.setState({ folderNameError: '', subCategoryError: '', isCreating: true });
            const parentFolderId = breadcrums.length > 0 ? breadcrums[breadcrums.length -1].id : selectedSubCategory;
            const body: any = {
                parent_folder_id: parentFolderId,
                name: folderName,
            };

            this.createFolderAPICallId = await this.apiCall({
                endPoint: configJSON.createFolderEndPoint,
                apiBody: body,
                apiMethod: configJSON.postAPIMethod,
            })

        } else {
            this.setState({
                folderNameError: folderName ? '' : 'Folder name required.',
                subCategoryError: selectedSubCategory ? '' : 'Sub category required.'
            });
        }
    }

    getFolderData = async (folderId: string) => {
        this.setState({isFetchingData: true, folderData:[]});
        this.folderDataApiCallId = await this.apiCall({
            endPoint: configJSON.folderDataEndPoint,
            apiBody: { parent_folder_id: folderId },
            apiMethod: configJSON.postAPIMethod,
        });
    }

    getSelectedTabName = () => {
        if (this.state.breadcrums.length > 0) {
            let breadcumName = '';
            this.state.breadcrums.forEach((item,index) => {
                breadcumName += index === this.state.breadcrums.length -1 ? `${item.name} ` : `${item.name} > `
            })
            return breadcumName;
        }  
        
        if (this.state.activeTab) {
            const category = this.state.categoriesList.find(item => item.id === this.state.activeTab);
            return category?.name ?? '';
        }
        
        return ''
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    handleClick = (event:any) => {
        this.setState({anchorEl: event.currentTarget});
    };

    closeUploadFileModalHandler = () => {
        this.setState({ openUploadFileModal: false });
    }

    uploadFile = async () => {
        if(this.state.isUploadingFile || !this.state.selectedSubCategory){
            return;
        }
        const {breadcrums, selectedSubCategory, file} = this.state;
        const parentFolderId = breadcrums.length > 0 ? breadcrums[breadcrums.length -1].id : selectedSubCategory;
        const formData = new FormData();
        this.setState({isUploadingFile: true});
        formData.append("folder_id", parentFolderId);
        file.forEach(item =>{
            formData.append("file", item);
        });
        formData.append("account_id", `${this.account_id}`);
        formData.append("company_id", `${this.company_id}`);

        const token = await getStorageData("authToken");
        const header = {
            // "Content-Type": 'multipart/form-data;',
            "token": token
        };

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        
        this.uploadFileApiCallId = apiRequest.messageId;

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.uploadFileEndPoint);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postAPIMethod);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

        runEngine.sendMessage(apiRequest.id, apiRequest);
    }

    handlePopoverClose = () => {
        this.setState({ anchorEl1: null, selectedFileId: '', selectedFileName:'', selectedFileType: '' });
    };

    handleDownload = async (fileName: string, fileId:string) => {
        const url = `${configJSON.downloadEndPoint}?account_id=${this.account_id}&company_id=${this.company_id}&resource_id=${fileId}`
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.downloadApiCallId = apiRequest.messageId;

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPIMethod);
        apiRequest.addData(getName(MessageEnum.BlobResponseData), true);
        apiRequest.addData(getName(MessageEnum.BlobResponseFileName),fileName );
        runEngine.sendMessage(apiRequest.id, apiRequest);
    };

    handleDownloadFolder = async (folderName: string, folderId:string) =>{
        const url = `${configJSON.downloadFolderEndPoint}?account_id=${this.account_id}&company_id=${this.company_id}&resource_id=${folderId}`

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.downloadFolderApiCallId = apiRequest.messageId;

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postAPIMethod);
        apiRequest.addData(getName(MessageEnum.BlobResponseData), true);
        apiRequest.addData(getName(MessageEnum.BlobResponseFileName), folderName);
        runEngine.sendMessage(apiRequest.id, apiRequest);
    }

    getFileIcon = (fileType: string) => {
        switch (fileType) {
            case "folder":
                return folderViewIcon;

            case "pdf":
                return pdfFileIcon;

            case "xls":
            case "xlsx":
            case "spreadsheet":
                return excelFileIcon;

            default:
                return docFileIcon;
        }
    }

    handleOpenFolder = (data: FolderData) => {
        if (data.type === "folder") {
            const parentObj = {
                id: data.categoryId,
                name: data.categoryName,
            };
            const currentFolder = {
                id: data.id,
                name: data.name,
            };

            if (this.state.breadcrums.length > 0) {
                this.setState({ breadcrums: [...this.state.breadcrums, currentFolder] });
            } else {
                this.setState({ breadcrums: [parentObj, currentFolder] });
            }
            this.getFolderData(data.id);
        }
    }

    searchHandler = (searchText:string) => {
        const list = this.state.folderData.filter(item => item.name.includes(searchText));
        this.setState({searchList: list});
    }

    deleteFileFolderHandler = async (folderId: string) => {
        this.handlePopoverClose();
        const body = {
            file_ids: [folderId],
            task_name: "trash",
        };

       this.trashApiCallId = await this.apiCall({
            endPoint: configJSON.deleteEndPoint,
            apiMethod: configJSON.postAPIMethod,
            apiBody: body,
        })
    }

    handleDelete = () => {
        if (this.state.selectedFileId) {
            this.deleteFileFolderHandler(this.state.selectedFileId);
        }
    }

    downloadFilesFolders = () => {
        if (this.state.selectedFileId) {
            if (this.state.selectedFileType === 'folder') {
                this.handleDownloadFolder(this.state.selectedFileName, this.state.selectedFileId);
            } else {
                this.handleDownload(this.state.selectedFileName, this.state.selectedFileId);
            }
        }
    }
    // Customizable Area End
}
