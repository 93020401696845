import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { es, es_active } from "./assets";
import Appointments from "./Appointments.web";
import Layout from "../../dashboard/src/Layout.web";
import PlanAndBillingCard from "../../dashboard/src/common/PlanAndBillingCard.web";

const InnerContainer = styled("div")({
    width: "100%",
    height: "auto",
    padding: "27px 0 44px 0px"
});

const ExpertTabContent = styled('div')({
    width: "100%",
})

const StyledBox = styled(Box)({
     '@media(max-width: 600px)': {
       marginLeft:'0px !important'
    },
})
const StyledBox2 = styled(Box)({
    '@media(max-width: 600px)': {
        marginLeft:'0px !important'
    },
})

// Customizable Area End

import AppointmentmanagementController, {
    Props,
} from "./AppointmentmanagementController";

export default class ExpertSupport extends AppointmentmanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const activeTab = this.props.navigation ? this.props.navigation.getParam('orgTab') : 'portfolio-manager';
        const description = "Personal ESG data consultant who will be responsible for managing and overseeing environmental and sustainability efforts will be assigned to you as you upgrade to the next tier. Please click the below link to upgrade."
        const tabsData = [
            {
                id: 1,
                text: "Portfolio Manager",
                icon: es,
                activeIcon: es_active,
                component: <Appointments navigation={this.props.navigation} id={""} />,
                isActive: activeTab === "portfolio-manager",
                link: "/portfolio-manager"
            },
            {
                id: 2,
                text: "ESG Data Consultant",
                icon: es,
                activeIcon: es_active,
                component: <StyledBox style={{ marginLeft: '38px' }}>
                    <PlanAndBillingCard navigation={this.props.navigation} id={""}
                        heading="ESG Data Consultant"
                        description={description}
                        width="536px"
                        buttonwidth = "227px"
                    />
                </StyledBox>,
                isActive: activeTab === "ESG-data-consultant",
                link: "/ESG-data-consultant"
            },
            {
                id: 3,
                text: "Sustainability Advisor",
                icon: es,
                activeIcon: es_active,
                component: <StyledBox2 style={{ marginLeft: '38px' }}>
                <PlanAndBillingCard navigation={this.props.navigation} id={""}
                    heading="Sustainability Advisor"
                    description={description}
                    width="536px"
                    buttonwidth = "227px"
                />
            </StyledBox2>,
                isActive: activeTab === "sustainability-advisor",
                link: "/sustainability-advisor"
            }
        ];
        return (
            <>
                <Layout>
                    <InnerContainer>
                        <Box style={{marginTop: '32px'}}>
                        {tabsData.map((content) => {
                            return <ExpertTabContent key={`content${content.id}`}>{content.isActive ? content.component : ""}</ExpertTabContent>
                        })}
                        </Box>
                    </InnerContainer>
                </Layout>
            </>
        );
        // Customizable Area End
    }

}

// Customizable Area Start
// Customizable Area End
