import React from "react";

import {
  // Customizable Area Start
  Typography,
    Divider,
    SvgIcon,
    Box,
    Checkbox,
    MenuItem,
    Select,
    Button,
    Modal,
    Input,
    IconButton 
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
type CSSProperties = {
  [key: string]: string | number | undefined;
};
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import VisualAnalytics from "../../../visualanalytics/src/VisualAnalytics.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { overviewbg, line2, line3, line1, line6 } from "../assets";
import SwitchButton from "../common/SwitchButton.web";
import Layout from "../Layout.web";

const theme = createTheme({
  palette: {
      primary: {
          main: "#fff",
          contrastText: "#fff"
      }
  },
  typography: {
      h6: {
          fontWeight: 500
      },
      subtitle1: {
          margin: "20px 0px"
      }
  },
});
const MainDiv = styled(Box)({
  '@media(max-width: 600px)': {
      flexDirection: "column",
  },
  })
const ErrDiv = styled(Box)({
  '@media(max-width: 400px)': {
      height: "68%  !important",
  },
})

const CustomBtn = styled(Button)({ 
 textTransform: "capitalize",
  display: 'flex',
  width: '187px',
  height: '40px',
  padding: '8px 8px 8px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '8px',
  background: '#43D270',
  color: '#34373A',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '160%',
})

const CHeckbox = styled(Checkbox)({
  color: 'grey',
  '&.Mui-checked , &.Mui-checked:checked': {
      color: '#43D270',
  },
  '&:hover': {
      color: '#43D270',
  },
})
const SelectBox = styled(Select)({
  "&.MuiSelect-root, &.MuiSelect-select-184": {
      paddingRight: "0px !important"
  }

})
// Customizable Area End

import DashboardController, {
  Props,
  configJSON,
} from "../DashboardController";

export default class ForeCastAnalysis extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.isLogin()
    this.getGraphData()
 
}

 handleOpenIcon = () => {
    let { openSelectbox }= this.state
   this.setState({openSelectbox:!openSelectbox});
  };

renderValueSelectBox = () =>{
  return this.state.selectedScopeLabel.length !==0 ?this.state.selectedScopeLabel: [' Select Scope']
}

renderIcon=(props:any)=>(
<IconButton
    {...props}
    onClick={this.handleOpenIcon}
    data-test-id="rendorIconid"
    style={{height:"16px",width:"16px",display:"flex",justifyContent:"center", alignItems:"center", marginRight: '12px',}}
  >
  { !this.state.openSelectbox ? <div>
    <ExpandMoreIcon style={{color: '#34373A', width:"16px"}} />
    </div> :
    <div style={{transform: "rotate(180deg)"}}>
    <ExpandMoreIcon style={{color: '#34373A', width:"16px" }} />
    </div>}
  </IconButton>)

renderCheckedValue = (item:any)=> {
 return this.state.selectedItemScopeName.indexOf(item?.id) > -1
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    let { graphData, updatedDashedArr, seriesVisibility2
      , switch1, switch3, switch2, switch4 } = this.state
      let data = [configJSON]
      data= []
      console.log("configJSON",data)

  const LineOptions = {
      chart: {
          stacked: false,
          zoom: {
              enabled: false
          },
          toolbar: {
              show: false,
          }
      },
      dataLabels: {
          enabled: false
      },
      series:
          [
              {
                  id: 1,
                  name: "Current emissions",
                  data: graphData.current_emissions,
                  color: "#34373A"
              },
              {
                  id: 2,
                  name: "Our your decarbonization",
                  data: graphData.our_your_decarbonization,
                  color: "#43D270",
              },
              {
                  id: 3,
                  name: "BAU emission",
                  data: graphData.bau_emission,
                  color: "#FA8C16",
              },

              {
                  id: 4,
                  name: "Your decarbonization",
                  data: graphData.your_decarbonization,
                  color: "#2563EB"
              }

          ],
      stroke: {
          width: 2,
          curve: 'smooth',
          dashArray: updatedDashedArr,
      },
      toolbar: {
          show: false,
      },
      xaxis: {
          axisTicks: {
              show: false
          },
          categories: graphData.x_axis,
          axisBorder: {
            show: false
          },
          labels: {
            style: {
              colors: '##34373A',
              fontSize: '12px',
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: '160%'
            }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '##34373A',
            fontSize: '12px',
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: '160%'
          },
        }
      },
      grid: {
        borderColor:"#E9E9E9"
      },
      legend: {
          show: false,
          onItemHover: {
              highlightDataSeries: false,
          },
      },
      fill: {
          type: 'gradient',
          gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.6,
              opacityTo: 0.3,
          },
      },
      lineWidth: 1
  }
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

    return (
    //   <ThemeProvider theme={theme} >
    //             <Layout>
                  <>
                    <div style={webStyle.root as CSSProperties}>
                        <div style={webStyle.titleContainer as CSSProperties}>
                            <Typography style={webStyle.title}>Your decarbonization pathway</Typography>
                            <Typography style={{ ...webStyle.subtitle, maxWidth: '882px' }}>
                            This chart forecasts your future emissions based on historical data. It also illustrates how implementing CarbonZeroed initiatives can accelerate your journey to carbon neutrality.                            </Typography>
                        </div>
                        <Divider  />
                        {
                             this.state.isEsgDone ?
                                 <div style={webStyle.overviewbg}>
                                     <div style={webStyle.insideModel}>
                                        <Typography style={webStyle.modelTitle}>Log your emissions to track them on your dashboard</Typography>
                                         <Typography style={webStyle.modelDesc}>
                                             Data for climate insights comes from your log entries. It seems like your organization
                                             didn't log enough data for us to showcase any insights. Please enter log data in order to leverage CarbonZeroed full potential.
                                         </Typography>
                                        <Link to="/measure/log-your-esg-factors/core"
                                         style={webStyle.logbutton as CSSProperties}
                                        >+ Log Your First Emission</Link>
                                     </div>
                                 </div> :
                                <div style={{ padding: '26px 0 0 44px' }} className='graphContainer'>
                                    <div className = 'selectScope' style={{ display: 'flex', justifyContent: 'space-between', width: '77%', marginBottom: '34px', paddingLeft: "25px" }}>
                                        <div>
                                            <Typography style={{ ...webStyle.subtitle, marginBottom: '0 !important' }}>Emissions as capture so far</Typography>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Typography style={webStyle.LineValue}>(Kg CO2e)</Typography>

                                            </div>
                                        </div>
                                        <div>
                                        </div>

                                    </div>

                                    <VisualAnalytics
                                        navigation={this.props.navigation}
                                        id={"Overview"}
                                        chartType={"area"}
                                        height={300}
                                        data={LineOptions.series.filter((_, index) => { return seriesVisibility2[index] === true })}
                                        option={LineOptions}
                                        width="80%"
                                    />
                                    <MainDiv style={webStyle.mainDiv}>
                                        <div style={{ width: "300px" }}>
                                            <div style={webStyle.divContent}>
                                                <img src={line6} alt='' />
                                                <div style={{ width: "186px" }}><label style={webStyle.labelDesign}>Current Emissions</label></div>
                                                <SwitchButton data-test-id="switch1" index={1} id={1} checked={switch1}
                                                    handleChange={this.handleSwitches} />
                                            </div>
                                            <div style={webStyle.divContent}>
                                                <img src={line3} alt='' />
                                                <div style={{ width: "186px" }}><p style={webStyle.labelDesign}>Ours + Your decarbonization initiatives</p></div>
                                                <SwitchButton index={2} id={2} checked={switch2}
                                                    handleChange={this.handleSwitches} />
                                            </div>
                                        </div>
                                        <div>
                                            <div style={webStyle.divContent}>
                                                <img src={line2} alt='' />
                                                <label style={webStyle.labelDesign}>BAU emissions forecast</label>
                                                <SwitchButton index={3} id={3} checked={switch3}
                                                    handleChange={this.handleSwitches} />
                                            </div>
                                            <div style={webStyle.divContent}>
                                                <img src={line1} alt='' />
                                                <div style={{ width: "150px" }}><label style={webStyle.labelDesign}>Your decarbonization initiatives</label></div>
                                                <SwitchButton index={4} id={4} checked={switch4}
                                                    handleChange={this.handleSwitches} />
                                            </div>
                                        </div>



                                    </MainDiv>
                                </div>
                        }

                    </div>
                    <Modal
                        open={this.state.errModal}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <ErrDiv style={webStyle.errDiv}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                                    <Typography style={webStyle.errTypo}>Log your emissions to track them on your dashboard</Typography>
                                    <Typography style={webStyle.errMsg}>Data for climate insights comes from your log entries. It seems like your organization didn't log enough data for us to showcase any insights. Please enter log data in order to leverage CarbonZeroed full potential. </Typography>
                                    <div style={{paddingLeft:"27px",paddingBottom:"20px", paddingRight:"27px"}} >
                                        <CustomBtn data-test-id="errModalId" onClick={this.navigateOnCard} > + Log Your First Emission</CustomBtn>
                                    </div>
                                </div>

                            </div>
                        </ErrDiv>
                    </Modal>
                  </>
            //      </Layout >
            //  </ThemeProvider >
    );
    // Customizable Area End
  }

}

// Customizable Area Start
const webStyle = {
  root: {
      flexDirection: 'column',
      justifyContent: 'space-around',
      backgroundColor: '#FFF',
      width: '100%',
      display: 'flex',
  },
  title: {
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "22px",
      color: "#34373A",
      fontFamily: "Lato",
      lineHeight: "125%",
      marginBottom: '23px',
  },
  subtitle: {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "12px",
      letterSpacing: "160%",
      fontStyle: "normal",
      color: "#7D7D7F",
      marginBottom: '33px',
      lineHeight: "160%",
  },
  LineValue: {
      fontSize: "18px",
      lineHeight: "25.2px",
      fontFamily: "Lato",
      color: "#0F172A",
      fontWeight: 700,
      letterSpacing: "0.2 px",
  },
  perEmtn: {
      fontSize: "12px",
      color: "#34D399",
      marginLeft: '5px',
      lineHeight: "19.2px",
      fontWeight: 500,
      fontFamily: "Lato",
  },
  titleContainer: {
      display: 'flex',
      padding: '20px 20px 10px 44px',
      width: '100%',
      marginTop: '20px',
      flexDirection: 'column',
  },
  overviewbg: {
      backgroundImage: `url(${overviewbg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '642px',
      minWidth: '1190px',
  },
  modelTitle: {
      fontFamily: "Montserrat",
      color: "#101010",
      fontSize: "20px",
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "120%"
  },
  modelDesc: {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontSize: "15px",
      color: "#34373A",
      lineHeight: "160%",
      fontWeight: 400,
      marginTop: '12px'
  },
  insideModel: {
      borderRadius: "12px", padding: '27px',
      background: "#ECFBF1", maxWidth: '900px',
      marginTop: '93px', marginLeft: '44px',
      maxHeight: '198px'
  },
  logbutton: {
      height: "40px",
      width: "187px",
      padding: "8px 8px 8px 12px",
      justifyContent: "center",
      display: "flex",
      gap: "4px",
      alignItems: "center",
      background: "#43D270",
      borderRadius: "8px",
      color: "#34373A",
      textAlign: "center",
      fontFamily: "Inter !important",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineWeight: "160%",
      marginTop: '22px',
      textDecoration: 'none'
  },
  labelDesign: {
      color: '#34373A',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '160%',
  },
  divContent: {
      width: "310px",
      height: "48px",
      display: "flex",
      gap: "24px",
      marginBottom: "8px",
      justifyContent: "space-around",
      alignItems: "center"
  },
  mainDiv: {
      display: "flex",
      width: "79.6%",
      height: "220px",
      paddingLeft: "45px",
      justifyContent: "space-between"
  },
  errDiv: {
      width: '60%',
      height: '198px',
      borderRadius: '12px',
      background: '#ECFBF1',
  },
  errTypo: {
      color: 'var(--neutral-100, #101010)',
      fontFamily: 'Montserrat',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%',
      paddingBottom: "12px",
      paddingTop: "25px",
      paddingLeft:"27px",
      paddingRight:"27px"
  },
  errMsg: {
      width: '100%',
      color: '#34373A',
      fontFamily: 'Lato',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '160%',
      paddingBottom: "22px",
      paddingLeft:"27px",
      paddingRight:"27px"
  },
  selectstyle:{
        borderRadius: '8px',
        fontWeight: 600,
        maxHeight: '36px',
        color: "#34373A",
        border: "1px solid #DFDFDF",
        fontFamily: "Inter !important",
        fontStyle: "normal",
        fontSize: "12px",
        padding: '8px',
        width:"174px",
        marginRight: '10px',
        lineHeight: '160%'
  }

};
// Customizable Area End
