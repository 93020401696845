import React, {useState} from "react";
import { Container, Grid, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
import { ic_search } from "../../../blocks/DynamicContent/src/assets";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(() => ({
  mainBlock: {
    margin: "60px 0",
    display: "flex",
    gap: "30px",
    flexWrap: "nowrap",
    "@media (max-width: 768px)": {
      flexDirection: "column"
    }
  },
  toolkitBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
  toolkitItem: {
    border: "1px solid #9D9D9D",
    borderRadius: "12px",
    padding: "24px"
  },
  toolkitDetail: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    alignItems: "center",
    padding: "16px 0",
    fontFamily: "Lato",
    "&:not(:last-child)": {
      borderBottom: "1px solid #9D9D9D"
    }
  },
  toolkitDescription: {
    "& > *": {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "48px",
      "@media (max-width: 768px)": {
        fontSize: "24px",
        lineHeight: "32px"
      }
    }
  },
  toolkitSubTitle: {
    "& > *": {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "28px",
      color: "#1f1f1f",
      "@media (max-width: 768px)": {
        fontSize: "16px",
        lineHeight: "24px"
      }
    }
  },
  toolkitSubDescription: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#4c4c4c",
    "@media (max-width: 768px)": {
      fontSize: "14px",
      lineHeight: "20px"
    }
  },
  subImage: {
    width: "20px",
    height: "20px",
    objectFit: "cover"
  },
  post: {
    display: "flex",
    fontFamily: "Lato",
    gap: "16px",
    margin: "16px 0",
    cursor: "pointer",
    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      borderRadius: "8px"
    },
    "& div": {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      "& span": {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#4c4c4c"
      },
      "& div": {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#1f1f1f",
        "@media (max-width: 768px)": {
          fontSize: "16px",
          lineHeight: "24px"
        }
      }
    }
  },
  recentPost: {
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Lato",
    lineHeight: "32px",
    marginTop: "48px"
  },
  input: {
    backgroundColor: "#FAFAFB",
    width: "100%",
    maxWidth: "100%",
    fontSize: "15px",
    borderRadius: "8px !important",
    border: "1px solid #626262 !important",
    overflow: "hidden",
    padding: "0 12px"
  }
}));

const getTagColors: any = {
  Environmental: "#43D270",
  Social: "#7166F1",
  Governance: "#F96C30",
  Flexible: "#F96C30",
  OnSite: "#43D270",
  Online: "#43D270",
  Remote: "#7166F1"
};

const getTagBackgroundColors: any = {
  Environmental: "#E3F8EA",
  Social: "#E5E1FF",
  Governance: "#FFF5F2",
  Flexible: "#FEE9E0",
  OnSite: "#E5F9F0",
  Online: "#E5F9F0",
  Remote: "#F3F0FF"
};

const getTagStyle = (tag: string) => {
  return {
    backgroundColor: getTagBackgroundColors[tag] as string,
    color: getTagColors[tag] as string,
    borderRadius: "4px",
    padding: "4px 16px",
    width: "fit-content",
    fontWeight: 650
  };
};

function BodyToolkit(props: any) {
  const { toolkit_items, recent_posts, navigate } = props;
  const styles = useStyles();
  const [search, setSearch] = useState("");

  const filteredPosts = recent_posts.filter((post: any) => {
    return post.title.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <Container>
      <Helmet>
        <link rel="canonical" href="https://www.carbonzeroed.com/toolkit" />
      </Helmet>
      <Grid container className={styles.mainBlock}>
        <Grid item={true} xs={12} md={7} className={styles.toolkitBlock}>
          {toolkit_items.data.map((toolkitItem: any, index: number) => {
            return (
              <div key={toolkitItem.id} className={styles.toolkitItem}>
                <div className={styles.toolkitDescription}>
                  {parse(toolkitItem.attributes.toolkit_description)}
                </div>
                {toolkitItem.attributes.toolkit_sub_items.data.map(
                  (subItem: any) => {
                    const {
                      sub_title,
                      sub_description,
                      sub_image,
                      sub_link
                    } = subItem.attributes;
                    return (
                      <div key={subItem.id} className={styles.toolkitDetail}
                           onClick={() => window.open(sub_link)}>
                        <div>
                          <span className={styles.toolkitSubTitle}>
                            {parse(sub_title)}
                          </span>
                          <span className={styles.toolkitSubDescription}>
                            {parse(sub_description)}
                          </span>
                        </div>
                        {sub_image ? (
                          <img
                            className={styles.subImage}
                            src={sub_image}
                            alt={sub_title}
                          />
                        ) : null}
                      </div>
                    );
                  }
                )}
              </div>
            );
          })}
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            data-test-id="handle-change-function"
            variant="standard"
            className={styles.input}
            onChange={(e: any) => setSearch(e.target.value)}
            value={search}
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <img src={ic_search} alt="search" height={24} width={24}/>
                </InputAdornment>
              ),
              disableUnderline: true
            }}
          />
          <div className={styles.recentPost}>Recent Posts</div>
          <div>
            {filteredPosts.map((item: any) => {
              const {
                id,
                title,
                resource_tag,
                insight_image
              } = item;
              const tagStyle = getTagStyle(resource_tag);
              // @ts-ignore
              return (
                <div
                  key={id}
                  className={styles.post}
                  onClick={() => navigate(id)}
                >
                  <img
                    src={insight_image}
                    style={{ opacity: insight_image ? "1" : "0" }}
                    alt={""}
                  />
                  <div>
                    <span style={{ ...tagStyle, textTransform: "uppercase" }}>
                      {resource_tag}
                    </span>
                    <div>{title}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default BodyToolkit;
