import React from "react";

import {
  // Customizable Area Start
    Box,
    Typography,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import TablePagination from "../../../dashboard/src/common/TablePagination.web";

const tableLabels = {
    title: "Report Title",
    description: "Report Year",
    action: "Action",
};

const ReportTableContainer = styled(TableContainer)({
    minHeight: "583px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: '26px',
    padding: '12px',
    paddingLeft: "0px"
});

const ReportTable = styled(Table)({
    width: "100%",
    borderCollapse: "separate",
});

const ReportTableBody = styled(TableBody)({
    color: "inherit",
});

const ReportSpacing = styled("div")({
    height: 16,
    display: "table-header-group",
});

const ReportTableCellHead = styled(TableCell)({
    minHeight: "48px",
    padding: "8px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const ReportTableCellHeadTitle = styled(TableCell)({
    minHeight: "48px",
    padding: "8px 18px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
    minWidth: '240px'
});

const ReportTableCell = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const ReportTableCellTitle = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px 8px 8px 18px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const ReportTableHead = styled(TableHead)({
    backgroundColor: "#F5F5F5",
    borderRadius: "10px",
});

const ReportTableRowHead = styled(TableRow)({
    borderRadius: "20px !important",
    overflow: "hidden",
    "& :first-child": {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    "& :last-child": {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
});
// Customizable Area End

import Multiplelandingpage2Controller, {
  Props,
  configJSON,
} from "../Multiplelandingpage2Controller";
import { emptyStateImage } from "../../../cfesgdatamanagement2/src/assets";

export default class Reports extends Multiplelandingpage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <>
            <Typography style={webStyle.title}>Reports</Typography>
            <Typography style={webStyle.description}>Select a report from the below list to get a detailed view of selected metrics.</Typography>
            {this.state.reportList.length > 0 ? (
                <>
                    <ReportTableContainer>
                        <ReportTable aria-label="users table">
                            <ReportTableHead>
                                <ReportTableRowHead>
                                    <ReportTableCellHeadTitle>
                                        {tableLabels.title}
                                    </ReportTableCellHeadTitle>
                                    <ReportTableCellHead>{tableLabels.description}</ReportTableCellHead>
                                    <ReportTableCellHead>{tableLabels.action}</ReportTableCellHead>

                                </ReportTableRowHead>
                            </ReportTableHead>
                            <ReportSpacing />
                            <ReportTableBody>
                                {this.state.reportList.map((report, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <ReportTableCellTitle>
                                                {report.report_name}
                                            </ReportTableCellTitle>
                                            <ReportTableCell>
                                                {report.report_year}
                                            </ReportTableCell>
                                            <ReportTableCell>
                                                <Box style={webStyle.button} onClick={() => { window.open(report.attachment_url, "_blank") }}>
                                                    <Typography style={webStyle.buttonText}>Download</Typography>
                                                </Box>
                                            </ReportTableCell>
                                        </TableRow>
                                    );
                                })}
                            </ReportTableBody>
                        </ReportTable>
                    </ReportTableContainer>
                    <div style={webStyle.paginator}>
                        <TablePagination count={5} />
                    </div>
                </>
            ) : (
                <Box style={webStyle.emptyStateContainer as React.CSSProperties}>
                    <img src={emptyStateImage} style={{ width: '165px', height: '152px' }} />
                    <Typography style={webStyle.emptyStateText as React.CSSProperties}>No reports found. Uploaded reports will be listed here.</Typography>
                </Box>
            )}
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    title: {
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "125%",
        margin: '32px 0 23px 0'
    },
    description: {
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "160%",
        maxWidth: '882px',
    },
    button: {
        minWidth: '140px',
        maxWidth: '140px',
        minHeight: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
        borderRadius: "8px",
        textDecoration: "none",
        border: "1px solid #000"
    },
    paginator: {
        // margin: '0 80px 0 35px'
    },
    buttonText:{
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: '20px',
    },
    emptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10%',
    },
    emptyStateText: {
        fontFamily: 'Lato',
        fontSize: '16px',
        lineHeight: '20.8px',
        color: '#34373A',
        fontWeight: 400,
        textAlign: 'center',
        maxWidth: '500px',
        marginTop: '29px'
    },
};
// Customizable Area End
