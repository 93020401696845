import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Task, ViewMode } from "gantt-task-react";
import { Alert } from "react-native";
import moment from "moment";
import { TaskType } from "gantt-task-react/dist/types/public-types";
import { getStorageData } from "../../../framework/src/Utilities";

interface APIData {
  attributes: {
    project_start_date: string;
    project_end_date: string;
    task_name: TaskType;
    percentage: number;
  };
  id: string;
  type: TaskType;
}

interface ChartOption {
  x: string;
  y: number[];
  task_progress_percentage_avg: number;
  assignees: {id: number; image: string; full_name:string;}[];
};
// Customizable Area End

export const configJSON = require("./config");
export const ganttData = require("./constants.json");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ganttChartView: ViewMode;
  ganttChartTasks: Task[];
  ganttChartTasksMobile: Task[];
  isChecked: boolean;
  ganttChartColumnWidth: number;
  minGrid: string;
  maxGrid: string;
  selectedPeriodType:string;
  openDropdown:boolean;
  chartData: {
    name:string; 
    data: ChartOption[];
  }[];
  graphObj: {
    [key: string]: {
      name:string; 
      data: ChartOption[];
    }[];
  };
  errModal:boolean;
  listCount: number;
  activeCateogry: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  name: string;
  progress: number;
  start: Date;
  end: Date;
  // Customizable Area End
}

export default class GanttChartController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  account_id: string = '';
  company_id: string = '';
  fetchDataApiCallId: string = '';
  user_type: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ganttChartView: ViewMode.Day,
      ganttChartTasks: ganttData,
      ganttChartTasksMobile: ganttData,
      isChecked: true,
      ganttChartColumnWidth: 80,
      minGrid: "2023-01-01",
      maxGrid: "2023-03-31",
      selectedPeriodType:'Monthly',
      openDropdown:false,
      chartData: [
        { name: 'Planned', data: [] },
        { name: 'Actual', data: [] },
      ],
      errModal: false,
      listCount: 0,
      graphObj:{},
      activeCateogry: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
        const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
        this.account_id = userDetails.meta.id;
        this.company_id = orgDetails.id;
        this.user_type = userDetails.meta.user_account_type;
    // Customizable Area End
  }

  async componentDidMount() {
    await this.getWaterFallData();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id == getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(this.fetchDataApiCallId === apiRequestCallId){
        this.handleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  prepareData = (subCategory: any, assignees:{id: number; image: string; full_name:string;}[] = []) => {
    const chartObj : {plannedTask: ChartOption | null, actualTask: ChartOption | null} = {plannedTask: null, actualTask: null};
    const obj: ChartOption = {
      x: subCategory.sub_category_name,
      y: [new Date().getTime(), new Date().getTime()],
      task_progress_percentage_avg: 0,
      assignees: []
    };

    if (subCategory.planned_min_date) {
      obj.y = [
        new Date(subCategory.planned_min_date).getTime(),
        new Date(subCategory.planned_max_date).getTime()
      ];
      obj.assignees = [...assignees];
      chartObj.plannedTask = obj;
    }

    if (subCategory.task_progress_percentage_avg && subCategory.actual_start_date) {
      const data = { ...obj };
      data.task_progress_percentage_avg = subCategory.task_progress_percentage_avg;
      data.y = [
        new Date(subCategory.actual_start_date).getTime(),
        new Date(subCategory.expected_completion_date).getTime()
      ];
      chartObj.actualTask = data;
    }

    return chartObj;
  }

  prepareChartData = (responseJson: any) => {
    const graphData = {...this.state.graphObj};

    responseJson.categories.forEach((item: any) => {
      const plannedTasks:ChartOption[] = [];
      const actualTasks:ChartOption[] = [];

      if (item.sub_categories?.length > 0) {
        item.sub_categories.forEach((subCategory: any) => {
          const assignees: {id: number; image: string; full_name:string;}[] = [];
          subCategory.tasks.forEach((e: any) => {
            e.assignees.forEach((ele:any) => {
              if(assignees.length > 0){
                const obj = assignees.find(data => data.id === ele.id);
                if(!obj){
                  assignees.push(ele);
                }
              } else {
                assignees.push(ele);
              }
            });
          });
          
          const data = this.prepareData(subCategory, assignees);
          if(data.plannedTask !== null){
            plannedTasks.push(data.plannedTask);
          }

          if(data.actualTask !== null){
            actualTasks.push(data.actualTask);
          }
        });
      }

      const category = [
        { name: 'Planned', data: plannedTasks },
        { name: 'Actual', data: actualTasks }
      ];
      
      graphData[item.category_name] = category;
    });

    this.setState({graphObj: graphData});

    if (Object.keys(graphData).length > 0) {
      this.setState({
        activeCateogry: Object.keys(graphData)[0],
        chartData: graphData[Object.keys(graphData)[0]],
      });
    } else {
      this.setState({
        activeCateogry: '',
        chartData: [
          { name: 'Planned', data: [] },
          { name: 'Actual', data: [] }
        ],
      });
    }
  }

  handleResponse = (responseJson: any) => {
    if (responseJson.success) {
      let count = 0;
      responseJson.categories.forEach((item: any) => {
        if (item?.sub_categories?.length > 0) {
          item.sub_categories.forEach((ele: any) => {
            count += ele.tasks?.length ?? 0
          });
        }
      });
      this.setState({ listCount: count });

      this.prepareChartData(responseJson);
      if (count === 0 && this.user_type === 'portfolio_manager') {
        this.setState({ errModal: true });
      }
    }
  }


  async getWaterFallData() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const body = {
      "account_id": this.account_id,
      "company_id": this.company_id,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.actionPlanDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), 
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onGanttChartPress = (text: SS) => {
    let message = `${moment(text?.start).format("YYYY-MM-DD")} - ${moment(
      text.end
    ).format("YYYY-MM-DD")}\nProgress: ${text.progress * 100}%`;
    Alert.alert(text.name, message);
  };

  navigateToDetails = {
    onPress: () => {
      this.props.navigation.navigate("GanttChartDetails");
    },
  };

  formatTasks = () => {
    const formattedTasks = this.state.ganttChartTasks.map(task => {
      return {
        ...task,
        start: new Date(task.start),
        end: new Date(task.end),
      }
    });
    return formattedTasks;
  }
  toggleDropDown = () =>{
    this.setState({openDropdown: !this.state.openDropdown});
  }

  closeDropDown = () =>{
    this.setState({openDropdown: false});
  }

  handleOpenDropDown = () =>{
    this.setState({openDropdown: true});
  }

  handleChangeEmmission = (e: any) => {
    this.setState({ selectedPeriodType: e.target.value });
  }

  navigateToClimaticGoals = () =>{
    this.setState({errModal:false});
    this.props.navigation.navigate('GoalManagement');
  }

  navigateToDoList = () => {
    localStorage.setItem("taskType", "Climate goals");
    this.props.navigation.navigate("GettingStarted");
  }

  getBackgroundColor(key:string){
    if(key === 'Decarbonization Strategy'){
      return '#6EEA95';
    }
    if(key === 'Digital Levers'){
      return '#AB9EFF';
    }
    return '#F49A7A';
  }

  categoryPressHandler = (key:string) => {
    this.setState({
      chartData: this.state.graphObj[key],
      activeCateogry: key
    });
  }
  // Customizable Area End
}
