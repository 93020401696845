import React from "react";
// Customizable Area Start
import Cfesdatamanagement2Controller, {
    Category,
    Props,
    configJSON,
} from "./Cfesdatamanagement2Controller";
import { Box, Button, CircularProgress, createTheme, IconButton, Menu, MenuItem, Modal, Paper, Popover, Radio, Snackbar, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from "@mui/material";
import Layout from "../../dashboard/src/Layout.web";
import { emptyStateImage, folderIcon, moreIcon, uploadIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import { arrowDown, successCheck } from "../../GoalManagement/src/assets";
import { checkbox, checked } from "../../customform/src/assets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FileUploader from "../../../components/src/FilteUploader.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff"
        }
    },
    typography: {
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px"
        }
    },
});


export default class Cfesdatamanagement2 extends Cfesdatamanagement2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    subCategoryRender(option: Category, { selected }: any) {
        return (
            <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
                <Radio
                    icon={<img src={checkbox} />}
                    checkedIcon={<img src={checked} />}
                    checked={selected}
                />
                <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>
                    {option.name}
                </Typography>
            </li>
        );
    }

    renderTable() {
        const list = this.state.searchList.length > 0 ? this.state.searchList : this.state.folderData;
        return (
            <FolderTableContainer>
                <FolderTable aria-label="task table" data-test-id="table">
                    <FolderTableHead>
                        <CustomTableRowHead>
                            <FolderTableCellHead>Name</FolderTableCellHead>
                            {this.state.breadcrums.length === 0 && <FolderTableCellHead>Category</FolderTableCellHead>}
                            <FolderTableCellHead>Date</FolderTableCellHead>
                            <FolderTableCellHead>Size</FolderTableCellHead>
                            <FolderTableCellHead></FolderTableCellHead>
                        </CustomTableRowHead>
                    </FolderTableHead>
                    {list.map((item,index) =>
                        <>
                            <CustomSpacing />
                            <FolderTableBody>
                                <FolderTableCell onClick={() => this.handleOpenFolder(item)}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={this.getFileIcon(item.type)} style={{ width: '20px', height: '20px', marginRight: '23px' }} />
                                        <Typography
                                            style={{
                                                color: "#34373A",
                                                fontFamily: "Lato",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 700,
                                                lineHeight: "21px",
                                                letterSpacing: "0.2px",
                                            }}
                                        >
                                            {item.name}
                                        </Typography>
                                    </Box>
                                </FolderTableCell>
                                {this.state.breadcrums.length === 0 && <FolderTableCell>{item.categoryName}</FolderTableCell>}
                                <FolderTableCell>{item.date}</FolderTableCell>
                                <FolderTableCell>{item.size}</FolderTableCell>
                                <FolderTableCell>
                                    <img
                                        data-test-id="more-option"
                                        src={moreIcon}
                                        style={{ width: '24px', height: '24px' }}
                                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                                            this.setState({ 
                                                anchorEl1: event.currentTarget,  
                                                selectedFileId: item.id,
                                                selectedFileName: item.name,
                                                selectedFileType: item.type,
                                            });
                                        }}
                                        aria-owns={Boolean(this.state.anchorEl1) ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={(event: React.MouseEvent<HTMLElement>) => {
                                            this.setState({ 
                                                anchorEl1: event.currentTarget,  
                                                selectedFileId: item.id,
                                                selectedFileName: item.name,
                                                selectedFileType: item.type,
                                            });
                                        }}
                                    />
                                    <Popover
                                        id="mouse-over-popover"
                                        open={Boolean(this.state.anchorEl1)}
                                        anchorEl={this.state.anchorEl1}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        onClose={this.handlePopoverClose}
                                        disableRestoreFocus
                                        data-test-id='popover'
                                    >
                                        <Box display={'flex'} flexDirection={'column'} style={{padding: '16px', borderRadius: '16px', background:'#FFFFFF'}}>
                                            <Box
                                                style={{ marginBottom: '10px', cursor: 'pointer' } as React.CSSProperties}
                                                onClick={this.handleDelete}
                                            >
                                                <Typography style={webStyle.popoverText}>Move to trash</Typography>
                                            </Box>
                                            <Box
                                                style={{ cursor: 'pointer' } as React.CSSProperties}
                                                onClick={this.downloadFilesFolders}
                                            >
                                                <Typography style={webStyle.popoverText}>Download</Typography>
                                            </Box>
                                        </Box>
                                    </Popover>
                                </FolderTableCell>
                            </FolderTableBody>

                        </>
                    )
                    }
                </FolderTable>
            </FolderTableContainer>
        )
    }

    renderContent() {
        const { isFetchingData, folderData, searchText, searchList } = this.state;
        if (isFetchingData) {
            return (
                <Box style={webStyle.emptyStateContainer as React.CSSProperties}>
                    <CircularProgress color="success" />
                </Box>
            )
        }

        if (folderData.length === 0 || (searchText && searchList.length === 0)) {
            return (
                <Box style={webStyle.emptyStateContainer as React.CSSProperties}>
                    <img src={emptyStateImage} style={{ width: '165px', height: '152px' }} />
                    <Typography style={webStyle.emptyStateText as React.CSSProperties}>{configJSON.emptyStateDescription}</Typography>
                </Box>
            )
        }

        return this.renderTable()
    }

    renderSubCategoryDropDown(testId:string) {
        return (
            <Autocomplete
                options={this.state.categoriesList}
                data-testid={testId}
                disableCloseOnSelect={false}
                onChange={(event, value) => {
                    this.setState({ selectedSubCategory: value?.id ?? '' });
                }}
                // disableCloseOnSelect
                popupIcon={<div style={{ margin: '5px 12px' }}>
                    <img src={arrowDown} />
                </div>}
                getOptionLabel={(option: Category) => option.name}
                renderOption={this.subCategoryRender}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        name="memberlist"
                        placeholder="Select one from the list"
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: (this.state.subCategoryError && !this.state.selectedSubCategory) ? webStyle.errorDropdownContainer : webStyle.dropdownContainer,
                        }}
                        inputProps={{
                            ...params.inputProps,
                            style: {
                                padding: 0,
                                fontFamily: 'Lato',
                                color: '#34373A',
                                fontSize: '15px',
                            }
                        }}
                    />
                }
                value={this.state.categoriesList.find(option => option.id === this.state.selectedSubCategory)}
            />
        )
    }

    renderCreateFolderModal() {
        return (
            <Modal
                open={this.state.openCreateFolderModal}
                onClose={this.closeModalHandler}
                style={webStyle.modalWrapper}
                data-testid="create-folder-modal"
            >
                <Paper style={webStyle.mdalPaper as React.CSSProperties}>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={webStyle.titleText}>{configJSON.createFolderLabel}</Typography>
                        <CloseIcon fontSize="medium" onClick={this.closeModalHandler} />
                    </Box>
                    <Typography style={webStyle.subTitleText}>{configJSON.createFolderModalSubText}</Typography>

                    <Typography style={webStyle.inputLabel}>{configJSON.nameText}</Typography>
                    <TaskNameField
                        placeholder={configJSON.namePlaceholderText}
                        variant="outlined"
                        onChange={e => this.setState({ folderName: e.target.value })}
                        value={this.state.folderName}
                        data-testid="folder-name"
                        error={this.state.folderNameError && !this.state.folderName ? true : false}
                        inputProps={{
                            style: {
                                padding: '12px',
                                fontFamily: 'Lato'
                            }
                        }}
                    />
                    {(this.state.folderNameError && !this.state.folderName) && (
                        <Typography style={webStyle.errMsgText}>{this.state.folderNameError}</Typography>
                    )}

                    <Typography style={{ ...webStyle.inputLabel, marginTop: '30px' }}>{configJSON.selectCategory}</Typography>
                    {this.renderSubCategoryDropDown("subcategory-dropdown")}
                    {(this.state.subCategoryError && !this.state.selectedSubCategory) && (
                        <Typography style={webStyle.errMsgText}>{this.state.subCategoryError}</Typography>
                    )}

                    <Box data-test-id="createFolderBtn" style={{ ...webStyle.buttonContainer, ...(this.checkFormValidation() ? { background: '#43D270' } : {}) }} onClick={this.createFolderHandler}>
                        <Typography style={webStyle.buttonText}>
                            {this.state.isCreating ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : 'Create Folder'}
                        </Typography>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    renderUploadFileModal() {
        return (
            <Modal
                open={this.state.openUploadFileModal}
                onClose={this.closeUploadFileModalHandler}
                style={webStyle.modalWrapper}
                data-test-id="upload-file-modal"
            >
                <Paper style={webStyle.mdalPaper as React.CSSProperties}>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <CloseIcon fontSize="medium" onClick={this.closeUploadFileModalHandler} />
                    </Box>
                    <Typography style={{ ...webStyle.titleText, textAlign: 'center' }}>Upload files</Typography>
                    <Typography style={{ ...webStyle.subTitleText, textAlign: 'center', marginTop: '2px' }}>
                        Start uploading files into your vault.
                    </Typography>
                    <FileUploader
                        onSelect={files => this.setState({ file: files })}
                        allowMultiple={false}
                        allowFolderSelection={this.state.allowFolderSelection}
                        data-test-id="file-uploader"
                    />

                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            style={{
                                ...webStyle.buttonContainer,
                                marginTop: '22px',
                                width: '127px',
                                ...(this.state.file.length > 0 ? { background: '#43D270' } : {})
                            }}
                            onClick={() => {
                                if(this.state.file.length > 0){
                                    this.setState({ openUploadFileModal: false, openCategoryModal: true });
                                }
                            }}
                            data-test-id="continue-btn"
                        >
                            <Typography style={webStyle.buttonText}>Continue</Typography>
                        </Box>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    renderSubCategoryModal() {
        return (
            <Modal
                open={this.state.openCategoryModal}
                onClose={() => this.setState({ openCategoryModal: false, allowFolderSelection: false })}
                style={webStyle.modalWrapper}
                data-testid="subcategory-modal"
            >
                <Paper style={webStyle.mdalPaper as React.CSSProperties}>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <CloseIcon fontSize="medium" onClick={() => this.setState({ openCategoryModal: false, allowFolderSelection: false })} />
                    </Box>
                    <Typography style={{ ...webStyle.titleText, textAlign: 'center' }}>Choose Category</Typography>
                    <Typography style={{ ...webStyle.subTitleText, textAlign: 'center', marginTop: '2px' }}>
                        Select category and upload the files.
                    </Typography>

                    {this.renderSubCategoryDropDown("subcategory-dropdown-modal")}

                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            style={{
                                ...webStyle.buttonContainer,
                                marginTop: '22px',
                                width: '127px',
                                ...(this.state.selectedSubCategory ? { background: '#43D270' } : {})
                            }}
                            onClick={this.uploadFile}
                        >
                            {this.state.isUploadingFile ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : <Typography style={webStyle.buttonText}>Upload</Typography>}
                        </Box>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    renderSuccessModal() {
        return (
            <Modal
                open={this.state.openSuccessModal}
                onClose={() => this.setState({ openSuccessModal: false })}
                style={webStyle.modalWrapper}
            >
                <Paper style={webStyle.completedModalPaper}>
                    <img src={successCheck} />
                    <Typography style={webStyle.sucsessTitle}>Success!</Typography>
                    <Typography style={webStyle.successMessage}>{this.state.successMessageText}</Typography>
                </Paper>
            </Modal>
        )
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <Box style={webStyle.topBar as React.CSSProperties}>
                        <TabWithSelect>
                            <Tabs>
                                {[...this.state.categoriesList].map((tab, index) => (
                                    <Box
                                        key={tab.id}
                                        data-test-id={`tab_${index}`}
                                        style={{ ...webStyle.taskTab, ...(this.state.activeTab === tab.id ? webStyle.activeTab : {}) }}
                                        onClick={() => this.tabPressHandler(tab.id)}
                                    >
                                        {tab.name}
                                    </Box>
                                ))}
                            </Tabs>
                        </TabWithSelect>

                        <Box style={{ display: "inline-flex", flexWrap: 'wrap', gap: 12 }}>
                            <CreateFolderButton data-test-id="create-folder-btn" onClick={() => this.setState({ openCreateFolderModal: true })}>
                                <img src={folderIcon} style={{ width: '18px', height: '18px' }} /> Create folder
                            </CreateFolderButton>

                            <Box>
                                <UploadButton
                                    data-test-id="upload-btn"
                                    // onClick={this.handleClick}
                                    onClick={() => this.setState({ openUploadFileModal: true, anchorEl: null, allowFolderSelection: false })}
                                >
                                    <img src={uploadIcon} style={{ width: '18px', height: '18px' }} /> Upload File
                                </UploadButton>

                                <CustomMenu
                                    anchorEl={this.state.anchorEl}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleClose}
                                    data-test-id="upload-menu"
                                >
                                    <CustomMenuItem onClick={() => this.setState({ openUploadFileModal: true, anchorEl: null, allowFolderSelection:false })}>
                                        Upload File
                                    </CustomMenuItem>
                                    <CustomMenuItem onClick={()=>this.setState({openUploadFileModal: true, anchorEl: null, allowFolderSelection: true})}>
                                        Upload Folder
                                    </CustomMenuItem>
                                </CustomMenu>
                            </Box>
                        </Box>
                    </Box>
                    <hr color="#EBEBEB" />

                    <Box style={{ ...webStyle.topBar, padding: "20px 48px 24px 32px" } as React.CSSProperties}>
                        <Typography style={webStyle.selectedTabText}>{this.getSelectedTabName()}</Typography>
                        <Box style={{ display: "inline-flex", flexWrap: 'wrap', gap: 12 }}>
                            <SearchField
                                placeholder="Search..."
                                data-test-id="search-input"
                                variant="outlined"
                                ref={this.searchInputRef}
                                onChange={e => {
                                    this.setState({ searchText: e.target.value });
                                    if (this.searchInputRef?.current) {
                                        clearTimeout(this.searchInputRef.current);
                                    }
                                    this.searchInputRef = setTimeout(() => {
                                        this.searchHandler(e.target.value);
                                    }, 300);
                                }}
                                value={this.state.searchText}
                                inputProps={{ style: { padding: '7px 12px', fontFamily: 'Lato'} }}
                            />
                        </Box>
                    </Box>

                    {this.renderContent()}

                    {/* Create Folder Modal */}
                    {this.renderCreateFolderModal()}

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.toastOpen}
                        autoHideDuration={4000}
                        data-testid="toast"
                        onClose={this.handleToastClose}
                        message={this.state.toastMessage}
                        action={
                            <React.Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleToastClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    />

                    {/* Upload File Modal */}
                    {this.renderUploadFileModal()}

                    {/* Select Category Modal */}
                    {this.renderSubCategoryModal()}

                    {/* Success Message */}
                    {this.renderSuccessModal()}
                </Layout>
            </ThemeProvider>
        );

    }
}

const webStyle = {
    topBar: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: "wrap",
        justifyContent: 'space-between',
        gap: 10,
        padding: "32px 48px 32px 32px"
    },
    taskTab: {
        display: "flex",
        justifyContent: "center",
        padding: "8px 12px",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderRadius: "8px",
        color: "#7B7D7F",
        fontSize: "12px",
        fontWeight: 700,
        cursor: "pointer",
        fontFamily: "Lato",
        fontStyle: "normal",
        letterSpacing: "0.2px",
    },
    activeTab: {
        color: '#329E54',
        backgroundColor: '#ECFBF1'
    },
    modalWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mdalPaper: {
        width: '100%',
        maxWidth: "460px",
        padding: '25px',
        borderRadius: '12px',
        overflow: 'scroll' as 'scroll',
        maxHeight: '90vh',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    },
    titleText: {
        fontSize: '18px',
        lineHeight: '25.2px',
        fontWeight: 700,
        color: '#34373A',
        fontFamily: 'Lato'
    },
    subTitleText: {
        fontSize: '14px',
        lineHeight: '22.4px',
        fontWeight: 400,
        color: '#7D7D7F',
        fontFamily: 'Lato',
        marginTop: '11px',
        marginBottom: '21px',
    },
    inputLabel: {
        fontFamily: 'Lato',
        fontSize: '15px',
        lineHeight: '22px',
        color: '#34373A',
        marginBottom: '8px',
        fontWeight: 400,
    },
    dropdownContainer: {
        maxHeight: "48px",
        borderRadius: '8px',
        border: "1px solid #D9D9D9",
        padding: '11px',
        boxShadow: 'none',
    },
    errorDropdownContainer: {
        maxHeight: '48px',
        borderRadius: '8px',
        border: "1px solid #f44336",
        padding: '11px',
        boxShadow: 'none',
    },
    errMsgText: {
        color: '#f44336',
        padding: '1px',
        fontSize: '14px',
        fontFamily: 'Lato',
        fontWeight: 400,
    },
    optionName: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        fontSize: '14px'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '41px',
        height: '40px',
        background: '#B1B3C3',
        alignItems: 'center',
        borderRadius: '8px',
        cursor: 'pointer'
    },
    buttonText: {
        fontFamily: 'Lato',
        fontSize: '12px',
        lineHeight: '19.2px',
        color: '#34373A',
        fontWeight: 700,
        cursor: 'pointer'
    },
    selectedTabText: {
        fontFamily: 'Lato',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#34373A',
        fontWeight: 700,
    },
    emptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10%',
    },
    emptyStateText: {
        fontFamily: 'Lato',
        fontSize: '16px',
        lineHeight: '20.8px',
        color: '#34373A',
        fontWeight: 400,
        textAlign: 'center',
        maxWidth: '500px',
        marginTop: '29px'
    },
    popoverText:{
        fontSize: '12px', 
        lineHeight: '19.2px', 
        fontWeight: 700, 
        color: '#7B7D7F', 
        cursor: 'pointer',
    },
    completedModalPaper: {
        width: '100%',
        maxWidth: "455px",
        padding: '40px 30px 20px',
        height: '241px',
        borderRadius: '12px',
        overflow: 'scroll' as 'scroll',
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none',
        textAlign: 'center' as 'center'
    },
    sucsessTitle: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '30px',
        color: '#0F172A',
        textAlign: 'center' as 'center',
        margin: '30px 0 10px',
        fontFamily: 'Lato',
    },
    successMessage: {
        fontSize: '17px',
        lineHeight: '26px',
        color: '#34373A',
        textAlign: 'center' as 'center',
        fontFamily: 'Lato',
    },
};

const TabWithSelect = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '@media(max-width: 768px)': {
        flexDirection: "column",
        alignItems: "flex-start",
    },
    '@media(max-width: 700px)': {
        flexWrap: "wrap",
    },
});

const Tabs = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "8px",
    justifyContent: "flex-start",
    alignItems: "center",
    '@media(max-width: 700px)': {
        gap: "4px",
    },
});

const UploadButton = styled(Button)({
    background: '#43D270',
    borderRadius: '8px',
    color: '#34373A',
    fontSize: '12px',
    fontFamily: 'Lato',
    fontWeight: 700,
    textTransform: 'capitalize',
    boxShadow: 'none',
    minWidth: '100px',
    height: '40px',
    gap: 6,
    cursor: 'pointer'
});

const CreateFolderButton = styled(Button)({
    borderRadius: '8px',
    border: '1px solid #DBDADF',
    color: '#7D7D7F',
    fontSize: '12px',
    fontFamily: 'Lato',
    fontWeight: 700,
    textTransform: 'capitalize',
    boxShadow: 'none',
    minWidth: '128px',
    height: '40px',
    gap: 6,
});

const TaskNameField = styled(TextField)({
    width: '100%',
    fontSize: '14px',
    color: '#34373A',
    fontFamily: 'Lato',
    '& div': {
        borderRadius: '8px',
        '& :focus': {
            outline: '1px solid #D9D9D9',
            borderRadius: '8px',
            zIndex: 1,
        },
    },
});

const SearchField = styled(TextField)({
    width: '100%',
    fontSize: '14px',
    color: '#34373A',
    fontFamily: 'Lato',
    '& div': {
        borderRadius: '8px',
        '& :focus': {
            outline: '1px solid #D9D9D9',
            borderRadius: '8px',
            zIndex: 1,
        },
    },
});

const FolderTableContainer = styled(TableContainer)({
    maxHeight: "500px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '0px 44px 20px 32px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '@media(max-width: 600px)': {
        padding: 0
    }
});

const FolderTable = styled(Table)({
    width: "100%",
    borderCollapse: "separate",
    marginBottom: '20px'
});
const FolderTableHead = styled(TableHead)({
    backgroundColor: "#F5F5F5",
    borderRadius: "12px",
});

const CustomTableRowHead = styled(TableRow)({
    // borderRadius: "20px !important",
    overflow: "hidden",
    "& :first-child": {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    "& :last-child": {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
});
const FolderTableCellHead = styled(TableCell)({
    minHeight: "48px",
    padding: "10px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});
const CustomSpacing = styled("div")({
    height: 16,
});
const FolderTableBody = styled(TableBody)({
    color: "inherit",
});
const FolderTableCell = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const CustomMenuItem = styled(MenuItem)({
    fontWeight: 400, 
    color:'#606060',
    fontSize: '14px',
    fontFamily: 'Lato',
    lineHeight: '22px',
    '&:hover': { 
        backgroundColor: "#d0f0c0",
        color: '#43D270', 
    } 
});

const CustomMenu = styled(Menu)({
    top: '3px'
})

// Customizable Area End