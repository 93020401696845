import React from "react";
// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ReportBuilderFormController, {
    Props,
    configJSON,
} from "./ReportBuilderFormController";

import Layout from "../../../dashboard/src/Layout.web";
import { Box, CircularProgress, IconButton, Snackbar, TextField, TextFieldProps, Typography } from "@material-ui/core";
import CustomizedSteppers from "../../../../components/src/CustomizedSteppers";
import { uploadIcon } from "../assets";
import { checkedIcon } from "../../../email-account-registration/src/assets";
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
export const InputField = ({ onChange, placeholder, value, error, InputProps }: TextFieldProps) => {
    return (
        <TextField
            variant="outlined"
            inputProps={{ style: webStyle.inputPropsStyle, maxLength: 500 }}
            InputProps={{ style: {...webStyle.inputStyle, ...(InputProps?.style ?? {})} }}
            fullWidth
            placeholder={placeholder ?? "Type here..."}
            onChange={onChange}
            value={value}
            error={error}
            className="inputField"
            multiline
            maxRows={5}
            minRows={1}
        />
    )
}
// Customizable Area End



// Customizable Area Start
export default class ReportBuilderForm extends ReportBuilderFormController {
    constructor(props: Props) {
        super(props);
    }

    renderMissionInput() {
        const { generalObj, generalObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Mission</Typography>
                <InputField
                    data-test-id="input-mission"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                mission: e.target.value,
                            },
                        }));
                        if(this.state.generalObjError.mission){
                            this.setState(prevProps => ({
                                generalObjError: {
                                    ...prevProps.generalObjError,
                                    mission: '',
                                },
                            }));
                        }
                    }}
                    value={generalObj.mission}
                    error={generalObjError.mission ? true : false}
                />
                {generalObjError.mission && (
                    <Typography style={webStyle.errorMessageText}>
                        {generalObjError.mission}
                    </Typography>
                )}
            </>
        )
    }

    renderVisionInput() {
        const {generalObj, generalObjError} = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Vision</Typography>
                <InputField
                    data-test-id="input-vision"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                vision: e.target.value,
                            },
                        }));
                        if(this.state.generalObjError.vision){
                            this.setState(prevProps => ({
                                generalObjError: {
                                    ...prevProps.generalObjError,
                                    vision: '',
                                },
                            }));
                        }
                    }}
                    value={generalObj.vision}
                    error={generalObjError.vision ? true : false}
                />
                {generalObjError.vision && <Typography style={webStyle.errorMessageText}>{generalObjError.vision}</Typography>}
            </>
        )
    }

    renderWelcomeStatementInput() {
        const { generalObj, generalObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Welcome Statement</Typography>
                <InputField
                    data-test-id="input-welcome-statement"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                welcome_statement: e.target.value,
                            },
                        }));
                        if(this.state.generalObjError.welcome_statement){
                            this.setState(prevProps => ({
                                generalObjError: {
                                    ...prevProps.generalObjError,
                                    welcome_statement: '',
                                },
                            }));
                        }
                    }}
                    value={generalObj.welcome_statement}
                    error={generalObjError.welcome_statement ? true : false}
                />
                {generalObjError.welcome_statement && <Typography style={webStyle.errorMessageText}>{generalObjError.welcome_statement}</Typography>}
            </>
        )
    }

    renderSustainabilitySnap() {
        const { generalObj, generalObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Sustainability snapshot</Typography>
                <Box
                    style={{
                        ...webStyle.fileUploadContainer,
                        borderColor: generalObjError.sustainability_snapshot ? 'red' : '#D9D9D9'
                    }}
                    onClick={this.handleClick}
                    data-test-id="sustainability-file-input"
                >
                    <Typography style={webStyle.chooseText}>{this.getFileName(generalObj.sustainability_snapshot)}</Typography>
                    <img src={uploadIcon} style={{ width: '43px', height: '43px', cursor: 'pointer' }} />
                    <input
                        type="file"
                        ref={this.fileInputRef}
                        style={{ display: "none" }}
                        onChange={this.handleFileChange}
                        accept=".jpg,.png,.pdf"
                        multiple={false}
                    />
                </Box>
                {generalObjError.sustainability_snapshot && <Typography style={webStyle.errorMessageText}>{generalObjError.sustainability_snapshot}</Typography>}
            </>
        )
    }

    renderKeyHighlights() {
        const { generalObj, generalObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Key highlights</Typography>
                <InputField
                    data-test-id="input-key-highlights"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                key_highlights: e.target.value,
                            },
                        }));
                        if(generalObjError.key_highlights){
                            this.setState(prevProps => ({
                                generalObjError: {
                                    ...prevProps.generalObjError,
                                    key_highlights: '',
                                },
                            }));
                        }
                    }}
                    value={generalObj.key_highlights}
                    error={generalObjError.key_highlights ? true : false}
                />
                {generalObjError.key_highlights && <Typography style={webStyle.errorMessageText}>{generalObjError.key_highlights}</Typography>}
            </>
        )
    }

    renderPhilosophyInput() {
        const { generalObj, generalObjError } = this.state
        return (
            <>
                <Typography style={webStyle.inputLabel}>Philosophy</Typography>
                <InputField
                    data-test-id="input-philosophy"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                philosophy: e.target.value,
                            },
                        }));
                        if(generalObjError.philosophy){
                            this.setState(prevProps => ({
                                generalObjError: {
                                    ...prevProps.generalObjError,
                                    philosophy: '',
                                },
                            }));
                        }
                    }}
                    value={generalObj.philosophy}
                    error={generalObjError.philosophy ? true : false}
                />
                {generalObjError.philosophy && <Typography style={webStyle.errorMessageText}>{generalObjError.philosophy}</Typography>}
            </>
        )
    }

    renderEngagement1() {
        const { generalObj, generalObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Engagement 01</Typography>
                <InputField
                    data-test-id="input-engagement1"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                engagement1: e.target.value,
                            },
                        }));

                        if(generalObjError.engagement1){
                            this.setState(prevProps => ({
                                generalObjError: {
                                    ...prevProps.generalObjError,
                                    engagement1: '',
                                },
                            }));
                        }
                    }}
                    value={generalObj.engagement1}
                    error={generalObjError.engagement1 ? true : false}
                />
                {generalObjError.engagement1 && <Typography style={webStyle.errorMessageText}>{generalObjError.engagement1}</Typography>}
            </>
        )
    }

    renderEngagement2Input() {
        const { generalObj, generalObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Engagement 02</Typography>
                <InputField
                    data-test-id="input-engagement2"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                engagement2: e.target.value,
                            },
                        }));

                        if(generalObjError.engagement2){
                            this.setState(prevProps => ({
                                generalObjError: {
                                    ...prevProps.generalObjError,
                                    engagement2: '',
                                },
                            }));
                        }
                    }}
                    value={generalObj.engagement2}
                    error={generalObjError.engagement2 ? true : false}
                />
                {generalObjError.engagement2 && <Typography style={webStyle.errorMessageText}>{generalObjError.engagement2}</Typography>}
            </>
        )
    }

    renderAwards() {
        const { generalObj, generalObjError } = this.state;
        return (
            <Box data-test-id="awards-list">
                {generalObj.awards.map((item, index) => {
                    this.awardFileInputRef[index]= React.createRef();
                    return (
                        <Box key={index}>
                            <Typography style={webStyle.inputLabel}>Awards & Accolades</Typography>
                            <Box>
                                <InputField
                                    InputProps={{ style: { marginBottom: '10px' } }}
                                    onChange={(e) => {
                                        const list = [...generalObj.awards];
                                        list[index] = {
                                            ...list[index],
                                            award: e.target.value,
                                        }
                                        this.setState(prevProps => ({
                                            generalObj: {
                                                ...prevProps.generalObj,
                                                awards: list,
                                            },
                                        }));

                                        if (generalObjError.awards[index]?.award) {
                                            const list1 = [...this.state.generalObjError.awards];
                                            list1[index] = {
                                                ...list1[index],
                                                award: '',
                                            }
                                            this.setState(prevProps => ({
                                                generalObjError: {
                                                    ...prevProps.generalObjError,
                                                    awards: list1,
                                                },
                                            }));
                                        }
                                    }}
                                    error={generalObjError.awards[index]?.award ? true : false}
                                    value={item?.award ?? ''}
                                />
                                {generalObjError.awards[index]?.award && <Typography style={webStyle.errorMessageText}>{generalObjError.awards[index]?.award}</Typography>}

                                <Box
                                    style={{
                                        ...webStyle.fileUploadContainer,
                                        borderColor: generalObjError.awards[index]?.award_file ? 'red' : '#D9D9D9'
                                    }}
                                    onClick={()=>this.awardUploadHandler(index)}
                                >
                                    <Typography style={webStyle.chooseText}>{this.getFileName(item.award_file)}</Typography>
                                    <img src={uploadIcon} style={{ width: '43px', height: '43px', cursor: 'pointer' }} />
                                    <input
                                        type="file"
                                        ref={this.awardFileInputRef[index]}
                                        style={{ display: "none" }}
                                        onChange={e => this.awardsFileHandler(e, index)}
                                        accept=".jpg,.png,.pdf"
                                        multiple={false}
                                    />
                                </Box>
                                {generalObjError.awards[index]?.award_file && <Typography style={webStyle.errorMessageText}>{generalObjError.awards[index].award_file}</Typography>}
                            </Box>
                            <Box style={{ marginTop: '12px', marginBottom: '24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                {(generalObj.awards.length > 1) ? (
                                    <Typography
                                        style={webStyle.delteBtn}
                                        onClick={() => {
                                            const list = [...generalObj.awards];
                                            list.splice(index, 1);
                                            this.setState(prevProps => ({
                                                generalObj: {
                                                    ...prevProps.generalObj,
                                                    awards: list,
                                                }
                                            }));
                                        }}
                                        data-test-id={`remove-award-${index}`}
                                    >
                                        - Delete
                                    </Typography>
                                ) : <Typography></Typography>}
                                {(generalObj.awards.length < 5 && index === generalObj.awards.length - 1) && (
                                    <Typography
                                        style={webStyle.addMoreBtn}
                                        onClick={() => {
                                            this.setState(prevProps => ({
                                                generalObj: {
                                                  ...prevProps.generalObj,
                                                  awards: [
                                                    ...prevProps.generalObj.awards, { id: '', award: '', award_file: null }
                                                ],
                                                }
                                              }));
                                        }}
                                        data-test-id="add-more-award"
                                    >
                                        + Add More
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    renderAccoladdes() {
        const { generalObj, generalObjError } = this.state;

        return (
            <>
                <Typography style={webStyle.inputLabel}>Accolades</Typography>
                <InputField
                    data-test-id="input-accolades"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                accolades: e.target.value,
                            },
                        }));

                        if(generalObjError.accolades){
                            this.setState(prevProps => ({
                                generalObjError: {
                                    ...prevProps.generalObjError,
                                    accolades: '',
                                },
                            }));
                        }
                    }}
                    value={generalObj.accolades}
                    error={generalObjError.accolades ? true : false}
                />
                {generalObjError.accolades && <Typography style={webStyle.errorMessageText}>{generalObjError.accolades}</Typography>}
            </>
        )
    }

    renderMembershipList() {
        const { generalObj, generalObjError } = this.state;
        return (
            <Box data-test-id="membership-list">
                {generalObj.memberships.map((item, index) => {
                    return (
                        <Box key={index}>
                            <Typography style={{ ...webStyle.inputLabel, marginTop: '23px' }}>Membership</Typography>
                            <Box>
                                <InputField
                                    InputProps={{ style: { marginBottom: '10px' } }}
                                    onChange={(e) => {
                                        const list = [...generalObj.memberships];
                                        list[index] = {
                                            ...list[index],
                                            membership: e.target.value,
                                        }
                                        this.setState(prevProps => ({
                                            generalObj: {
                                                ...prevProps.generalObj,
                                                memberships: list,
                                            },
                                        }));

                                        if (generalObjError.memberships[index].membership) {
                                            const list1 = [...this.state.generalObjError.memberships];
                                            list1[index] = {
                                                ...list1[index],
                                                membership: '',
                                            }
                                            this.setState(prevProps => ({
                                                generalObjError: {
                                                    ...prevProps.generalObjError,
                                                    memberships: list1,
                                                },
                                            }));
                                        }
                                    }}
                                    error={generalObjError.memberships[index]?.membership ? true : false}
                                    value={item?.membership ?? ''}
                                    placeholder="Organization Name"
                                />
                                {generalObjError.memberships[index]?.membership && <Typography style={webStyle.errorMessageText}>{generalObjError.memberships[index].membership}</Typography>}

                                <InputField
                                    onChange={(e) => {
                                        const list = [...this.state.generalObj.memberships];
                                        list[index] = {
                                            ...list[index],
                                            membership_details: e.target.value,
                                        }
                                        this.setState(prevProps => ({
                                            generalObj: {
                                                ...prevProps.generalObj,
                                                memberships: list,
                                            },
                                        }));

                                        if (generalObjError.memberships[index].membership_details) {
                                            const list1 = [...this.state.generalObjError.memberships];
                                            list1[index] = {
                                                ...list1[index],
                                                membership_details: '',
                                            }
                                            this.setState(prevProps => ({
                                                generalObjError: {
                                                    ...prevProps.generalObjError,
                                                    memberships: list1,
                                                },
                                            }));
                                        }
                                    }}
                                    error={generalObjError.memberships[index]?.membership_details ? true : false}
                                    value={item?.membership_details ?? ''}
                                    placeholder="Type of Membership"
                                />
                                {generalObjError.memberships[index]?.membership_details && <Typography style={webStyle.errorMessageText}>{generalObjError.memberships[index].membership_details}</Typography>}
                            </Box>
                            <Box style={{ marginTop: '12px', marginBottom: '24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                {(generalObj.memberships.length > 1) ? (
                                    <Typography
                                        style={webStyle.delteBtn}
                                        onClick={() => {
                                            const list = [...generalObj.memberships];
                                            list.splice(index, 1);
                                            this.setState(prevProps => ({
                                                generalObj: {
                                                    ...prevProps.generalObj,
                                                    memberships: list,
                                                }
                                            }));
                                        }}
                                        data-test-id={`remove-membership-${index}`}
                                    >
                                        - Delete
                                    </Typography>
                                ) : <Typography></Typography>}
                                {(generalObj.memberships.length < 5 && index === generalObj.memberships.length - 1) && (
                                    <Typography
                                        style={webStyle.addMoreBtn}
                                        onClick={() => {
                                            this.setState(prevProps => ({
                                                generalObj: {
                                                    ...prevProps.generalObj,
                                                    memberships: [...prevProps.generalObj.memberships, { membership: '', membership_details: '', id: '' }],
                                                }
                                            }));
                                        }}
                                        data-test-id="add-more-membership"
                                    >
                                        + Add More
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    renderInitiatives() {
        const { generalObj, generalObjError } = this.state;

        return (
            <>
                <Typography style={webStyle.inputLabel}>Initiatives 01</Typography>
                <InputField
                    onChange={this.handleInitiative1}
                    value={generalObj.initative1}
                    data-test-id="input-initiative1"
                    error={generalObjError.initative1 ? true : false}
                />
                {generalObjError.initative1 && <Typography style={webStyle.errorMessageText}>{generalObjError.initative1}</Typography>}

                <Typography style={webStyle.inputLabel}>Initiatives 02</Typography>
                <InputField
                    onChange={this.handleInitiative2}
                    value={generalObj.initative2}
                    data-test-id="input-initiative2"
                    error={generalObjError.initative2 ? true : false}
                />
                {generalObjError.initative2 && <Typography style={webStyle.errorMessageText}>{generalObjError.initative2}</Typography>}

                <Typography style={webStyle.inputLabel}>Initiatives 03</Typography>
                <InputField
                    onChange={this.handleInitiative3}
                    value={generalObj.initative3}
                    data-test-id="input-initiative3"
                    error={generalObjError.initative3 ? true : false}
                />
                {generalObjError.initative3 && <Typography style={webStyle.errorMessageText}>{generalObjError.initative3}</Typography>}

                <Typography style={webStyle.inputLabel}>Initiatives 04</Typography>
                <InputField
                    onChange={this.handleInitiative4}
                    value={generalObj.initative4}
                    data-test-id="input-initiative4"
                    error={generalObjError.initative4 ? true : false}
                />
                {generalObjError.initative4 && <Typography style={webStyle.errorMessageText}>{generalObjError.initative4}</Typography>}
            </>
        )
    }

    renderGeneralForm(){
        return (
            <>
                <Typography style={webStyle.subTitleText}>Welcome Statement</Typography>
                {this.renderMissionInput()}
                {this.renderVisionInput()}
                {this.renderWelcomeStatementInput()}
                {this.renderSustainabilitySnap()}
                {this.renderKeyHighlights()}
                {this.renderPhilosophyInput()}
                
                <hr color="#EBEBEB" style={{marginTop: '35px', marginBottom: '35px'}} />

                <Typography style={webStyle.subTitleText}>Stakeholder Engagement</Typography>
                
                {this.renderEngagement1()}
                {this.renderEngagement2Input()}
                
                <hr color="#EBEBEB" style={{marginTop: '35px', marginBottom: '35px'}} />

                <Typography style={webStyle.subTitleText}>Awards, Accolades, Memberships</Typography>

                {this.renderAwards()}
                {this.renderMembershipList()}
                
                <hr color="#EBEBEB" style={{marginTop: '35px', marginBottom: '35px'}} />

                <Typography style={webStyle.subTitleText}>Future Initiatives/ Outlooks</Typography>
                
                {this.renderInitiatives()}

                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '60px' }}>
                    <Box style={webStyle.buttonContainer} onClick={this.navigateToReportBuilder}>
                        <Typography style={webStyle.buttonText}>
                            Cancel
                        </Typography>
                    </Box>

                    <Box style={{...webStyle.buttonContainer, background: '#43D270'}} onClick={this.updateGeneralInfo} data-test-id="general-proceed-btn">
                        <Typography style={webStyle.buttonText}>
                            {this.state.isUpdatingReport ? <CircularProgress style={{width:'20px', height:'20px'}} /> : "Proceed"}
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    renderCircularEconomy() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;

        return (
            <>
                <Typography style={webStyle.inputLabel}>Circular Economy Initiatives</Typography>
                <InputField
                    data-test-id="input-circular-economy"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                circular_economy_initatives: e.target.value,
                            },
                        }));

                        if(esgPerformanceObjError.circular_economy_initatives){
                            this.setState(prevProps => ({
                                esgPerformanceObjError: {
                                    ...prevProps.esgPerformanceObjError,
                                    circular_economy_initatives: '',
                                },
                            }));
                        }
                    }}
                    value={esgPerformanceObj.circular_economy_initatives}
                    error={esgPerformanceObjError.circular_economy_initatives ? true : false}
                />
                {esgPerformanceObjError.circular_economy_initatives && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.circular_economy_initatives}</Typography>}
            </>
        )
    }

    renderMembershipInput() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;

        return (
            <>
                <Typography style={webStyle.inputLabel}>Emissions Reporting (Current status, Future targets)</Typography>
                <InputField
                    data-test-id="input-membership"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                membership: e.target.value,
                            },
                        }));

                        if(esgPerformanceObjError.membership){
                            this.setState(prevProps => ({
                                esgPerformanceObjError: {
                                    ...prevProps.esgPerformanceObjError,
                                    membership: '',
                                },
                            }));
                        }
                    }}
                    value={esgPerformanceObj.membership}
                    error={esgPerformanceObjError.membership ? true : false}
                />
                {esgPerformanceObjError.membership && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.membership}</Typography>}
            </>
        )
    }

    renderResourceEfficiency() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Resource Efficiency</Typography>
                <InputField
                    data-test-id="input-efficiency"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                resource_efficiencey: e.target.value,
                            },
                        }));

                        if(esgPerformanceObjError.resource_efficiencey){
                            this.setState(prevProps => ({
                                esgPerformanceObjError: {
                                    ...prevProps.esgPerformanceObjError,
                                    resource_efficiencey: '',
                                },
                            }));
                        }
                    }}
                    value={esgPerformanceObj.resource_efficiencey}
                    error={esgPerformanceObjError.resource_efficiencey ? true : false}
                />
                {esgPerformanceObjError.resource_efficiencey && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.resource_efficiencey}</Typography>}
            </>
        )
    }

    renderWellBeing() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Well-being</Typography>
                <InputField
                    data-test-id="input-well-being"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                well_being: e.target.value,
                            },
                        }));

                        if(esgPerformanceObjError.well_being){
                            this.setState(prevProps => ({
                                esgPerformanceObjError: {
                                    ...prevProps.esgPerformanceObjError,
                                    well_being: '',
                                },
                            }));
                        }
                    }}
                    value={esgPerformanceObj.well_being}
                    error={esgPerformanceObjError.well_being ? true : false}
                />
                {esgPerformanceObjError.well_being && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.well_being}</Typography>}
            </>
        )
    }

    renderPartnerships() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Partnerships</Typography>
                <InputField
                    data-test-id="input-partnerships"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                partnerships: e.target.value,
                            },
                        }));

                        if(esgPerformanceObjError.partnerships){
                            this.setState(prevProps => ({
                                esgPerformanceObjError: {
                                    ...prevProps.esgPerformanceObjError,
                                    partnerships: '',
                                },
                            }));
                        }
                    }}
                    value={esgPerformanceObj.partnerships}
                    error={esgPerformanceObjError.partnerships ? true : false}
                />
                {esgPerformanceObjError.partnerships && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.partnerships}</Typography>}
            </>
        )
    }

    renderCommunity() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Community</Typography>
                <InputField
                    data-test-id="input-community"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                community: e.target.value,
                            },
                        }));

                        if(esgPerformanceObjError.community){
                            this.setState(prevProps => ({
                                esgPerformanceObjError: {
                                    ...prevProps.esgPerformanceObjError,
                                    community: '',
                                },
                            }));
                        }
                    }}
                    value={esgPerformanceObj.community}
                    error={esgPerformanceObjError.community ? true : false}
                />
                {esgPerformanceObjError.community && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.community}</Typography>}
            </>
        )
    }

    renderDiversityEfforts() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Diversity Efforts</Typography>
                <InputField
                    data-test-id="input-diversity-efforts"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                diversity_efforts: e.target.value,
                            },
                        }));

                        if(esgPerformanceObjError.diversity_efforts){
                            this.setState(prevProps => ({
                                esgPerformanceObjError: {
                                    ...prevProps.esgPerformanceObjError,
                                    diversity_efforts: '',
                                },
                            }));
                        }
                    }}
                    value={esgPerformanceObj.diversity_efforts}
                    error={esgPerformanceObjError.diversity_efforts ? true : false}
                />
                {esgPerformanceObjError.diversity_efforts && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.diversity_efforts}</Typography>}
            </>
        )
    }

    renderCertificationsList() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;
        return (
            <Box data-test-id="certificates-list">
                {esgPerformanceObj.certifications.map((item, index) => {
                    this.certificateFileInputRef[index] = React.createRef();
                    return (
                        <Box key={index}>
                            <Typography style={webStyle.inputLabel}>Certifications</Typography>
                            <Box>
                                <InputField
                                    InputProps={{ style: { marginBottom: '10px' } }}
                                    onChange={(e) => {
                                        const list = [...esgPerformanceObj.certifications];
                                        list[index] = {
                                            ...list[index],
                                            title: e.target.value,
                                        }
                                        this.setState(prevProps => ({
                                            esgPerformanceObj: {
                                                ...prevProps.esgPerformanceObj,
                                                certifications: list,
                                            },
                                        }));

                                        if (esgPerformanceObjError.certifications[index]?.title) {
                                            const list1 = [...esgPerformanceObjError.certifications];
                                            list1[index] = {
                                                ...list1[index],
                                                title: '',
                                            }
                                            this.setState(prevProps => ({
                                                esgPerformanceObjError: {
                                                    ...prevProps.esgPerformanceObjError,
                                                    certifications: list1,
                                                },
                                            }));
                                        }
                                    }}
                                    value={item?.title ?? ""}
                                    error={esgPerformanceObjError.certifications[index]?.title ? true : false}
                                />
                                {esgPerformanceObjError.certifications[index]?.title && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.certifications[index].title}</Typography>}

                                <Box
                                    style={{
                                        ...webStyle.fileUploadContainer,
                                        borderColor: esgPerformanceObjError.certifications[index]?.certificate ? 'red' : '#D9D9D9',
                                    }}
                                    onClick={() => {
                                        this.certificateUploadHandler(index)
                                    }}
                                >
                                    <Typography style={webStyle.chooseText}>{this.getFileName(item.certificate)}</Typography>
                                    <img src={uploadIcon} style={{ width: '43px', height: '43px', cursor: 'pointer' }} />
                                    <input
                                        type="file"
                                        ref={this.certificateFileInputRef[index]}
                                        style={{ display: "none" }}
                                        onChange={e => {
                                            this.certificateFileHandler(e,index)
                                        }}
                                        accept=".jpg,.png,.pdf"
                                        multiple={false}
                                    />
                                </Box>
                                {esgPerformanceObjError.certifications[index]?.certificate && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.certifications[index].certificate}</Typography>}
                            </Box>
                            <Box style={{ marginTop: '12px', marginBottom: '24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                {(esgPerformanceObj.certifications.length > 1) ? (
                                    <Typography
                                        style={webStyle.delteBtn}
                                        onClick={() => {
                                            const list = [...esgPerformanceObj.certifications];
                                            list.splice(index, 1);
                                            this.setState(prevProps => ({
                                                esgPerformanceObj: {
                                                    ...prevProps.esgPerformanceObj,
                                                    certifications: list,
                                                }
                                            }));
                                        }}
                                        data-test-id="remove-certificate"
                                    >
                                        - Delete
                                    </Typography>
                                ) : <Typography></Typography>}
                                {(esgPerformanceObj.certifications.length < 5 && index === esgPerformanceObj.certifications.length - 1) && (
                                    <Typography
                                        style={webStyle.addMoreBtn}
                                        onClick={() => {
                                            this.setState(prevProps => ({
                                                esgPerformanceObj: {
                                                  ...prevProps.esgPerformanceObj,
                                                  certifications: [...prevProps.esgPerformanceObj.certifications, { id: '', title: '', certificate: null }],
                                                }
                                              }));
                                        }}
                                        data-test-id="add-more-certificate"
                                    >
                                        + Add More
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    renderEthicalPolicyList() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;
        return (
            <Box data-test-id="policies-list">
                {esgPerformanceObj.ethical_policies.map((item, index) => {
                    return (
                        <Box key={index}>
                            <Typography style={webStyle.inputLabel}>Ethical Policies</Typography>
                            <Box>
                                <InputField
                                    onChange={(e) => {
                                        const list = [...esgPerformanceObj.ethical_policies];
                                        list[index] = {...list[index], name: e.target.value};

                                        this.setState(prevProps => ({
                                            esgPerformanceObj: {
                                                ...prevProps.esgPerformanceObj,
                                                ethical_policies: list,
                                            },
                                        }));

                                        if (esgPerformanceObjError.ethical_policies[index]) {
                                            const list1 = [...this.state.esgPerformanceObjError.ethical_policies];
                                            list1[index] = '';
                                            this.setState(prevState => ({
                                                esgPerformanceObjError: {
                                                    ...prevState.esgPerformanceObjError,
                                                    ethical_policies: list1
                                                }
                                            }))
                                        }
                                    }}
                                    value={item.name ?? ''}
                                    error={esgPerformanceObjError.ethical_policies[index] ? true : false}
                                />
                                {esgPerformanceObjError.ethical_policies[index] && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.ethical_policies[index]}</Typography>}
                            </Box>

                            <Box style={{ marginTop: '12px', marginBottom: '24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                {(esgPerformanceObj.ethical_policies.length > 1) ? (
                                    <Typography
                                        style={webStyle.delteBtn}
                                        onClick={() => {
                                            const list = [...esgPerformanceObj.ethical_policies];
                                            list.splice(index, 1);

                                            this.setState(prevProps => ({
                                                esgPerformanceObj: {
                                                    ...prevProps.esgPerformanceObj,
                                                    ethical_policies: list,
                                                }
                                            }));
                                        }}
                                        data-test-id="remove-policies"
                                    >
                                        - Delete
                                    </Typography>
                                ) : <Typography></Typography>}
                                {(esgPerformanceObj.ethical_policies.length < 5 && index === esgPerformanceObj.ethical_policies.length - 1) && (
                                    <Typography
                                        style={webStyle.addMoreBtn}
                                        onClick={() => {
                                            this.setState(prevProps => ({
                                                esgPerformanceObj: {
                                                    ...prevProps.esgPerformanceObj,
                                                    ethical_policies: [...prevProps.esgPerformanceObj.ethical_policies, {id: '', name:''}],
                                                }
                                            }));
                                        }}
                                        data-test-id="add-more-policies"
                                    >
                                        + Add More
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    renderDataSecurity() {
        const { esgPerformanceObj, esgPerformanceObjError } = this.state;
        return (
            <>
                <Typography style={webStyle.inputLabel}>Data security/ standards</Typography>
                <InputField
                    data-test-id="input-data-security"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                data_security: e.target.value,
                            },
                        }));

                        if(esgPerformanceObjError.data_security){
                            this.setState(prevProps => ({
                                esgPerformanceObjError: {
                                    ...prevProps.esgPerformanceObjError,
                                    data_security: '',
                                },
                            }));
                        }
                    }}
                    value={esgPerformanceObj.data_security}
                    error={esgPerformanceObjError.data_security ? true : false}
                />
                {esgPerformanceObjError.data_security && <Typography style={webStyle.errorMessageText}>{esgPerformanceObjError.data_security}</Typography>}
            </>
        )
    }

    renderESGPerformanceForm() {
        return (
            <>
                <Typography style={webStyle.subTitleText}>Environmental Stewardship</Typography>
                {this.renderCircularEconomy()}
                {this.renderMembershipInput()}
                {this.renderResourceEfficiency()}

                <hr color="#EBEBEB" style={{ marginTop: '35px', marginBottom: '35px' }} />

                <Typography style={webStyle.subTitleText}>Employee Well-being</Typography>
                {this.renderWellBeing()}
                {this.renderPartnerships()}
                {this.renderCommunity()}
                {this.renderDiversityEfforts()}

                <hr color="#EBEBEB" style={{ marginTop: '35px', marginBottom: '35px' }} />

                <Typography style={webStyle.subTitleText}>Transparency and Accountability</Typography>

                {this.renderCertificationsList()}
                {this.renderEthicalPolicyList()}
                {this.renderDataSecurity()}

                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '60px' }}>
                    <Box
                        style={webStyle.buttonContainer}
                        onClick={() => { 
                            if (!this.state.isUpdatingReport) {
                                this.setState({ activeStep: 0 });
                            }
                        }}
                        data-test-id="back-btn"
                    >
                        <Typography style={webStyle.buttonText}>
                            Back
                        </Typography>
                    </Box>

                    <Box style={{ ...webStyle.buttonContainer, background: '#43D270' }} onClick={this.updateESFPerformance} data-test-id="proceed-esg-performance-btn">
                        <Typography style={webStyle.buttonText}>
                            {this.state.isUpdatingReport ? <CircularProgress style={{width:'20px', height:'20px'}} /> : "Proceed"}
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    renderUNSDGDetails() {
        return (
            <>
                <Typography style={webStyle.subTitleText}>Fill the chosen UN SDGs</Typography>
                <Box data-test-id="unSDGDetails">
                    {this.state.choosenUNSDG.map((item, index) => {
                        return (
                            <Box key={item.id}>
                                <Typography style={webStyle.inputLabel}>{item.goal_title}</Typography>
                                <InputField
                                    data-test-id="input-circular-economy"
                                    onChange={(e) => {
                                        const list = this.state.choosenUNSDG.map(ele => {
                                            if (ele.goal_id === item.goal_id) {
                                                return {
                                                    ...ele,
                                                    details: e.target.value,
                                                }
                                            }

                                            return ele;
                                        })
                                        this.setState({ choosenUNSDG: list });

                                        if (this.state.choosendUNSDGError[index]) {
                                            const list1 = [...this.state.choosendUNSDGError];
                                            list1[index] = '';
                                            this.setState({ choosendUNSDGError: list1 });
                                        }
                                    }}
                                    value={this.state.choosenUNSDG.find(ele => ele.goal_id === item.goal_id)?.details ?? ''}
                                    error={this.state.choosendUNSDGError[index] ? true : false}
                                />
                                {this.state.choosendUNSDGError[index] && <Typography style={webStyle.errorMessageText}>{this.state.choosendUNSDGError[index]}</Typography>}
                            </Box>
                        )
                    })}
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '60px' }}>
                    <Box
                        style={webStyle.buttonContainer}
                        onClick={() => {
                            if(!this.state.isUpdatingReport){
                                this.setState({ activeStep: 1 })
                            }
                        }}
                        data-test-id="choosen-sdg-back-btn"
                    >
                        <Typography style={webStyle.buttonText}>
                            Back
                        </Typography>
                    </Box>

                    <Box style={{ ...webStyle.buttonContainer, background: '#43D270' }} onClick={this.updateUNSDGList}>
                        <Typography style={webStyle.buttonText}>
                            {this.state.isUpdatingReport ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : "Submit"}
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    renderContent():JSX.Element{
        switch (this.state.activeStep) {
            case 0:
                return this.renderGeneralForm();

            case 1:
                return this.renderESGPerformanceForm();

            case 2:
                return this.renderUNSDGDetails();

            default:
                return <></>;
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <Box style={webStyle.headerContainer}>
                        <Typography style={webStyle.titleText}>{configJSON.reportBuilderFormText}</Typography>
                        <Typography style={webStyle.descriptionText}>{configJSON.reportBuilderFormDescription}</Typography>

                        <CustomizedSteppers
                            data-testid="customized-stepper"
                            getSteps={this.tabOptions}
                            getStepContent={(step)=>{
                                this.setState({activeStep: step});
                            }}
                            activeStep={this.state.activeStep}
                            style={{padding: 0, width: '70%'}}
                        />
                    </Box>
                    <hr color="#EBEBEB" />
                    <Box style={{padding: '40px 50px', width: '90%'}}>
                        {this.renderContent()}
                    </Box>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.toastOpen}
                        autoHideDuration={4000}
                        data-testid="toast"
                        onClose={this.handleToastClose}
                        message={this.state.toastMessage}
                        action={
                            <React.Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleToastClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                </Layout>
            </ThemeProvider>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
    headerContainer: {
        marginTop: '32px',
        padding: "0 44px 30px",
    },
    titleText:{
        fontSize: '28px',
        lineHeight: '35px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#34373A',
        marginBottom: '23px',
    },
    descriptionText:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#7D7D7F',
        marginBottom: '10px',
    },
    subTitleText: {
        fontSize: '18px',
        lineHeight: '15.2px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#34373A',
    },
    inputLabel: {
        fontSize: '15px',
        lineHeight: '15.2px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#34373A',
        marginBottom: '8px',
        marginTop: '16px'
    },
    inputStyle:{
        fontSize: '15px',
        color: '#34373A',
        fontFamily: 'Lato',
        fontWeight: 400,
        lineHeight: '24px',
        border: '1px solid #D9D9D9',
        padding: 0
    },
    inputPropsStyle: { 
        padding: '14px 12px',
    },
    chooseText:{
        fontSize: '15px',
        color: '#34373A',
        fontFamily: 'Lato',
        fontWeight: 400,
        lineHeight: '24px',
        cursor: 'pointer',
    },
    fileUploadContainer: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        border: '1px dashed #D9D9D9',
        borderRadius: '8px',
        padding: '18.51px 18.44px',
        cursor: 'pointer'
    },
    addMoreBtn: {
        textAlign: 'right',
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        fontFamily: 'Inter',
        color: '#43D270',
        cursor: 'pointer'
    } as React.CSSProperties,
    delteBtn: {
        textAlign: 'left',
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        fontFamily: 'Inter',
        color: '#F5222D',
        cursor: 'pointer',
    } as React.CSSProperties,
    buttonContainer:{
        width: '160px',
        height: '40px',
        background: '#F6F6F6',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        fontFamily: 'Inter',
        color: '#34373A',
        cursor: 'pointer'
    },
    unsdgCard: {
        display: 'flex',
        alignItems: 'center',
        padding: '15px',
        justifyContent: 'space-between',
    },
    iconContainer:{
        width: '48px',
        height: '48px',
        borderRadius: '8px',
        background: '#F3F3F3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    goalName:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#7D7D7F',
    },
    goalTitle:{
        fontSize: '18px',
        lineHeight: '11.6px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#34373A',
        marginTop: '5px'
    },
    errorMessageText:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 500,
        fontFamily: 'Lato',
        color: 'red',
    }
};
// Customizable Area End
