import React, { CSSProperties } from "react";
import {
    createStyles,
    TextField,
    Typography,
    Paper,
    InputLabel,
    Radio,
    IconButton,
    Box,
    CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DashboardController from "../DashboardController";
import { withStyles } from '@material-ui/core/styles';

const styles = createStyles({
    paper: {
        border: "none",
        boxShadow: 'none',
        maxHeight: '250px',
    },

});


export class MemberModalWeb extends DashboardController {
    constructor(props: any) {
        super(props);
    }
    async componentDidMount() {
        this.getMembers()
    }

    renderOption(option: any, { selected }: any) {
        return (
            <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
                <Radio
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#D9D9D9" />
                    </svg>}
                    checkedIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#43D270" />
                        <rect x="4" y="4" width="8" height="8" fill="#43D270" />
                    </svg>}
                    style={{ marginLeft: -16 }}
                    checked={selected}
                />
                <Typography style={{ ...webStyle.resourcename, color: selected ? "#34373A" : "#7D7D7F", }}>{option.first_name + " " + option.last_name}</Typography>
            </li>
        );
    }

    renderInput(params: any) {
        return (
            <TextField
                {...params}
                name="memberlist"
                placeholder="Select one"
                InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    style: webStyle.autoComplete,
                }}
            />
        );
    }

    render() {
        const { classes, onAssign, onClose, isLoading } = this.props;

        return (
            <Paper style={webStyle.memberpaperStyle as CSSProperties}>
                <div style={webStyle.titleContainer}>
                    <Typography style={webStyle.memberTitle}>Please select from the list</Typography>
                    <IconButton onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M16.9496 7.05029L7.05015 16.9498" stroke="#34373A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.05017 7.05029L16.9497 16.9498" stroke="#34373A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </IconButton>
                </div>
                <Typography style={webStyle.memberdesc}>Select the team member you want to assign the task to from the below list:</Typography>
                <InputLabel
                    htmlFor="member"
                    style={{ ...webStyle.labeltxt }}
                >
                    Select the team member
                </InputLabel>

                <Autocomplete
                    id="member"
                    options={this.state.memberList}
                    data-test-id="member"
                    onChange={(event, value) => this.setState({selectedAssignee: value?.id})}
                    disableCloseOnSelect={false}
                    classes={{ paper: classes?.paper }}
                    closeIcon={false}
                    popupIcon={<div style={{ margin: '5px 12px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15 7.5L10 12.5L5 7.5" stroke="#7D7D7F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>}
                    PopperComponent={({ children, ...other }) => (
                        <div {...other} style={webStyle.modelpopper}>
                            {children}
                        </div>
                    )}
                    getOptionLabel={this.getOptionLabel}
                    renderOption={this.renderOption}
                    renderInput={this.renderInput}
                />
                <Box
                    data-test-id="assign-btn"
                    style={{ ...webStyle.buttonContainer, ...(this.state.selectedAssignee ? { background: '#43D270' } : {}) }}
                    onClick={() => {
                        if (this.state.selectedAssignee) {
                            onAssign(this.state.selectedAssignee);
                        }
                    }}
                >
                    {isLoading ? (
                        <CircularProgress style={{ width: '20px', height: '20px' }} />
                    ) : (
                        <Typography style={webStyle.buttonText}>
                            Assign
                        </Typography>
                    )}
                </Box>
            </Paper>
        );
    }
}
// Customizable Area Start
const webStyle = {
    resourcename: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        fontSize: '14px'
    },
    memberpaperStyle: {
        width: '460px',
        maxWidth: "100%",
        minHeight: '250px',
        padding: '25px 21px 46px 27px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1
    },
    labeltxt: {
        color: "#34373A",
        fontFamily: "Lato !important",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        marginBottom: '8px'
    },
    memberTitle: {
        color: "#34373A",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "140%",
        letterSpacing: "0.2px",
    },
    modelpopup: {
        width: 167, height: 86,
        position: 'relative',
        right: 117,
        top: 3,
        cursor: 'pointer',
    },
    autoComplete: {
        maxWidth: '404px',
        minHeight: "48px",
        borderRadius: '8px',
        border: "1px solid #D9D9D9",
        padding: '12px',
        boxShadow: 'none'
    },
    modelpopper: {
        maxHeight: '271px',
        borderRadius: "8px",
        border: "1px solid #D9D9D9",
        background: "#FFF",
        maxWidth: '404px',
        boxShadow: 'none !important',
        padding: '0px !important',
    },
    memberdesc: {
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "160%",
        marginBottom: '21px'
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '11px',
        alignItems: 'center'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '41px',
        height: '40px',
        background: '#B1B3C3',
        alignItems: 'center',
        borderRadius: '8px',
        cursor: 'pointer'
    },
    buttonText: {
        fontFamily: 'Lato',
        fontSize: '12px',
        lineHeight: '19.2px',
        color: '#34373A',
        fontWeight: 700,
        cursor: 'pointer'
    },
};
// Customizable Area End
export default withStyles(styles)(MemberModalWeb);
