import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { 
  Typography as TypographyMUI, 
  Box,
  Button as ButtonMUI, 
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Layout from "../../dashboard/src/Layout.web";
import Card from "./components/GoalCard";
import { no_goals } from "../../multiplelandingpage2/src/assets";

export const PageHeaadingContainer = styled(Box)({
  width: "100%",
  display: "flex", 
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 30,
  "@media all and (max-width: 400px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10
  }
});

export const NoGoalsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center", 
  justifyContent: "center",
  marginTop: 150,
  "@media all and (max-width: 600px)": {
    marginTop: 0,
  }
});

export const NoGoalsText = styled(TypographyMUI)({
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "28px",
  textAlign: "center",
  color: "#34373A",
  marginTop: 45,
  "@media all and (max-width: 600px)": {
    marginTop: 0,
  }
});

export const GoalsContainer = styled("div")({
	width: "100%",
	minHeight: "calc(100vh - 185px)",
	padding: "32px 32px 21px 32px"
});

export const CircularProgressContainer = styled("div")({
	color: "grey", 
  height: "calc(100vh - 145px)", 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "center",
});

export const PageHeading = styled(TypographyMUI)({
  fontFamily: "Lato",
  fontSize: "22px",
  fontWeight: 700,
  lineHeight: "27.5px",
  textAlign: "left",
  color: "#34373A"
});

export const SaveButton = styled(ButtonMUI)({
  minHeight: "40px",
  minWidth: "97px",
  padding: "8px 8px 8px 12px",
  borderRadius: "8px",
  backgroundColor: "#43D270",
  color: "#34373A",
  textTransform: "none",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
  '& span': {
    fontFamily: "Inter",
    fontWeight: 700,
  },
  '&:disabled': {
    backgroundColor: "#B1B3C3", 
    color: "#34373A"
  },
  '&:hover': {
    backgroundColor: "#43D270",
  },
});

// Customizable Area End

import CfunsdgsController, {
  Props,
  configJSON,
} from "./CfunsdgsController";

export default class Cfunsdgs extends CfunsdgsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout>
        {
            this.state.isLoading ? (
              <CircularProgressContainer>
                <CircularProgress color="inherit" />
              </CircularProgressContainer>
            ) : (
              <GoalsContainer>
                <PageHeaadingContainer>
                  <PageHeading variant="h1">
                    {this.state.unsdgGoals.length} UN SDGs
                  </PageHeading>
                  <SaveButton 
                    data-test-id="save-btn"
                    disableElevation
                    disabled={!this.state.goalChangeDetected}
                    variant="contained"
                    onClick={this.saveUNSDGGoals}
                  >
                    Save
                  </SaveButton>
                </PageHeaadingContainer>
                {
                  this.state.unsdgGoals.length === 0 && (
                    <NoGoalsContainer>
                      <img src={no_goals} alt="no goals found" />
                      <NoGoalsText>No goals found</NoGoalsText>
                    </NoGoalsContainer>
                  )
                }
                <Grid container spacing={2}>
                  {this.state.unsdgGoals.map((goal) => {
                    return (
                      <Grid item xs={12} sm={6} md={3} key={goal.id}>
                        <Card 
                          goalNumber={goal.goal_name}
                          goalEnabled={goal.goal_enabled}
                          goalTitle={goal.goal_title}
                          goalDescription={goal.goal_description}
                          goalIcon={goal.goal_icon}
                          goalSelectedIcon={goal.goal_green_icon}
                          onSelectClick={() => this.handleGoalSelection(goal.id)}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </GoalsContainer>
            )
          }
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
